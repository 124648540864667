import { FunctionComponent } from 'react';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ConnectFormProps } from '../ConnectMarketplaceNew';
import ConnectMarketplaceForm, { FieldOption } from '../ConnectMarketplaceForm';
import { Col, Row } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';

export interface FormData {
    username: string;
    password: string;
}

export const createValidationSchema = (t: TFunction) =>
    yup.object().shape({
        username: yup.string().required(t('error.required.general')),
        password: yup.string().required(t('error.required.general')),
    });

const fields: FieldOption[][] = [[{ name: 'username' }, { name: 'password' }]];

const ConnectEMagForm: FunctionComponent<ConnectFormProps> = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: props.i18nPrefix });

    return (
        <>
            <Row>
                <Col>
                    <Trans
                        i18nKey="connectEMag.step1.p1"
                        components={[
                            <strong>univers</strong>,
                            <a href={t('helpLink')} target="_blank">
                                univers
                            </a>,
                        ]}
                    ></Trans>
                </Col>
            </Row>
            <ConnectMarketplaceForm {...props} fields={fields} />
            <Row>
                <Col>{t('p2')}</Col>
            </Row>
        </>
    );
};

export default ConnectEMagForm;
