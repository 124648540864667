import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from './ErrorPage';

export const NotAuthorizedPage: FunctionComponent<{ desc?: string }> = ({ desc }) => {
    const { t } = useTranslation();

    return (
        <ErrorPage
            code={401}
            title={t('error.notAuthorized.title')}
            message={t('error.notAuthorized.message')}
            desc={desc}
        />
    );
};
