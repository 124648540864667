import { FormData } from './ShoptetLoginForm';
import { AbstractFormBloc } from 'src/library/bloc/AbstractFormBloc';
import { NotFoundError } from 'src/errors/index';
import { ServiceProvider } from 'src/services/ServiceProvider';

declare const SERVICES: ServiceProvider;

export class ShoptetLoginBloc extends AbstractFormBloc<any, FormData> {
    getName(): string {
        return 'ShoptetLoginBloc';
    }

    getFormData(data: any): FormData {
        return {
            url: data?.url ?? '',
        };
    }
    requestGetData(): Promise<any> {
        return Promise.resolve();
    }
    requestSetData(data: FormData): Promise<any> {
        return SERVICES.auth.shoptetLogin(data.url);
    }

    responseSetData(authUrl: string) {
        window.location.href = authUrl;
    }

    getErrorMessage(error: Error) {
        if (error instanceof NotFoundError) {
            return this.t('error.shopNotFound');
        }
        return super.getError(error);
    }
}
