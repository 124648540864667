import { FunctionComponent } from 'react';
import FormInputLabeled from 'src/components/form/FormInputLabeled';
import { useTranslation } from 'src/hooks/useTranslation';
import { RouteUtils } from 'src/utils/RouteUtils';

export interface ConnectMarketplaceInputProps {
    name: string;
    tabIndex: number;
    i18nPrefix: string;
    isSubmitting?: boolean;
}

const ConnectMarketplaceInput: FunctionComponent<ConnectMarketplaceInputProps> = (props) => {
    const { name, i18nPrefix, tabIndex, isSubmitting } = props;

    const i18nProps = useTranslation({ i18nPrefix });
    const helpLinkKey = `${i18nPrefix}.${name}.helpLink`;

    return (
        <FormInputLabeled
            type="string"
            t={i18nProps.t}
            exists={i18nProps.exists}
            name={name}
            tabIndex={tabIndex}
            hintOnClick={RouteUtils.handleOpenUrl(i18nProps.t(helpLinkKey))}
            includeFormPrefix={false}
            disabled={isSubmitting}
        />
    );
};
ConnectMarketplaceInput.defaultProps = {
    isSubmitting: false,
};

export default ConnectMarketplaceInput;
