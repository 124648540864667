import { FunctionComponent } from 'react';
import PlatformLogo from 'src/components/logo/PlatformLogo';
import { Platform } from 'src/graphql/generated';
import { useTranslation } from 'src/hooks/useTranslation';
import { paths } from 'src/routing/paths';
import AuthWithCard from './AuthWithCard';
import { AuthType } from '../Form/AuthType';
import { useNavigate } from 'react-router-dom';

export const AuthWithShoptet: FunctionComponent<{ type: AuthType }> = ({ type }) => {
    const navigate = useNavigate();
    const { t } = useTranslation({ i18nPrefix: `auth.authWith.shoptet.${type}` });
    const platform = Platform.shoptet;

    return (
        <AuthWithCard
            t={t}
            onClick={() => navigate(paths.auth.shoptet[type])}
            logo={<PlatformLogo type={platform} size="small" />}
        />
    );
};
