import { FunctionComponent } from 'react';
import { HasI18nProps } from 'src/common/props';
import { Button } from 'src/ui';

export interface AuthWithCardProps extends HasI18nProps {
    logo?: JSX.Element;
    onClick: () => void;
}

const AuthWithCard: FunctionComponent<AuthWithCardProps> = ({ t, logo, onClick }) => {
    return (
        <>
            <span className="title">{t('title')}</span>
            <div className="logo">{logo}</div>
            <Button className="pull-right" color="secondary" text={t('btn')} onClick={onClick} />
        </>
    );
};
AuthWithCard.defaultProps = {
    logo: null,
};

export default AuthWithCard;
