import { ApolloClient } from '@apollo/client';
import {
    GetCompanyComponent,
    GetCompanyDocument,
    GetCompanyQuery,
    GetShopInfoDocument,
    GetShopInfoQuery,
    GetUserDocument,
    GetUserQuery,
} from './graphql/generated';
import { ApolloQueryResult } from 'apollo-client';

declare const GQL_CLIENT: ApolloClient<any>;

export function bootstrapIntercom(history) {
    const data: {
        user: GetUserQuery['user'] | null;
        company: GetCompanyQuery['company'] | null;
        shopInfo: GetShopInfoQuery['shopInfo'] | null;
    } = {
        user: undefined,
        company: undefined,
        shopInfo: undefined,
    };
    function update() {
        for (const key in data) if (data[key] === undefined) return;
        const { user, company, shopInfo } = data;

        window.Intercom('update', {
            email: user.email,
            name: user.name,
            user_id: user._id,
            companyId: user.roles?.[0]?.companyId ?? '',
            productSource: company.feedType ?? '',
            activeShoptetAddons: Object.keys(shopInfo.addons ?? {})
                ?.filter((addon) => shopInfo.addons?.[addon]?.active)
                .join(','),
        });
    }
    GQL_CLIENT.watchQuery<GetUserQuery>({ query: GetUserDocument }).subscribe((result) => {
        if (result.loading || !result.data) return;
        data.user = result.data.user ?? null;
        update();
    });
    GQL_CLIENT.watchQuery<GetCompanyQuery>({ query: GetCompanyDocument }).subscribe((result) => {
        if (result.loading || !result.data) return;
        data.company = result.data.company ?? null;
        update();
    });
    GQL_CLIENT.watchQuery<GetShopInfoQuery>({ query: GetShopInfoDocument }).subscribe((result) => {
        if (result.loading || !result.data) return;
        data.shopInfo = result.data.shopInfo ?? null;
        update();
    });
    history.listen(() => {
        if (window.Intercom && typeof window.Intercom === 'function') {
            window.Intercom('update');
        }
    });
}

export function openIntercom() {
    const launcherElement = window.document.getElementsByClassName('intercom-lightweight-app-launcher');
    if (!launcherElement || !launcherElement[0]) return;

    (launcherElement[0] as any).click();
}
