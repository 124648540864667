import { FunctionComponent } from 'react';
import { ShoptetLoginForm } from './ShoptetLoginForm';
import AuthLayout from '../AuthLayout';
import { RegistrationFooter } from '../Registration/RegistrationFooter';
import { AuthWithEmail } from '../AuthWith/AuthWithEmail';
import { useTranslation } from 'src/hooks/useTranslation';
import { ShoptetLoginBloc } from './ShoptetLoginBloc';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';

export interface ShoptetLoginProps {
    bloc: IAbstractBloc;
}
export const ShoptetLogin: FunctionComponent<ShoptetLoginProps> = ({ bloc }) => {
    return (
        <AuthLayout
            type="loginShoptet"
            bloc={bloc}
            authWithCards={[AuthWithEmail]}
            content={ShoptetLoginForm}
            footer={RegistrationFooter}
        />
    );
};

export const ShoptetLoginOrchestrator: FunctionComponent = () => {
    const { t } = useTranslation();

    return <ShoptetLogin bloc={new ShoptetLoginBloc({ state: 'empty' }, t)} />;
};
