import { FunctionComponent } from 'react';
import { Button } from 'src/ui';
import { useTranslation } from 'src/hooks/useTranslation';
import { NavLink } from 'react-router-dom';
import { paths } from 'src/routing/paths';

export const RegistrationFooter: FunctionComponent = () => {
    const { t } = useTranslation({ i18nPrefix: 'auth.registration' });

    return (
        <footer>
            {t('footer.haveAccount')}
            <NavLink to={paths.auth.login}>
                <Button color="secondary" text={t('footer.login')} />
            </NavLink>
        </footer>
    );
};
