import { UserQuery } from 'src/graphql/generated';
import { addScriptToHead } from '../utils/DomUtils';

export enum ESegmentEvent {
    USER_LOGGED = 'user_logged',
    USER_REGISTERED = 'user_registered',
    DATA_SOURCE_FEED_URL_SAVED = 'data_source_feed_url_saved',
    DATA_SOURCE_CSV_UPLOADED = 'data_source_csv_uploaded',
    DATA_SOURCE_CONNECTED = 'data_source_connected',
    MARKETPLACE_SELECTED = 'marketplace_selected',
    MARKETPLACE_CONNECTED = 'marketplace_connected',
    AUTOPRICER_SUGGESTIONS_SUBMITTED = 'autopricer_suggestions_submitted',
}

export interface SegmentEvent {
    event: ESegmentEvent;
    params: Record<any, string>;
    error?: string;
    env?: string;
    /** Default Date.now if not provided */
    timestamp?: number;
    /** Default from user context if not provided */
    companyId?: string;
    /** Default from user context if not provided */
    userId?: string;
    userEmail?: string;
}

export function init(segmentId: string) {
    addScriptToHead(`
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentId}";;analytics.SNIPPET_VERSION="4.15.3";
        analytics.load("${segmentId}");
        // analytics.page();
        }}();
    `);
}
export function identify(user: UserQuery) {
    if (!user) return;
    window.analytics.identify(user._id, {
        name: user.name,
        email: user.email,
    });
}
export function track(segmentEvent: SegmentEvent): void {
    const { event, ...data } = segmentEvent;
    window.analytics.track(event, data);
}
