import { AuthService } from './auth/AuthService';
import { AutopricerService } from './autopricing/AutopricerService';
import { FeatureService } from './FeatureService';
import { FeedService } from './feed/FeedService';
import { MarketplaceService } from './marketplace/MarketplaceService';
import { OrderService } from './order/OrderService';
import { FulfillmentService } from './fulfillment/FulfillmentService';
import { ProductService } from './product/ProductService';
import { ProgressBarService } from './progress/ProgressBarService';
import { UserService } from './user/UserService';
import { ReportService } from 'src/services/report/ReportService';
import { PlatformService } from './platform/PlatformService';
import { ConfirmationModalBloc } from '../ui/modal/ConfirmationModalBloc';
import { TFunction } from 'i18next';
import { CompanyService } from './company/CompanyService';
import { SimpleModalBloc } from 'src/ui/modal/SimpleModalBloc';
import { ToastWrapperService } from './toast/ToastService';
import { ShoptetService } from './shop/ShoptetService';
import { FeedConvertService } from 'src/services/convert/FeedConvertService';
import { ShipmentService } from 'src/services/shipment/OrderService';

export class ServiceProvider {
    public feature = new FeatureService();
    public feed = new FeedService();
    public auth = new AuthService();
    public user = new UserService();
    public company = new CompanyService();
    public product = new ProductService();
    public order = new OrderService();
    public fulfillment = new FulfillmentService();
    public autopricer = new AutopricerService();
    public report = new ReportService();
    public convert = new FeedConvertService();
    public shoptet = new ShoptetService();
    public shipment = new ShipmentService();

    private _marketplace = new MarketplaceService();
    private _platform = new PlatformService();

    public system: {
        modal: {
            confirmation: ConfirmationModalBloc;
            simple: SimpleModalBloc;
        };
        progressBar: ProgressBarService;
        toast: ToastWrapperService;
    };

    constructor(t?: TFunction) {
        this.system = {
            modal: {
                confirmation: new ConfirmationModalBloc(t),
                simple: new SimpleModalBloc(t),
            },
            progressBar: new ProgressBarService(),
            toast: new ToastWrapperService(),
        };
    }

    // to be able to mock services provide get property accessor, see MarketplacesContext.spec.tsx
    get marketplace() {
        return this._marketplace;
    }
    get platform() {
        return this._platform;
    }
}
