import { FunctionComponent } from 'react';
import { SetPasswordForm } from './SetPasswordForm';
import { LoginFooter } from '../Login/LoginFooter';
import AuthLayout from '../AuthLayout';
import { useTranslation } from 'src/hooks/useTranslation';
import { SetPasswordBloc } from './SetPasswordBloc';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';

export interface SetPasswordProps {
    bloc: IAbstractBloc;
}
export const SetPassword: FunctionComponent<SetPasswordProps> = ({ bloc }) => {
    return <AuthLayout type="setPassword" bloc={bloc} authWithCards={[]} content={SetPasswordForm} />;
};

export const SetPasswordOrchestrator: FunctionComponent = () => {
    const { t } = useTranslation();
    return <SetPassword bloc={new SetPasswordBloc({ state: 'empty' }, t)} />;
};
