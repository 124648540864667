import { useState } from 'react';
import { getStatusLikeError } from 'src/services/ServiceUtils';

type UserServiceRequest<T> = () => Promise<T>;
const defaultRequest = () => Promise.reject(new Error('useServices# No request provided'));

export interface UserServiceOptions<T> {
    initialLoading?: boolean;
    initialData?: T;
    initialError?: any;
    setData?: (data: T) => void;
    setError?: (error: Error) => void;
    onFinish?: () => void;
}

function useCommonService<T>(
    responseResolver: (data: T, setData: (data: T) => void) => void,
    options: UserServiceOptions<T> = {},
) {
    const { initialLoading, initialData, initialError } = options;

    const [isLoading, setLoading] = useState<boolean>(initialLoading || false);
    const [data, setData] = useState<T>(initialData);
    const [error, setError] = useState<any>(initialError);

    /**
     * @param request
     * @param callback when request successfull
     */
    const makeRequest = async (request: UserServiceRequest<T> = defaultRequest) => {
        const updateData = options.setData || setData;
        const updateError = options.setError || setError;
        try {
            setData(null);
            setError(null);
            setLoading(true);
            responseResolver(await request(), updateData);
            options.onFinish && options.onFinish();
        } catch (error) {
            updateError(error);
        } finally {
            setLoading(false);
        }
    };

    const clearData = () => setData(null);

    return {
        makeRequest,
        clearData,
        isLoading,
        setLoading,
        data,
        setData,
        error,
        setError,
    };
}

export function useService<T>(options: UserServiceOptions<T> = {}) {
    return useCommonService((data, setData) => setData(data), options);
}
export function useStatusService<T>(options: UserServiceOptions<T> = {}, relatedStatuses?: string[]) {
    return useCommonService((data, setData) => {
        setData(data);
        const error = getStatusLikeError(data, { relatedStatuses });
        if (error) throw error;
    }, options);
}
