import * as yup from 'yup';
import { AbstractForm, FieldOption, ValidationSchemas } from 'src/components/form';
import { Translation } from 'src/common/decorators/Translation';

export interface FormData {
    email: string;
}

@Translation({
    i18nPrefix: 'auth.resetPassword',
    lookup: true,
})
export class ResetPasswordForm extends AbstractForm<FormData> {
    getFields(): FieldOption[][] {
        return [[{ name: 'email', size: 12 }]];
    }

    getValidationSchema(): { email: yup.BaseSchema<any, any, any> } {
        return {
            email: ValidationSchemas.email(this.t),
        };
    }
}
