import { FunctionComponent } from 'react';

export interface PageTitleProps {
    title: string;
    description?: string;
    heading?: string;
}

const PageTitle: FunctionComponent<PageTitleProps> = ({ title, description, heading }) => {
    return (
        <header className="page-title">
            {heading && <div className="heading">{heading}</div>}
            <h1>{title}</h1>
            {description && <div className="h1-desc">{description}</div>}
        </header>
    );
};

export default PageTitle;
