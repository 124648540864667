import { FunctionComponent } from 'react';
import { useTranslation } from 'src/hooks/useTranslation';
import { OptionsButton, OptionsButtonOption } from 'src/ui';
import Flag from 'src/ui/flag/Flag';

import { LanguageSelectorImplProps } from './LanguageSelector';

const IconLanguageSelector: FunctionComponent<LanguageSelectorImplProps> = (props) => {
    const { selected, direction, languages, onSelection } = props;

    const { t } = useTranslation();

    const RootRenderer: FunctionComponent<OptionsButtonOption> = () => (
        <div className={'circled'}>
            <Flag code={selected} />
        </div>
    );

    const OptionRenderer: FunctionComponent<OptionsButtonOption> = ({ value }) => (
        <div className={'option-item'}>
            <Flag code={value} />
            <span className="align-middle">{t(`country.${value}`)}</span>
        </div>
    );

    const options = languages.map((lng) => ({
        label: lng.toUpperCase(),
        value: lng,
        icon: <Flag code={lng} />,
    }));

    return (
        <OptionsButton
            color="transparent"
            caret={false}
            direction={direction}
            text={selected}
            options={options}
            onSelection={(lng) => onSelection(lng.value)}
            RootRenderer={RootRenderer}
            OptionRenderer={OptionRenderer}
        />
    );
};

export default IconLanguageSelector;
