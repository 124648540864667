import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { FormData } from './ConnectMallStep1Form';

export class ConnectMallStep1Bloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectMallStep1Bloc';
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            clientId: data?.clientId ?? '',
            // need to be defined for step2 to be able to read it
            webhookUrl: data?.webhookUrl ?? '',
        };
    }

    protected handleContinue = (): void => {
        this.isDone = true;
        this.produceEvent('set', false, this.credentials, null);
    };
}
