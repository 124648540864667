import {
    FindAllByIdsDocument,
    FindAllByIdsQuery,
    FindAllByIdsQueryVariables,
    GetOrdersPageDocument,
    GetOrdersPageQuery,
    GetOrdersPageQueryVariables,
    Order,
} from '../../graphql/generated';
import { Service } from '../Service';

export class OrderService extends Service {
    async findAll(variables: GetOrdersPageQueryVariables): Promise<GetOrdersPageQuery> {
        let result = await this.query<GetOrdersPageQuery, GetOrdersPageQueryVariables>(
            {
                query: GetOrdersPageDocument,
                variables,
                fetchPolicy: 'no-cache',
            },
            {
                method: 'findAll',
            },
        );

        return result ?? null;
    }

    async findByIds(marketplaceOrderIds: string[]): Promise<Partial<Order>[]> {
        let result = await this.query<FindAllByIdsQuery, FindAllByIdsQueryVariables>(
            {
                query: FindAllByIdsDocument,
                variables: { marketplaceOrderIds },
                fetchPolicy: 'no-cache',
            },
            {
                method: 'findByIds',
            },
        );

        return result?.ordersByIds ?? [];
    }
}
