import { MutationOptions } from '@apollo/client';
import {
    CompanyQuery,
    FeatureToggleList,
    GetUserCompanyDocument,
    GetUserCompanyQuery,
    GetUserDocument,
    GetUserFeaturesDocument,
    GetUserFeaturesQuery,
    GetUserQuery,
    UserRegistrationDocument,
    UserRegistrationMutation,
    UserRegistrationMutationVariables,
    UserRegistrationRes,
    UserQuery,
    UserRow,
    userStatsDocument,
    userStatsQuery,
    UserRegistrationDto,
    UpdateToSMutation,
    UpdateToSMutationVariables,
    UpdateToSDocument,
} from '../../graphql/generated';
import { ErrorInterceptor, RequestContext, Service } from '../Service';
import { IUserService } from './IUserService';

export interface GetUserResult extends UserQuery {
    company: CompanyQuery | null;
    features: FeatureToggleList;
}

export type FindAllUser = Pick<UserRow, 'username' | 'companyId'>;

export class UserService extends Service implements IUserService {
    /**
     * @inheritdoc
     */
    async findAll(): Promise<FindAllUser[]> {
        let result = await this.query<userStatsQuery>({ query: userStatsDocument }, { method: 'findAll' });

        return result?.userStats ?? null;
    }

    /**
     * @inheritdoc
     */
    async getUser(): Promise<GetUserResult> {
        const user = await this.query<GetUserQuery>({ query: GetUserDocument }, { method: 'getUser' });

        if (!user?.user) return null;

        // Note: MSL don't know where to update on GW, so for now, get company here with extra query
        const company = await this.query<GetUserCompanyQuery>(
            {
                query: GetUserCompanyDocument,
                fetchPolicy: 'no-cache',
            },
            { method: 'getUserCompany' },
        );
        const features = await this.query<GetUserFeaturesQuery>(
            {
                query: GetUserFeaturesDocument,
                fetchPolicy: 'no-cache',
            },
            { method: 'getUserFeatures' },
        );

        const result: GetUserResult = {
            ...user.user,
            company: company?.company ?? null,
            features: features?.featureToggleList ?? {
                pass: [],
                block: [],
            },
        };

        return result;
    }

    /**
     * @inheritdoc
     */
    async register(dto: UserRegistrationDto, errorInterceptor?: ErrorInterceptor): Promise<UserRegistrationRes> {
        const request: MutationOptions<UserRegistrationMutation, UserRegistrationMutationVariables> = {
            mutation: UserRegistrationDocument,
            variables: { dto },
        };
        const context: RequestContext = {
            method: 'register',
            params: request.variables,
            errorInterceptor,
        };
        const result = await this.mutate(request, context);

        if (result.userRegistration.error) {
            throw new Error(result.userRegistration.error);
        }

        return result.userRegistration;
    }

    /**
     * @inheritdoc
     */
    async acceptToS(tosVersion: string, language: string): Promise<void> {
        const request: MutationOptions<UpdateToSMutation, UpdateToSMutationVariables> = {
            mutation: UpdateToSDocument,
            variables: {
                update: {
                    tos: { acceptedAt: new Date(), version: tosVersion, language },
                },
            },
        };
        const context: RequestContext = {
            method: 'register',
            params: request.variables,
        };
        await this.mutate(request, context);
    }
}
