import { FunctionComponent } from 'react';
import { HasBlocProps } from 'src/common/props';
import NotificationPanel from 'src/components/NotificationPanel/NotificationPanel';
import { useBloc } from 'src/hooks/useBloc';
import { StateData as AbstractStateData } from 'src/library/bloc';
import CommonStatusPage from 'src/screens/StatusPage/StatusPage';
import LoopingProgressBar from 'src/ui/progressBar/LoopingProgressBar';
import { Button } from 'src/ui';
import { paths } from 'src/routing/paths';
import { useTranslation } from 'src/hooks/useTranslation';
import { useNavigate } from 'react-router-dom';

export interface StateData extends AbstractStateData<'authenticating' | 'authenticated' | 'incorrect', any> {}
export interface StatusPageProps extends HasBlocProps {
    type: 'request' | 'redirect';
}
const StatusPage: FunctionComponent<StatusPageProps> = ({ bloc, type }) => {
    const navigate = useNavigate();
    const { t } = useTranslation({
        i18nPrefix: `auth.shoptetOAuth.${type}`,
        lookup: true,
    });

    const { state, error } = useBloc<StateData>(bloc);

    const description = state === 'incorrect' ? t('pageFailedDescription') : t('pageDescription');

    const Content: FunctionComponent = () => {
        switch (state) {
            case 'authenticating':
                return <LoopingProgressBar />;
            case 'authenticated':
                return <NotificationPanel type="success" message={t('success')} centered />;
            case 'incorrect':
                return (
                    <>
                        <NotificationPanel type="danger" message={error} centered />
                        <Button
                            text={t('btn')}
                            onClick={() => navigate(paths.root, { replace: true })}
                            style={{ margin: '0 auto' }}
                        />
                    </>
                );
        }
    };

    return <CommonStatusPage title={t('pageTitle')} description={description} content={Content} />;
};

export default StatusPage;
