import { captureException, init } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Severity } from '@sentry/react';
import { LogLocalContext } from './AppLogger';
import { LoggerStream } from './LoggerStream';

export class SentryLogger extends LoggerStream<Record<string, any>> {
    constructor(private dsn: string) {
        super('SentryLogger');
    }

    initialize(): Promise<void> {
        init({
            dsn: this.dsn,
            integrations: [new Integrations.BrowserTracing()],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.2,
        });

        return super.initialize();
    }

    warn(value: string | Record<string, any>, context: LogLocalContext = {}): void {
        this.log(Severity.Warning, value, context);
    }
    error(error: string | Record<string, any> | Error, context: LogLocalContext = {}): void {
        this.log(Severity.Error, error, context);
    }

    // /**
    //  * Sets global context for logger that will be added to each log accordingly.
    //  * @param context
    //  */
    // setContext(context: LogGlobalContext): void {
    // super.setContext(context);
    // TODO MSL not sure if use this to setup global context or pass it during captureExpetion context
    // check the difference and whether why override each other, for now, passing at once in log method
    // configureScope(scope => {
    //     scope.setUser(context.user);
    //     scope.setTags({
    //         companyId: context.companyId
    //     });
    // });
    // }

    /**
     * Log message via sentry's captureExpetion function.
     * @param level
     * @param message
     * @param context
     */
    protected log(level: Severity, message: string | Error | Record<string, any>, context: LogLocalContext) {
        captureException(message, {
            user: this.context?.user,
            level,
            tags: {
                companyId: this.context?.companyId,
                category: context.category,
                component: context.component,
                method: context.method,
            },
            extra: {
                params: context.params,
            },
        });
    }
}
