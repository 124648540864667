import { ApolloClient } from '@apollo/client';
import { gql } from 'apollo-boost';

declare const GQL_CLIENT: ApolloClient<any>;

const getUserQuery = gql`
    query GetUser {
        user {
            email
            roles {
                type
                companyId
            }
        }
    }
`;

export async function initHeapAnalytics() {
    const heapData = await getUser();
    if (!heapData) return false;
    if (heapData === true) return true;

    // @ts-ignore heap not defined on window
    const heap = window.heap;
    if (!heap) return false;

    const { email, companyId } = heapData;
    heap.addUserProperties({
        email,
        companyId,
        AppEmail: email,
    });

    return true;
}

async function getUser() {
    let result = await GQL_CLIENT.query({
        query: getUserQuery,
    });
    let user = result?.data?.user;
    if (!user) return null; // falsy no user to decide

    const role = user?.roles[0];
    if (role?.type !== 'user') return true; // not regular user no more fetching

    return {
        email: user.email,
        companyId: role.companyId,
    };
}
