import React, { FunctionComponent } from 'react';
import { LanguageService } from '../../localization/LanguageService';
import { HasChildren } from 'src/common/props';

export interface ILanguageContext {
    languageService: LanguageService;
}

export const LanguageContext = React.createContext<ILanguageContext>(null);
LanguageContext.displayName = 'LanguageContext';

export interface LanguageContextProviderProps extends HasChildren {
    languageService: LanguageService;
}
export const LanguageContextProvider: FunctionComponent<LanguageContextProviderProps> = ({
    languageService,
    children,
}) => {
    return <LanguageContext.Provider value={{ languageService }}>{children}</LanguageContext.Provider>;
};
