import {
    EFeatureName,
    GetFeatureToggleAsAdminDocument,
    GetFeatureToggleAsAdminQuery,
    GetFeatureToggleDocument,
    GetFeatureToggleQuery,
} from '../graphql/generated';
import { Service } from './Service';

export class FeatureService extends Service {
    /**
     * Check the current logged user has access for feature.
     * @param featureName a feature toggle name
     * @returns true/false
     */
    async isEnabled(featureName: EFeatureName): Promise<boolean> {
        const variables = {
            featureName,
        };
        let result = await this.query<GetFeatureToggleQuery>(
            {
                query: GetFeatureToggleDocument,
                variables,
                fetchPolicy: 'cache-first',
            },
            {
                method: 'isEnabled',
                params: variables,
            },
        );

        return result?.getFeatureToggle?.allowed ?? false;
    }

    async isEnabledAsAdmin(companyId: string, featureName: EFeatureName): Promise<boolean> {
        const variables = {
            companyId,
            featureName,
        };
        let result = await this.query<GetFeatureToggleAsAdminQuery>(
            {
                query: GetFeatureToggleAsAdminDocument,
                variables,
                fetchPolicy: 'cache-first',
            },
            {
                method: 'isEnabled',
                params: variables,
            },
        );

        return result?.getFeatureToggleAsAdmin?.allowed ?? false;
    }

    isHandlingTimeEnabled(): Promise<boolean> {
        return this.isEnabled(EFeatureName.handling_time_enabled);
    }

    isAutopricingSuggestionsEnabled(): Promise<boolean> {
        return this.isEnabled(EFeatureName.autopricing_suggestions);
    }
}
