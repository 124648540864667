/**
 * Adds given content as script tag into page head tag.
 * @param content
 */
export function addScriptToHead(content: string): void {
    const header = document.querySelector('head');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.appendChild(document.createTextNode(content));
    header.appendChild(script);
}
