import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import classNames from 'classnames';
import { Spinner } from 'reactstrap';
import { Tooltip } from 'react-tippy';

import './ExpandoButton.scss';

export type ButtonColors = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'success' | 'warning' | 'info' | 'link';
export type ButtonSizes = 'small' | 'normal';
export interface ButtonProps extends ButtonHTMLAttributes<any> {
    text?: string;
    color?: ButtonColors;
    size?: ButtonSizes;
    notificationCount?: number;
    icon?: JSX.Element;
    loading?: boolean;
    tooltip?: string;
    // since button si wrapped by tooltip, classname is assigned to tooltip div as it is a root div
    // but sometimes might come handy to assign additional class to button directly, for that use buttonClassName
    buttonClassName?: string;
}

const ExpandoButton: FunctionComponent<ButtonProps> = (props) => {
    const {
        color,
        size,
        disabled,
        hidden,
        notificationCount,
        icon,
        loading,
        text,
        className,
        buttonClassName,
        tooltip,
        ...rest
    } = props;

    if (hidden) return null;
    if (loading || disabled) delete rest.onClick;

    const classes = classNames({
        'expando-btn': true,
        [color]: true,
        [size]: true,
        disabled: disabled,
        loading: loading,
        'has-text': !!text,
        [buttonClassName]: !!buttonClassName,
    });

    return (
        <Tooltip title={tooltip} disabled={!tooltip} className={`expando-btn-tooltip ${className}`}>
            <button className={classes} {...rest}>
                {loading && <Spinner className="spinner" size="sm" />}
                {icon}
                {text && <span className="text">{text}</span>}
                {notificationCount > 0 && <span className="badge">{notificationCount}</span>}
            </button>
        </Tooltip>
    );
};
ExpandoButton.defaultProps = {
    color: 'primary',
    size: 'normal',
    type: 'button',
    disabled: false,
    hidden: false,
    className: '',
    buttonClassName: '',
};

export default ExpandoButton;
