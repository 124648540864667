import { ExtendedQueryOptions, RequestContext, Service, StopPollingWhenPrototype } from 'src/services/Service';
import {
    FeedConvertReport,
    FeedConvertReportState,
    GetFeedConvertReportDocument,
    GetFeedConvertReportQuery,
    GetFeedConvertReportQueryVariables,
    RequestFeedConvertReportDocument,
    RequestFeedConvertReportMutation,
    RequestFeedConvertReportMutationVariables,
} from 'src/graphql/generated';
import { MutationOptions } from '@apollo/client';
import { Report } from 'src/hooks/useReportPolling';
import { OperationFailedError, UnexpectedError } from 'src/errors';

export interface FeedConvertReportResult extends Report {
    id: string;
    result: {
        downloadUrl: string;
    };
}
export class FeedConvertService extends Service {
    /**
     * Starts converting feed.
     * @param settings
     * @returns feed convert report object
     */
    async startConvertingFeed(settings: RequestFeedConvertReportMutationVariables): Promise<FeedConvertReport> {
        const request: MutationOptions<RequestFeedConvertReportMutation> = {
            mutation: RequestFeedConvertReportDocument,
            variables: settings,
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'generateReport',
            params: settings,
        };
        const response = await this.mutate<RequestFeedConvertReportMutation, RequestFeedConvertReportMutationVariables>(
            request,
            context,
        );

        const result = response?.requestFeedConvertReport;
        if (!result) {
            throw new UnexpectedError('no data', context);
        }
        if (result.state === FeedConvertReportState.error) {
            throw new OperationFailedError('state', FeedConvertReportState.error);
        }

        return result;
    }

    /**
     * Polls a feed conversion report. Returns promise that is resolved when report is finished. But 'stopPollingWhen' gets the result of each poll.
     * @param variables required by request
     * @param updateUiFn
     * @param stopPollingWhen determines when to stop pooling
     * @returns FeedConvertReport when finished
     */
    async pollFeedConvertReport(
        variables: GetFeedConvertReportQueryVariables,
        updateUiFn: (progress: number) => void,
        stopPollingWhen?: StopPollingWhenPrototype,
    ): Promise<FeedConvertReportResult> {
        const getReport = (response: GetFeedConvertReportQuery): FeedConvertReportResult => {
            const result = response?.getFeedConvertReport;
            // make conversion to FE generic types for usePolling
            const report: FeedConvertReportResult = {
                id: result?.id,
                state: result?.state as any,
                progress: result?.progress || 0,
                result: {
                    downloadUrl: (result?.result?.downloadUrl as any) ?? null,
                },
            };
            updateUiFn(report.progress || 0);
            return report;
        };
        const pollInterval = 3000;
        const request: ExtendedQueryOptions<GetFeedConvertReportQuery, {}> = {
            query: GetFeedConvertReportDocument,
            variables,
            pollInterval,
            stopPollingWhen: (response) => stopPollingWhen(getReport(response)),
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'pollFeedConvertReport',
            params: variables,
        };
        const result = await this.watchQuery(request, context);

        return getReport(result);
    }
}
