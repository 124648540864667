import { ENV_KEY, EnvUtils } from '../utils/EnvUtils';
import { ConsoleLogger } from './ConsoleLogger';
import { SentryLogger } from './SentryLogger';
import { CoralogixLogger } from './CoralogixLogger';
import { EAppLoggerCategory } from './EAppLoggerCategory';
import { SegmentLogger } from './SegmentLogger';

export type LogLocalContext = {
    category?: EAppLoggerCategory;
    component?: string;
    method?: string;
    params?: any;
};

export type LogGlobalContext = {
    user: {
        id: string;
        email: string;
    };
    companyId: string;
};

export interface ILoggerStream {
    initialize(): Promise<void>;

    info(value: string | Record<string, any>, context?: LogLocalContext): void;
    warn(value: string | Record<string, any>, context?: LogLocalContext): void;
    error(value: string | Record<string, any> | Error, context?: LogLocalContext): void;
    trace(value: string | Record<string, any>, context?: LogLocalContext): void;
    /**
     * Use to log some end of completed workflow.
     */
    track(value: string | Record<string, any>, context?: LogLocalContext): void;

    setContext(context: LogGlobalContext): void;
}

export class AppLogger {
    private constructor(private streams: ILoggerStream[] = []) {}

    public static async initialize(): Promise<AppLogger> {
        const streams: ILoggerStream[] = [new ConsoleLogger()];
        if (EnvUtils.isProduction()) {
            this.addSentryStream(streams);
            this.addCoralogicStream(streams);
            this.addSegmentStream(streams);
        }

        for (const stream of streams) {
            stream.initialize();
        }

        const logger = new AppLogger(streams);

        window.onerror = (msg, source, fileno, columnNumber, error) => {
            logger.error(msg.toString(), {
                category: EAppLoggerCategory.GENERAL,
                component: 'window',
                params: {
                    source,
                    fileno,
                    columnNumber,
                    error: JSON.stringify(error),
                },
            });
        };

        return logger;
    }

    /**
     * Adds Sentry logger stream if enabled ( SENTRY_DSN in envs )
     * @param streams
     */
    private static addSentryStream(streams: ILoggerStream[]): void {
        const dsn = EnvUtils.find(ENV_KEY.SENTRY_DSN);
        if (dsn) {
            streams.push(new SentryLogger(dsn));
        }
    }

    /**
     * Adds Coralogix logger stream if enabled
     * ( CORALOGIX_APP_NAME and CORALOGIX_PRIVATE_KEY in envs )
     * @param streams
     */
    private static addCoralogicStream(streams: ILoggerStream[]): void {
        const privateKey = EnvUtils.find(ENV_KEY.CORALOGIX_PRIVATE_KEY);
        const applicationName = EnvUtils.find(ENV_KEY.CORALOGIX_APP_NAME);
        const subsystemName = EnvUtils.find(ENV_KEY.CORALOGIX_SUBSYS_NAME);
        const url = EnvUtils.find(ENV_KEY.CORALOGIX_URL);

        if (applicationName && privateKey) {
            streams.push(
                new CoralogixLogger({
                    debug: true,
                    privateKey,
                    url,
                    applicationName,
                    subsystemName,
                }),
            );
        }
    }

    /**
     * Adds Segment logger stream if enabled ( SEGMENT_ID in envs )
     * @param streams
     */
    private static addSegmentStream(streams: ILoggerStream[]): void {
        const segmentId = EnvUtils.find(ENV_KEY.SEGMENT_ID);
        if (segmentId) {
            streams.push(new SegmentLogger(segmentId));
        }
    }

    setContext(context: LogGlobalContext): void {
        for (const stream of this.streams) {
            stream.setContext(context);
        }
    }

    info = (value: string | Record<string, any>, context: LogLocalContext = {}): void => {
        this.doForStream((o) => o.info(value, context));
    };
    warn = (value: string | Record<string, any>, context: LogLocalContext = {}): void => {
        this.doForStream((o) => o.warn(value, context));
    };
    error = (error: string | Record<string, any> | Error, context: LogLocalContext = {}): void => {
        this.doForStream((o) => o.error(error, context));
    };
    trace = (error: string | Record<string, any> | Error, context: LogLocalContext = {}): void => {
        this.doForStream((o) => o.trace(error, context));
    };
    track = (value: string | Record<string, any> | Error, context: LogLocalContext = {}): void => {
        this.doForStream((o) => o.track(value, context));
    };

    doForStream(work: (stream: ILoggerStream) => void) {
        for (const stream of this.streams) {
            work(stream);
        }
    }
}
