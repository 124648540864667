import { ApolloLink, InMemoryCache, defaultDataIdFromObject, ApolloClient, HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
import { AppLogger } from '../logger/AppLogger';
import { EnvUtils } from '../utils/EnvUtils';

declare const LOGGER: AppLogger;

export class GraphqlClient {
    private constructor() {}

    public static async initialize(): Promise<ApolloClient<any>> {
        const client = new ApolloClient({
            link: GraphqlClient.createLink(),
            cache: GraphqlClient.createCache(),
            queryDeduplication: true,
        });
        LOGGER.info('AppoloClient initialized');

        return client;
    }

    private static createCache(): InMemoryCache {
        return new InMemoryCache({
            dataIdFromObject: (object: any) => {
                switch (object.__typename) {
                    case 'CustomProductFeed':
                        return `${object.__typename}`;
                    case 'ProductFeedResult':
                        return `${object.__typename}`;
                    case 'CompanyMwsMarketplace':
                        return `${object.__typename}:${object.marketplace}`;
                    case 'UserQuery':
                        return `${object.__typename}:${object.email}`;
                    case 'ProductVariant':
                        return `${object.__typename}:${object.sku}:${object.autopricing?.marketplace || '_'}`;
                    case 'Job':
                        return `${object.__typename}:${object.id}`;
                    case 'ProductSync':
                        return `ProductSync`;
                    default:
                        return defaultDataIdFromObject(object);
                }
            },
        });
    }

    // const requests
    private static createLink(): ApolloLink {
        return ApolloLink.from([
            // Note: I could pass global onError handling here to log errors over /qraphql
            // but I will lose change to intercept errors and won't be able to provide detailed log context
            // so I decided to handle it in Service
            new HttpLink({
                uri: EnvUtils.getApiEndpoint() + '/graphql',
                credentials: 'include',
                fetch,
            }),
        ]);
    }
}
