import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { EnumCompanyOnboardingState } from 'src/graphql/generated';

declare const SERVICES: ServiceProvider;

export interface ConnectLaterProps {
    path: string;
    text: string;
    onboardingStateToSet?: EnumCompanyOnboardingState;
}

const ConnectLater: FunctionComponent<ConnectLaterProps> = ({ path, text, onboardingStateToSet }) => {
    return (
        <NavLink
            to={path}
            className="connect-later-btn"
            onClick={async () => {
                if (!!onboardingStateToSet) await SERVICES.company.updateOnboardingState(onboardingStateToSet);
            }}
        >
            {text}
        </NavLink>
    );
};

export default ConnectLater;
