import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectMarketplace from '../ConnectMarketplaceNew';
import ConnectMarketplaceWizard, { ConnectMarketplaceWizardProps } from '../ConnectMarketplaceWizard';
import ConnectMarketplaceHeader from '../ConnectMarketplaceHeader';
import ConnectMallStep1Form, { createValidationSchema } from './ConnectMallStep1Form';
import ConnectMallStep2Form from './ConnectMallStep2Form';
import { MarketplaceRegion } from 'src/graphql/generated';
import { ConnectMallStep1Bloc } from './ConnectMallStep1Bloc';
import { ConnectMallStep2Bloc } from './ConnectMallStep2Bloc';
import { ConnectMarketplaceWizardBloc } from '../ConnectMarketplaceWizardBloc';

interface ConnectMallOrchestratorProps {
    region: MarketplaceRegion;
    showWizardComponents: boolean;
}

export const ConnectMallOrchestrator: FunctionComponent<ConnectMallOrchestratorProps> = ({
    region,
    showWizardComponents,
}) => {
    const { t } = useTranslation();
    const parentBloc = new ConnectMarketplaceWizardBloc(2, region, t);
    const step1Bloc = new ConnectMallStep1Bloc(region, parentBloc, null, t);
    const step2Bloc = new ConnectMallStep2Bloc(parentBloc, step1Bloc, t);
    return (
        <ConnectMall
            region={region}
            bloc={parentBloc}
            stepBlocs={[step1Bloc, step2Bloc]}
            i18nPrefix="connectMall"
            showWizardComponents={showWizardComponents}
        />
    );
};

const ConnectMall: FunctionComponent<ConnectMarketplaceWizardProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ConnectMarketplaceWizard {...props}>
            <ConnectMarketplace
                header={ConnectMarketplaceHeader}
                form={ConnectMallStep1Form}
                validationSchema={createValidationSchema(t)}
            />
            <ConnectMarketplace form={ConnectMallStep2Form} />
        </ConnectMarketplaceWizard>
    );
};
export default ConnectMall;
