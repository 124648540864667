import { FunctionComponent, useContext } from 'react';
import { Direction } from 'reactstrap/lib/Dropdown';
import { LanguageService } from '../../localization/LanguageService';
import { ILanguageContext, LanguageContext } from '../../Contexts/Language/LanguageContext';
import IconLanguageSelector from './IconLanguageSelector';
import TextLanguageSelector from './TextLanguageSelector';

import './LanguageSelector.scss';

export interface HasLanguageService {
    languageService: LanguageService;
}
export interface LanguageSelectorProps {
    type: 'text' | 'icon';
    direction?: Direction;
}

export interface LanguageSelectorImplProps {
    /**
     * List of languages that will be available
     */
    languages: string[];
    /**
     * Selected language
     */
    selected: string;
    direction: Direction;
    onSelection: (lang: string) => void;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({ type, direction }) => {
    const { languageService } = useContext<ILanguageContext>(LanguageContext);

    const props: LanguageSelectorImplProps = {
        languages: languageService.getAvailableLanguages(),
        selected: languageService.getLanguage(),
        direction,
        onSelection: (lng) => {
            languageService.changeLanguage(lng);
            window.location.reload();
        },
    };

    return (
        <div className="language-selector">
            {type === 'text' && <TextLanguageSelector {...props} />}
            {type === 'icon' && <IconLanguageSelector {...props} />}
        </div>
    );
};
LanguageSelector.defaultProps = {
    direction: 'down',
};

export default LanguageSelector;
