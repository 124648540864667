import { Component, FunctionComponent } from 'react';
import { HasFormProps, HasI18nProps } from 'src/common/props';
import { Formik, FormikConfig, FormikProps } from 'formik';
import Form from 'src/components/form/Form';
import { Button } from 'src/ui';
import * as yup from 'yup';
import SimpleForm, { FieldOption } from './SimpleForm';

export interface SubmitButtonProps {
    id?: string;
    className?: string;
}
export interface AbstractSimpleRenderProps extends HasI18nProps {
    //}, FormikProps<any> {
    Form: FunctionComponent;
    SubmitButton: FunctionComponent<SubmitButtonProps>;
    isSubmitting: boolean;
}
export interface AbstractSimpleFormProps<FD> extends HasI18nProps, HasFormProps<FD> {}
export abstract class AbstractForm<
    FD,
    P extends AbstractSimpleFormProps<FD> = AbstractSimpleFormProps<FD>,
> extends Component<P, {}> {
    abstract getFields(): FieldOption[][];
    abstract getValidationSchema(): { [key in keyof FD]: yup.BaseSchema<any, any, any> };

    renderContent(props: AbstractSimpleRenderProps) {
        const { Form: FormContent, SubmitButton } = props;
        return (
            <>
                <FormContent />
                <SubmitButton />
            </>
        );
    }

    render() {
        const { initialValues, initialErrors, initialTouched, isSubmitting, onSubmit, ...i18nProps } = this.props;
        const { t } = i18nProps;
        const formikProps: FormikConfig<FD> = {
            enableReinitialize: !!initialValues,
            initialValues,
            initialErrors,
            initialTouched,
            validationSchema: yup.object().shape(this.getValidationSchema()),
            onSubmit,
        };

        const FormContent: FunctionComponent = () => (
            <SimpleForm {...i18nProps} fields={this.getFields()} isSubmitting={isSubmitting} />
        );

        const SubmitButton: FunctionComponent<SubmitButtonProps> = ({ id, className }) => (
            <Button
                id={id}
                type="submit"
                color="primary"
                loading={isSubmitting}
                text={t(`btn.submit`)}
                className={className ?? ''}
            />
        );

        return (
            // don't pass context here, i encounter strange behaviour while trying it such as:
            // 'loosing focus on input when typing'
            <Formik {...formikProps}>
                <Form>
                    {this.renderContent({
                        ...i18nProps,
                        isSubmitting,
                        Form: FormContent,
                        SubmitButton,
                    })}
                </Form>
            </Formik>
        );
    }

    get t() {
        return this.props.t;
    }
}
