import { FunctionComponent, useEffect, useState } from 'react';

import './ProgressBar.scss';

export interface LoopingProgressBarProps {
    /**
     * How wide will a runner be in px
     */
    runnerWidth?: number;
    /**
     * How many % of parent will travel runner during each cycle
     */
    movementDistance?: number;
    /**
     * How often to move runner to its next position
     */
    refreshInterval?: number;
}

const LoopingProgressBar: FunctionComponent<LoopingProgressBarProps> = (props) => {
    const { runnerWidth, movementDistance, refreshInterval } = props;
    const [left, setLeft] = useState<number>(0);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLeft((left + movementDistance) % 120);
        }, refreshInterval);
        return () => clearTimeout(timeoutId);
    }, [left]);

    const style = {
        width: runnerWidth + 'px',
        marginLeft: left - 10 + '%',
    };

    return (
        <div className="progressBar">
            <div className="barStatus" style={style}></div>
        </div>
    );
};
LoopingProgressBar.defaultProps = {
    runnerWidth: 50,
    movementDistance: 2,
    refreshInterval: 50,
};

export default LoopingProgressBar;
