import { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui';
import { ConfirmationModalBloc } from './ConfirmationModalBloc';

export interface ConfirmationModalProps {
    bloc: ConfirmationModalBloc;
}
export interface ConfirmationModalStateData {
    title: string;
    paragraphs: string[];
    isOpen: boolean;
    cancelId?: string;
    confirmId?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const confirmationModalDefaults: ConfirmationModalStateData = {
    isOpen: false,
    title: '',
    paragraphs: [],
    onConfirm: () => {},
    onCancel: () => {},
};

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({ bloc }) => {
    const [stateData, setStateData] = useState<ConfirmationModalStateData>(confirmationModalDefaults);
    const { isOpen, title, paragraphs, cancelId, confirmId, onConfirm, onCancel } = stateData;
    const { t } = useTranslation();

    useEffect(() => {
        const modalSubscription = bloc.subscribe(setStateData);
        return () => {
            modalSubscription.unsubscribe();
        };
    }, []);

    const handleClose = () => setStateData(confirmationModalDefaults);
    const handleConfirm = () => {
        handleClose();
        onConfirm && onConfirm();
    };
    const handleCancel = () => {
        handleClose();
        onCancel && onCancel();
    };

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {paragraphs.map((content, idx) => (
                    <p key={idx}>{content}</p>
                ))}
            </ModalBody>
            <ModalFooter>
                <Button id={cancelId} color="secondary" onClick={handleCancel} text={t('btn.cancel')} />
                <Button id={confirmId} color="danger" onClick={handleConfirm} text={t('btn.confirm')} />
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
