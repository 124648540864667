import { FunctionComponent } from 'react';
import cx from 'classnames';
import { UISize } from '../UISize';

import 'flag-icon-css/css/flag-icon.min.css';
import './Flag.scss';

export interface FlagProps {
    code: string;
    size?: UISize;
    /** grayscale */
    dimmed?: boolean;
    /** Adds border-radius */
    rounded?: boolean;
    /** 1x1 ratio if true vs 4x3 if false */
    squared?: boolean;
}

const Flag: FunctionComponent<FlagProps> = ({ code, size, dimmed, rounded, squared }) => {
    code = code.toLowerCase();
    if (code === 'en') code = 'gb';
    if (code === 'uk') code = 'gb';
    if (code === 'cs') code = 'cz';
    if (code === 'global') code = 'xx';

    const classNames = cx({
        'flag-icon': true,
        [`flag-icon-${code}`]: true,
        [size]: true,
        'flag-icon-dimmed': dimmed,
        'flag-icon-rounded': rounded,
        'flag-icon-squared': squared,
    });

    return <div className={classNames} />;
};
Flag.defaultProps = {
    size: 'normal',
    dimmed: false,
    squared: false,
};

export default Flag;
