import { FormData } from './ConnectMallStep2Form';
import { WizardStepBloc } from 'src/library/bloc';
import { ConnectMarketplaceStateData } from '../ConnectMarketplaceNew';
import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ConnectMarketplaceState } from '../ConnectMarketplaceBloc';

export class ConnectMallStep2Bloc extends WizardStepBloc<
    'get',
    ConnectMarketplaceState,
    ConnectMarketplaceStateData<ConnectMarketplaceState, FormData>
> {
    public produceEvent() {}
    protected onParentCompleted(): void {}
    protected onPrevStepCompleted(): void {}

    public getName(): string {
        return 'ConnectMallStep2Bloc';
    }

    protected onPrevStepChange(
        stateData: ConnectMarketplaceStateData<ConnectMarketplaceState, MarketplaceCredentials>,
    ): void {
        const { state, data } = stateData;
        switch (state) {
            case 'credentialsDeleted':
                this.next({
                    state: 'locked',
                    data: this.getFormData(null),
                });
                break;
            case 'credentialsSaved':
            case 'completed':
                this.next({
                    state: 'credentialsSaved',
                    data: this.getFormData(data),
                    allowDelete: false,
                });
                break;
        }
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            webhookUrl: data?.webhookUrl ?? '',
        };
    }
}
