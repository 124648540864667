import { FunctionComponent } from 'react';
import { ShoptetRegistrationForm } from './ShoptetRegistrationForm';
import AuthLayout from '../AuthLayout';
import { RegistrationFooter } from '../Registration/RegistrationFooter';
import { AuthWithEmail } from '../AuthWith/AuthWithEmail';
import { useTranslation } from 'src/hooks/useTranslation';
import { ShoptetRegistrationBloc } from './ShoptetRegistrationBloc';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';

export interface ShoptetRegistrationProps {
    bloc: IAbstractBloc;
}
export const ShoptetRegistration: FunctionComponent<ShoptetRegistrationProps> = ({ bloc }) => {
    return (
        <AuthLayout
            type="registrationShoptet"
            bloc={bloc}
            authWithCards={[AuthWithEmail]}
            content={ShoptetRegistrationForm}
            footer={RegistrationFooter}
        />
    );
};

export const ShoptetRegistrationOrchestrator: FunctionComponent = () => {
    const { t } = useTranslation();
    return <ShoptetRegistration bloc={new ShoptetRegistrationBloc({ state: 'empty' }, t)} />;
};
