import { FunctionComponent } from 'react';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ConnectFormProps } from '../ConnectMarketplaceNew';
import ConnectMarketplaceForm, { FieldOption } from '../ConnectMarketplaceForm';

export interface FormData {
    clientId: string;
    // need to be defined for step2 to be able to read it
    webhookUrl: string;
}

export const createValidationSchema = (t: TFunction) =>
    yup.object().shape({
        clientId: yup.string().required(t('validation.required')),
    });

const fields: FieldOption[][] = [[{ name: 'clientId' }]];

const ConnectMallStep1Form: FunctionComponent<ConnectFormProps> = (props) => {
    return <ConnectMarketplaceForm {...props} fields={fields} />;
};

export default ConnectMallStep1Form;
