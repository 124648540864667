import { FunctionComponent } from 'react';
import CardFooter from 'src/ui/card/CardFooter';
import { Button } from 'src/ui';
import { Badge } from 'reactstrap';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RouteUtils } from 'src/utils/RouteUtils';
import { MarketplaceRegion } from 'src/graphql/generated';
import { platformFromMarketplaceRegion } from 'src/library/marketplace';
import { ConnectMarketplaceState } from './ConnectMarketplaceBloc';

export interface ConnectMarketplaceFooterProps {
    state: ConnectMarketplaceState;
    region: MarketplaceRegion;
    disabled?: boolean;
    showDelete?: boolean;
    i18nPrefix?: string;
    onSubmit?: () => void;
    onDelete?: () => void;
    onContinue?: () => void;
}

const ConnectMarketplaceFooter: FunctionComponent<ConnectMarketplaceFooterProps> = (props) => {
    const { state, region, showDelete, disabled, i18nPrefix, onSubmit, onDelete, onContinue } = props;
    const { t } = useTranslation();

    const name = t(`platform.${platformFromMarketplaceRegion(region)}`);
    // i18nPrefix contains step suffix, get rit of it for header
    const parentPrefix = i18nPrefix.split('.')[0];

    const SaveButton: FunctionComponent = () => {
        let textBtn = null;
        let textFallbackBtn = null;
        const saveRelatedStates: ConnectMarketplaceState[] = [
            'missingCredentials',
            'credentialsDeleted',
            'savingCredentials',
        ];
        const editRelatedStates: ConnectMarketplaceState[] = ['hasCredentials', 'incorrect'];
        if (saveRelatedStates.includes(state)) {
            textBtn = `${i18nPrefix}.btn.submit`;
            textFallbackBtn = 'connectMarketplace.btn.submit';
        }
        if (editRelatedStates.includes(state)) {
            textBtn = `${i18nPrefix}.btn.edit`;
            textFallbackBtn = `btn.edit`;
        }
        if (!textBtn) return null;
        return (
            <Button text={t([textBtn, textFallbackBtn])} className="save-btn" onClick={onSubmit} loading={disabled} />
        );
    };

    const DeleteButton: FunctionComponent = () => {
        if (!showDelete) return null;
        return (
            <Button
                text={t('btn.delete')}
                className="delete-btn"
                onClick={onDelete}
                color="danger"
                disabled={disabled}
            />
        );
    };
    const ContinueButton: FunctionComponent = () => {
        if (state !== 'credentialsSaved' && state !== 'completed') return null;
        return <Button text={t([`${i18nPrefix}.btn.finish`, 'connectMarketplace.btn.finish'])} onClick={onContinue} />;
    };

    const CreateAccountButton: FunctionComponent = () => {
        if (state !== 'missingCredentials') return null;
        return (
            <div className="createAccount-wrapper">
                <span>{t('connectMarketplace.noAccount', { name })}</span>
                <Button
                    text={t('connectMarketplace.createAccount', { name })}
                    color="link"
                    disabled={disabled}
                    onClick={RouteUtils.handleOpenUrl(t(`${parentPrefix}.createAccountLink`))}
                />
            </div>
        );
    };

    const SuccessBadge: FunctionComponent = () => {
        let badgeText = null;
        if (state === 'credentialsSaved') badgeText = t('connectMarketplace.savedSuccessfully', { name });
        if (state === 'credentialsDeleted') badgeText = t('connectMarketplace.deletedSuccessfully', { name });
        if (!badgeText) return null;
        return <Badge color="success">{t(badgeText)}</Badge>;
    };

    return (
        <CardFooter showSeparator>
            <ContinueButton />
            <SaveButton />
            <DeleteButton />
            <CreateAccountButton />
            <SuccessBadge />
        </CardFooter>
    );
};
ConnectMarketplaceFooter.defaultProps = {
    showDelete: false,
    disabled: false,
    onSubmit: noop,
    onDelete: noop,
    onContinue: noop,
};

export default ConnectMarketplaceFooter;
