import { IUserContext } from 'src/Contexts/User/UserContext';
import { StatusError } from 'src/errors';
import { TFunction } from 'src/hooks/useTranslation';
import { AbstractFormBloc } from 'src/library/bloc/AbstractFormBloc';
import { paths } from 'src/routing/paths';
import { ErrorInterceptor } from 'src/services/Service';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { FormData } from './LoginForm';

declare const SERVICES: ServiceProvider;

export class LoginBloc extends AbstractFormBloc<any, FormData> {
    constructor(userContext: IUserContext, t: TFunction) {
        super({ state: 'empty' }, t);
    }

    public getName(): string {
        return 'LoginBloc';
    }

    protected getFormData(data: any): FormData {
        return {
            email: data?.email ?? '',
            password: data?.password ?? '',
        };
    }

    protected requestGetData(): Promise<any> {
        return Promise.resolve();
    }

    protected requestSetData(data: FormData): Promise<void> {
        const loggerInterceptor: ErrorInterceptor<StatusError> = (error: StatusError) => {
            if ([401, 403].includes(error.statusCode)) {
                return; // don't log
            } else {
                throw error;
            }
        };
        return SERVICES.auth.passwordLogin(data.email, data.password, loggerInterceptor);
    }

    protected responseSetData() {
        // this.userContext.onLogin();
        window.location.href = paths.eve.dashboard;
    }

    protected getError(error: Error): string {
        if (error instanceof StatusError) {
            switch (error.statusCode) {
                case 401:
                    return this.t('error.badUsername');
                case 403:
                    return this.t('error.accountBlocked');
            }
        }
        return error?.message;
    }
}
