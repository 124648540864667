import { TFunction } from 'i18next';
import { AbstractBloc } from './AbstractBloc';
import { StateData } from './StateData';

export type WizardState = 'loading' | 'loaded' | 'completed' | 'incorrect';
export interface WizardStateData<T> extends StateData<WizardState, T> {}

export abstract class WizardBloc<T> extends AbstractBloc<'get', WizardStateData<T>> {
    private confirmedInits = 0;
    protected abstract onStart(): void;
    private requireConfirmedInits: number;

    /**
     * @param requireConfirmedInits must match stepBlocs count
     */
    constructor(requireConfirmedInits: number, t: TFunction) {
        super({ state: 'loading' }, t);
        this.requireConfirmedInits = requireConfirmedInits;
    }

    public start = () => {
        this.confirmedInits++;
        if (this.confirmedInits === this.requireConfirmedInits) {
            // start getting data after all child UI has subscribed to its blocs
            this.onStart();
        }
    };

    public produceEvent(action: 'get', isLoading: boolean, data: any, error: Error) {
        if (error)
            return this.next({
                state: 'incorrect',
                error: this.getErrorMessage(error),
            });
        if (isLoading) return this.next({ state: 'loading' });

        this.next({
            state: 'completed',
            data,
        });
    }
}
