const dateFormats: Record<string, string> = {
    cs: 'D.M.YYYY',
    en: 'D.M.YYYY',
};
const dateTimeFormats: Record<string, string> = {
    cs: 'D.M.YYYY H:mm',
    en: 'D.M.YYYY H:mm',
};
const timeFormats: Record<string, string> = {
    cs: 'H:mm',
    en: 'H:mm',
};

export function getDateFormat(lang: string): string {
    return dateFormats[lang] || 'l';
}

export function getDateTimeFormat(lang: string): string {
    return dateTimeFormats[lang] || 'YYYY-MM-DD HH:mm:ss';
}

export function getTimeFormat(lang: string): string {
    return timeFormats[lang] || 'HH:mm';
}

export function dateFromObjectId(objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}
