import { FunctionComponent, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense } from './Suspense';
import { paths } from './paths';
import Page from './Page';
import { sharedRoutes } from './SharedRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { OnboardingRoutes } from './OnboardingRoutes';
import { LoginOrchestrator } from 'src/screens/Auth/Login/Login';
import { RegistrationOrchestrator } from 'src/screens/Auth/Registration/Registration';
import { ResetPasswordOrchestrator } from 'src/screens/Auth/ResetPassword/ResetPassword';
import { ShoptetLoginOrchestrator } from 'src/screens/Auth/ShoptetLogin/ShoptetLogin';
import { ShoptetRegistrationOrchestrator } from 'src/screens/Auth/ShoptetRegistration/ShoptetRegistration';
import { SetPasswordOrchestrator } from 'src/screens/Auth/SetPassword/SetPassword';
import { ShoptetOAuthRequest } from 'src/screens/Auth/ShoptetLogin/OAuth/OAuthRequest';
import { ShoptetOAuthRedirect } from 'src/screens/Auth/ShoptetLogin/OAuth/OAuthRedirect';
import { HasRoutingProps } from 'src/common/props';
import ConfirmEmailPage from 'src/screens/Onboarding/ConfirmEmail';

const PrivacyPolicy = lazy(
    () => import('../screens/PrivacyPolicy/PrivacyPolicy' /* webpackChunkName: "PrivacyPolicy" */),
);

const TermsOfService = lazy(
    () => import('../screens/TermsOfService/TermsOfService') /* webpackChunkName: "TermsOfService" */,
);

export const FullpageRoutes: FunctionComponent<HasRoutingProps> = ({ user, url }) => (
    // onboarding routes
    <Routes>
        <Route
            path="onboarding/*"
            element={
                <ProtectedRoute>
                    <OnboardingRoutes user={user} />
                </ProtectedRoute>
            }
        />

        <Route path="terms-of-service" element={<Suspense element={TermsOfService} />} />

        <Route path={paths.emailVerification} element={<Suspense element={ConfirmEmailPage} />} />

        {/* // Auth rutes */}
        <Route
            path={paths.oauth.shoptet.login}
            element={
                <Page>
                    <ShoptetOAuthRequest />
                </Page>
            }
        />
        <Route
            path={paths.oauth.shoptet.afterauth}
            element={
                <Page>
                    <ShoptetOAuthRedirect />
                </Page>
            }
        />

        <Route
            path={paths.auth.resetPassword}
            element={
                <Page>
                    <ResetPasswordOrchestrator />
                </Page>
            }
        />
        <Route
            path={paths.auth.resetPasswordFinalize}
            element={
                <Page>
                    <SetPasswordOrchestrator />
                </Page>
            }
        />

        <Route
            path={paths.auth.shoptet.registration}
            element={
                <Page>
                    <ShoptetRegistrationOrchestrator />
                </Page>
            }
        />
        <Route
            path={paths.auth.registration}
            element={
                <Page>
                    <RegistrationOrchestrator />
                </Page>
            }
        />
        <Route
            path={paths.auth.shoptet.login}
            element={
                <Page>
                    <ShoptetLoginOrchestrator />
                </Page>
            }
        />
        {user && <Route path={paths.auth.login} element={<Navigate to={paths.eve.dashboard} />} />}
        <Route
            path={paths.auth.login}
            element={
                <Page>
                    <LoginOrchestrator />
                </Page>
            }
        />

        <Route path={paths.pp} element={<Suspense element={PrivacyPolicy} />} />

        <Route index element={<Navigate to={paths.auth.login} />} />

        {sharedRoutes(url)}
    </Routes>
);
