import { uniqueId } from 'lodash';
import { AppLogger } from 'src/logger/AppLogger';
import { ProgressBarBloc } from 'src/ui/progressBar/ProgressBarBloc';

declare const LOGGER: AppLogger;

export class ProgressBarService {
    private progressUpdaters: { [id: string]: ProgressBarBloc } = {};

    /**
     * Get progress updater for given id.
     * @param id
     * @returns progress updater observable
     */
    get(id: string): ProgressBarBloc {
        const progressUpdater = this.progressUpdaters[id];
        if (!progressUpdater) {
            LOGGER.warn(`Progress updater ${id} not found`);
        }
        return progressUpdater;
    }

    /**
     * Creates progress updater
     * @returns progress updater id
     */
    create(initialValue = 0): string {
        const id = uniqueId('progress-updater-');
        this.progressUpdaters[id] = new ProgressBarBloc(initialValue);
        return id;
    }

    /**
     * Deletes progress updater if exists.
     * @param id
     */
    delete(id: string) {
        delete this.progressUpdaters[id];
    }
}
