import { SpecificError } from 'src/errors';
import {
    Fulfillment,
    FulfillmentInput,
    GetFulfillmentDocument,
    GetFulfillmentQuery,
    GetFulfillmentQueryVariables,
    GetOrdersPageDocument,
    GetPickupListsDocument,
    UpdateFulfillmentDocument,
    UpdateFulfillmentMutation,
    UpdateFulfillmentMutationVariables,
} from 'src/graphql/generated';
import { getOperationName } from 'apollo-link';
import { Service } from '../Service';

export type GetFulfillmentResult = Pick<
    Fulfillment,
    | '_id'
    | 'trackingNumber'
    | 'shipDate'
    | 'carrier'
    | 'carrierName'
    | 'shipMethod'
    | 'trackingUrl'
    | 'packages'
    | 'marketplaceOrderIds'
>;
export type SaveFulfillmentResult = Pick<
    Fulfillment,
    '_id' | 'trackingNumber' | 'shipDate' | 'carrier' | 'trackingUrl'
>;
export type SaveAsyncFulfillmentResult = Pick<
    Fulfillment,
    '_id' | 'trackingNumber' | 'shipDate' | 'carrier' | 'trackingUrl' | 'syncError'
>;

export class FulfillmentService extends Service {
    async get(variables: GetFulfillmentQueryVariables): Promise<GetFulfillmentResult> {
        const response = await this.query<GetFulfillmentQuery, GetFulfillmentQueryVariables>(
            {
                query: GetFulfillmentDocument,
                variables,
                fetchPolicy: 'no-cache',
            },
            {
                method: 'GetFulfillment',
                params: variables,
            },
        );

        return response?.fulfillment ?? null;
    }

    async save(fulfillment: FulfillmentInput, fulfillmentChannel: string): Promise<SaveFulfillmentResult> {
        // safety check because providing empty packages will wipe out already stored ones
        if ('packages' in fulfillment && !fulfillment.packages.length) {
            // temporary log to console
            console.error('empty pacakges :>> ', {
                method: 'updateFulfillment',
                emptyPackages: 'packages' in fulfillment && !fulfillment.packages.length,
                fulfillment,
            });
            throw new SpecificError('emptyPackages');
        }
        const variables: UpdateFulfillmentMutationVariables = { fulfillment, fulfillmentChannel };
        const response = await this.mutate<UpdateFulfillmentMutation, UpdateFulfillmentMutationVariables>(
            {
                mutation: UpdateFulfillmentDocument,
                variables,
                refetchQueries: [getOperationName(GetOrdersPageDocument), getOperationName(GetPickupListsDocument)],
            },
            {
                method: 'updateFulfillment',
                params: variables,
            },
        );

        if (!response || !response.updateFulfillment) {
            throw new Error("Couldn't confirm the order");
        }
        if (response.updateFulfillment?.syncError) {
            throw new SpecificError('syncError', { error: response.updateFulfillment.syncError });
        }

        return response.updateFulfillment;
    }
}
