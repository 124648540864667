import { RequestContext, Service } from '../Service';
import {
    CredentialsDocument,
    CredentialsQuery,
    CredentialsQueryVariables,
    DeleteMarketplaceConnectionDocument,
    DeleteMarketplaceConnectionMutation,
    DeleteMarketplaceConnectionMutationVariables,
    GetDeveloperIdDocument,
    GetDeveloperIdQuery,
    GetDeveloperIdQueryVariables,
    MarketplaceName,
    MarketplaceRegion,
    SaveCredentialsDocument,
    SaveCredentialsMutation,
    SaveCredentialsMutationVariables,
    MarketplaceStatus as _MarketplaceStatus,
    GetMarketplacesDocument,
    MarketplaceStatus,
    Formula,
    GetMarketplacesQuery,
    UpdateMarketplaceMutation,
    UpdateMarketplaceDocument,
    UpdateMarketplaceMutationVariables,
} from '../../graphql/generated';
import { MutationOptions } from '@apollo/client';
import {
    MarketplaceError,
    OperationFailedError,
    UnavailableServiceError,
    UnexpectedError,
    SpecificError,
} from 'src/errors';

export type GetMarketplacesResult = Pick<
    MarketplaceStatus,
    'id' | 'marketplace' | 'region' | 'currency' | 'error' | 'hidden' | 'status'
> & { formula?: Pick<Formula, 'add' | 'multiply'> };

export type MarketplaceCredentials = SaveCredentialsMutationVariables & { webhookUrl?: string };
export type ApplicationCredentials = {
    id: string;
    name: string;
};

export class MarketplaceService extends Service {
    async getMarketplaces(): Promise<GetMarketplacesResult[]> {
        let result = await this.query<GetMarketplacesQuery>(
            { query: GetMarketplacesDocument },
            { method: 'getMarketplaces' },
        );

        return result.marketplaces;
    }

    async getApplicationCredentials(region: MarketplaceRegion): Promise<ApplicationCredentials> {
        const variables: GetDeveloperIdQueryVariables = { region };
        const response = await this.query<GetDeveloperIdQuery>(
            {
                query: GetDeveloperIdDocument,
                variables,
            },
            {
                method: 'getCredentials',
                params: variables,
            },
        );

        if (!response?.developerId) throw new Error('No application credentials found');

        return {
            name: 'EVE',
            id: response.developerId,
        };
    }

    /**
     * Temporary common interface for all marketplaces until GW will provide.
     * @returns credentials
     */
    async getCredentials(region: MarketplaceRegion): Promise<MarketplaceCredentials> {
        const variables: CredentialsQueryVariables = {
            serviceScope: region,
        };
        const context: RequestContext = {
            method: 'getCredentials',
            params: variables,
        };
        let response: any = await this.query<CredentialsQuery>(
            {
                query: CredentialsDocument,
                variables,
            },
            context,
        );

        const result = response.credentials;
        if (result?.success === false) {
            // for no credentials service returns success false with 404 error so handle it as valid scenario
            if (result?.error?.match(/^HTTPError.+404.+$/)) {
                return null;
            }
            if (result?.error?.match(/^RequestError.+ECONNREFUSED.+$/)) {
                throw new UnavailableServiceError(context);
            }
            throw new OperationFailedError('sucess', 'false', context);
        }

        return result;
    }

    /**
     * Saves credentials
     * @param variables
     */
    async saveCredentials(variables: MarketplaceCredentials): Promise<boolean> {
        const request: MutationOptions<SaveCredentialsMutation> = {
            mutation: SaveCredentialsDocument,
            variables,
        };
        const context: RequestContext = {
            method: 'saveCredentials',
            params: request.variables,
        };
        const response = await this.mutate(request, context);
        if (!response.saveCredentials) {
            throw new UnexpectedError('no data', context);
        }
        const { success, message, error, credentials } = response.saveCredentials;
        if (!success) {
            if (message === 'Duplicate credentials') {
                throw new SpecificError('duplicateCredentials', { property: error });
            }
            if (error.startsWith('Duplicate supplier branch id')) {
                // so far only alza service returns this kind of error
                throw new MarketplaceError(MarketplaceName.alza_cz, 'duplicitBranchId', {
                    branchId: variables.supplierBranchId,
                });
            }
            throw new UnexpectedError(message, context);
        }

        return credentials;
    }

    /**
     * Delete credentials
     * @param variables
     */
    async disconnectMarketplace(variables: DeleteMarketplaceConnectionMutationVariables) {
        const request: MutationOptions<DeleteMarketplaceConnectionMutation> = {
            mutation: DeleteMarketplaceConnectionDocument,
            variables,
        };
        const context: RequestContext = {
            method: 'disconnectMarketplace',
            params: request.variables,
        };
        const response = await this.mutate(request, context);
        if (!response.deleteMarketplaceConnection) {
            throw new UnexpectedError('no data', context);
        }
        const { success, message } = response.deleteMarketplaceConnection;
        if (!success) {
            throw new UnexpectedError(message, context);
        }

        return true;
    }

    async updateMarketplaceStatus(variables: UpdateMarketplaceMutationVariables): Promise<GetMarketplacesResult[]> {
        const request: MutationOptions<UpdateMarketplaceMutation> = {
            mutation: UpdateMarketplaceDocument,
            variables,
        };
        const context: RequestContext = {
            method: 'updateMarketplaceStatus',
            params: request.variables,
        };
        const response = await this.mutate(request, context);
        return response.updateMarketplaceStatus;
    }
}
