export enum ENV_KEY {
    GATEWAY_URL = 'REACT_APP_GATEWAY_URL',

    SENTRY_DSN = 'REACT_APP_SENTRY_DSN',

    CORALOGIX_URL = 'REACT_APP_CORALOGIX_URL',
    CORALOGIX_PRIVATE_KEY = 'REACT_APP_CORALOGIX_PRIVATE_KEY',
    CORALOGIX_APP_NAME = 'REACT_APP_CORALOGIX_APP_NAME',
    CORALOGIX_SUBSYS_NAME = 'REACT_APP_CORALOGIX_SUBSYS_NAME',

    SEGMENT_ID = 'REACT_APP_SEGMENT_ID',

    PUBLIC_URL = 'REACT_APP_PUBLIC_URL',

    NODE_ENV = 'NODE_ENV',
}

export class EnvUtils {
    private constructor() {}

    static find(key: ENV_KEY): string {
        return process.env[key];
    }

    static get(key: ENV_KEY): string {
        const val = process.env[key];
        if (!val) {
            throw new Error(`Missing '${key}' env in config`);
        }

        return val;
    }

    /**
     * Gets env value and parses it as boolean
     * @param key
     * @returns parsed env value
     * @throws error if missing
     */
    static getBoolean(key: ENV_KEY): boolean {
        return EnvUtils.get(key) === 'true';
    }

    /**
     * Gets env value and parses it as number ( as float number if containing '.')
     * @param key
     * @returns parsed env value
     * @throws error if missing
     */
    static getNumber(key: ENV_KEY): number {
        const value = EnvUtils.get(key);

        return value.includes('.') ? parseFloat(value) : parseInt(value);
    }

    static isProduction(): boolean {
        return EnvUtils.get(ENV_KEY.NODE_ENV) === 'production';
    }

    static isDevelopment(): boolean {
        return EnvUtils.get(ENV_KEY.NODE_ENV) === 'development';
    }

    static getApiEndpoint(): string {
        return EnvUtils.get(ENV_KEY.GATEWAY_URL);
    }
}
