import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui';
import Card from 'src/ui/card/Card';
import CardHeader, { CardHeaderProps } from 'src/ui/card/CardHeader';
import CardContent from 'src/ui/card/CardContent';
import PlatformLogo from '../logo/PlatformLogo';
import { useToggleState } from 'src/hooks';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Platform } from 'src/graphql/generated';
import { HasChildren } from 'src/common/props';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUtils } from 'src/utils/RouteUtils';

export interface MarketplaceCardProps extends HasChildren {
    cardId?: string | number;
    platform: Platform;
    // use to override heading prefix (platform by default)
    headingPrefix?: string;
    collapsable?: boolean;
    badge?: JSX.Element;
}

interface ILocatonState {
    key: string;
    opened: boolean;
}

const MarketplaceCard: FunctionComponent<MarketplaceCardProps> = (props) => {
    const { cardId, platform, headingPrefix, collapsable, badge, children } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'marketplaceSelector' });

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as any as ILocatonState;
    const locationKey = cardId ?? platform;
    const { isOpen, toggle } = useToggleState((locationState?.key === locationKey && locationState.opened) ?? false);

    const handleToggle = () => {
        toggle();
        navigate(RouteUtils.getPath(), {
            replace: true,
            state: {
                key: locationKey,
                opened: !isOpen,
            } as ILocatonState,
        });
    };

    const i18nHeadingPrefix = headingPrefix ?? platform;
    const icon = isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />;

    const headerProps: CardHeaderProps = {
        image: <PlatformLogo type={platform} />,
        title: t(`${i18nHeadingPrefix}.title`),
        description: t(`${i18nHeadingPrefix}.description`),
        badge,
        button: (
            <Button
                id={`marketplaceSelection-regions-toggleBtn`}
                color="secondary"
                text={isOpen ? t('btn.hide') : t('btn.show')}
                icon={icon}
                onClick={handleToggle}
            />
        ),
    };

    return (
        <Card>
            <CardHeader {...headerProps} />
            <CardContent collapsable={collapsable} showSeparator isOpen={isOpen}>
                {children}
            </CardContent>
        </Card>
    );
};
MarketplaceCard.defaultProps = {
    collapsable: true,
};

export default MarketplaceCard;
