import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { FormData } from './ConnectEMagForm';

export class ConnectEMagStepBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectEMagStepBloc';
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            username: data?.username ?? '',
            password: data?.password ?? '',
        };
    }
}
