import { ReactNode } from 'react';
import { Options, UpdateOptions } from 'react-toast-notifications';
import { Subject } from 'rxjs';
import { ToastWrapperData } from 'src/ui/toast/ToastWrapper';

export class ToastWrapperService extends Subject<ToastWrapperData> {
    private defaultOptions: Options = {
        autoDismiss: true,
    };

    add(content: ReactNode, optionsOverride?: Options, callback?: (id: string) => void) {
        this.next({
            action: 'add',
            params: {
                content,
                options: {
                    ...this.defaultOptions,
                    ...optionsOverride,
                },
                callback,
            },
        });
    }

    update(id: string, optionsOverride?: UpdateOptions, callback?: (id: string) => void) {
        this.next({
            action: 'update',
            params: {
                id,
                options: {
                    ...this.defaultOptions,
                    ...optionsOverride,
                },
                callback,
            },
        });
    }

    remove(id: string, callback?: (id: string) => void) {
        this.next({
            action: 'remove',
            params: { id, callback },
        });
    }

    removeAll() {
        this.next({ action: 'removeAll' });
    }
}
