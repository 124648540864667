import { FunctionComponent } from 'react';
import { useTranslation } from 'src/hooks/useTranslation';
import { OAuthRedirectBloc } from './OAuthRedirectBloc';
import StatusPage from './StatusPage';

export const ShoptetOAuthRedirect: FunctionComponent = () => {
    const { t } = useTranslation();

    return <StatusPage type="redirect" bloc={new OAuthRedirectBloc(t)} />;
};
