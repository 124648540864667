import { LogLocalContext } from './AppLogger';
import { LoggerStream } from './LoggerStream';

export class ConsoleLogger extends LoggerStream<any> {
    constructor() {
        super('ConsoleLogger');
    }

    info(value: string | any): void {
        console.info('[INFO]', value);
    }

    warn(value: string | any): void {
        console.warn('[WARN]', value);
    }

    error(error: any | Error, options: LogLocalContext = {}): void {
        const payload: any = {
            ...this.context,
            extra: options,
            error,
        };

        console.error('[ERROR]', payload);
    }

    trace(value: string | any): void {
        console.trace('[TRACE]', value);
    }

    track(value: string | any): void {
        console.log('[TRACK]', value);
    }
}
