import React, { FunctionComponent } from 'react';
import { useState, useEffect } from 'react';
import { userIdToOneSignal } from '../../library/oneSignal';
import { AppLogger } from '../../logger/AppLogger';
import { ServiceProvider } from '../../services/ServiceProvider';
import { paths } from 'src/routing/paths';
import Loader from 'src/ui/loader/Loader';
import { ESegmentEvent } from 'src/library/segment';
import { GetUserResult } from 'src/services/user/UserService';
import { useNavigate } from 'react-router-dom';
import { EFeatureName, EnumCompanyOnboardingState } from 'src/graphql/generated';
import { EnvUtils } from 'src/utils/EnvUtils';

declare const LOGGER: AppLogger;
declare const SERVICES: ServiceProvider;

type OnLoginOptions = {
    setLoggerContext?: boolean;
    setOneSignal?: boolean;
};
export interface IUserContext {
    user: GetUserResult;

    /**
     * Handles actions once user is authenticated:
     * - request user
     * - reset children components states by displaying LoadingPage (which will unmount them).
     * - set logger global context for fetched user (not suggested when logged as different user)
     * - set one signal (not suggested when logged as different user)
     * - redirect
     * @params options
     */
    onLogin: (options?: OnLoginOptions) => Promise<void>;

    /**
     * Handles actions once user is logged out:
     * - clear user
     * - reset children components states by displaying LoadingPage (which will unmount them).
     * - clear logger global context
     * - redirect to /login
     */
    onLogout: () => Promise<void>;

    isFeatureEnabled: (feature: EFeatureName) => boolean;
    isFeatureBlocked: (feature: EFeatureName) => boolean;
}

export const UserContext = React.createContext<IUserContext>(null);
UserContext.displayName = 'UserContext';

export const UserContextProvider: FunctionComponent = ({ children }) => {
    const [user, setUser] = useState<GetUserResult>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        handleLogin().catch(LOGGER.error);
    }, []);

    const setGlobalLoggerContext = (user: GetUserResult) => {
        if (user) {
            LOGGER.setContext({
                companyId: user.company?._id,
                user: {
                    id: user._id,
                    email: user.email,
                },
            });
        } else {
            LOGGER.setContext({
                companyId: null,
                user: null,
            });
        }
    };

    const handleLogin = async (customOptions?: OnLoginOptions): Promise<void> => {
        try {
            // throw new Error('test error');
            const options = {
                setLoggerContext: true,
                setOneSignal: true,
                ...(customOptions ?? {}),
            };
            setLoading(true);
            setUser(null);
            setGlobalLoggerContext(null);
            const user = await SERVICES.user.getUser();
            setUser(user);
            setLoading(false);

            if (!user) return;

            if (options.setLoggerContext) {
                setGlobalLoggerContext(user);
            }
            if (options.setOneSignal && EnvUtils.isProduction()) {
                await userIdToOneSignal(user._id);
            }

            LOGGER.track({ event: ESegmentEvent.USER_LOGGED });

            forwardWhenOnboarding(user);
        } catch (error) {
            LOGGER.error(error);
            setLoading(false);
            navigate(paths.serverError, {
                replace: true,
                state: {
                    returnUrl: paths.auth.login,
                    desc: error.message,
                },
            });
        }
    };

    const handleLogout = async (): Promise<void> => {
        LOGGER.info('UserContext.handleLogout');

        setLoading(true);
        setUser(null);
        setGlobalLoggerContext(null);
        setLoading(false);
        navigate(paths.auth.login);
    };

    /**
     * Check requesting feature agains user features.
     * If no data available return negation of type
     * - if asking for pass, deny
     * - if asking for bloc, confirm
     * @param feature
     * @param type
     * @returns boolean
     */
    const handleFeatureCheck = (feature: EFeatureName, type: 'pass' | 'block') => {
        const defaultResult = type === 'pass' ? false : true;

        return user?.features?.[type]?.includes(feature) ?? defaultResult;
    };

    const forwardWhenOnboarding = (user: GetUserResult) => {
        switch (user?.company?.onboardingState) {
            case EnumCompanyOnboardingState.SOURCE_SELECTION:
                LOGGER.info('Onboarding not completed. Redirecting to "connect datasource"');
                navigate(paths.onboarding.connectDataSource);
                return;
            case EnumCompanyOnboardingState.MARKETPLACES_CONNECTION:
                LOGGER.info('Onboarding not completed. Redirecting to "connect marketplace"');
                navigate(paths.onboarding.connectMarketplaces);
                return;
            default:
                // DO not navigate in general here, cause it will always got there no matter your url
                return;
        }
    };

    const context: IUserContext = {
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
        isFeatureEnabled: (f) => handleFeatureCheck(f, 'pass'),
        isFeatureBlocked: (f) => handleFeatureCheck(f, 'block'),
    };

    if (loading) {
        return <Loader fullpage centered />;
    }

    return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};
