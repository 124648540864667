import { FunctionComponent } from 'react';
import { useTranslation } from 'src/hooks/useTranslation';
import { OAuthRequestBloc } from './OAuthRequestBloc';
import StatusPage from './StatusPage';

export const ShoptetOAuthRequest: FunctionComponent = () => {
    const { t } = useTranslation();

    return <StatusPage type="request" bloc={new OAuthRequestBloc(t)} />;
};
