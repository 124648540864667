import { FunctionComponent, useContext } from 'react';
import { IUserContext, UserContext } from 'src/Contexts/User/UserContext';
import { useTranslation } from 'src/hooks/useTranslation';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';
import AuthLayout from '../AuthLayout';
import { AuthWithShoptet } from '../AuthWith/AuthWithShoptet';
import { LoginBloc } from './LoginBloc';
import { LoginFooter } from './LoginFooter';
import { LoginForm } from './LoginForm';

export interface LoginProps {
    bloc: IAbstractBloc;
}
export const Login: FunctionComponent<LoginProps> = ({ bloc }) => {
    return (
        <AuthLayout
            type="login"
            bloc={bloc}
            authWithCards={[AuthWithShoptet]}
            content={LoginForm}
            footer={LoginFooter}
        />
    );
};

export const LoginOrchestrator: FunctionComponent = () => {
    const { t } = useTranslation();
    const userContext = useContext<IUserContext>(UserContext);

    return <Login bloc={new LoginBloc(userContext, t)} />;
};
