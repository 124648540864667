import { ExtendedQueryOptions, RequestContext, Service, StopPollingWhenPrototype } from 'src/services/Service';
import {
    GetPricingReportDocument,
    GetPricingReportQuery,
    GetPricingReportQueryVariables,
    PricingReport,
    PricingReportState,
    RequestPricingReportDocument,
    RequestPricingReportMutation,
    RequestPricingReportMutationVariables,
} from 'src/graphql/generated';
import { MutationOptions } from '@apollo/client';
import { Report } from 'src/hooks/useReportPolling';
import { OperationFailedError, UnexpectedError } from 'src/errors';

export interface PricingReportResult extends Report {
    id: string;
    result: {
        reportLink: string;
    };
}
export class ReportService extends Service {
    /**
     * Starts generating pricing report.
     * @param settings
     * @returns pricing report object
     */
    async generatePricingReport(settings: RequestPricingReportMutationVariables): Promise<PricingReport> {
        const request: MutationOptions<RequestPricingReportMutation> = {
            mutation: RequestPricingReportDocument,
            variables: settings,
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'generateReport',
            params: settings,
        };
        const response = await this.mutate<RequestPricingReportMutation, RequestPricingReportMutationVariables>(
            request,
            context,
        );

        const result = response?.requestPricingReport;
        if (!result) {
            throw new UnexpectedError('no data', context);
        }
        if (result.state === PricingReportState.error) {
            throw new OperationFailedError('state', PricingReportState.error);
        }

        return result;
    }

    /**
     * Polls a pricing report. Returns promise that is resolved when report is finished. But 'stopPollingWhen' gets the result of each poll.
     * @param variables required by request
     * @param stopPollingWhen determines when to stop pooling
     * @returns PricingReport when finished
     */
    async pollPricingReport(
        variables: GetPricingReportQueryVariables,
        stopPollingWhen?: StopPollingWhenPrototype,
    ): Promise<PricingReportResult> {
        const getReport = (response: GetPricingReportQuery): PricingReportResult => {
            const result = response?.getPricingReport;
            // make conversion to FE generic types for usePolling
            const report: PricingReportResult = {
                id: result?.id,
                state: result?.state as any,
                progress: 0, // backend doesn't provide yet
                result: {
                    reportLink: (result?.result?.reportLink as any) ?? null,
                },
            };
            return report;
        };
        const pollInterval = 500;
        const request: ExtendedQueryOptions<GetPricingReportQuery, {}> = {
            query: GetPricingReportDocument,
            variables,
            pollInterval,
            stopPollingWhen: (response) => stopPollingWhen(getReport(response)),
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'poolPricingReport',
            params: variables,
        };
        const result = await this.watchQuery(request, context);

        return getReport(result);
    }
}
