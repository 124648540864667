import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'src/ui/title/PageTitle';
import MarketplaceCard from 'src/components/card/MarketplaceCard';
import { EnumCompanyOnboardingState, MarketplaceRegion, Platform } from 'src/graphql/generated';
import NotificationPanel from 'src/components/NotificationPanel/NotificationPanel';
import Loader from 'src/ui/loader/Loader';
import { MarketplaceSelectorBloc } from './MarketplaceSelectorBloc';
import { paths } from 'src/routing/paths';
import FooterNavigation from '../../screens/Onboarding/FooterNavigationNew';
import ConnectMarketplaceSelector from '../MarketplaceHero/ConnectMarketplaceSelector';

export interface MarketplaceSelectorProps {
    bloc: MarketplaceSelectorBloc;
}
export interface MarketplaceSelectorLoadingStateData {
    state: 'loading';
}
export interface MarketplaceSelectorIncorrectStateData {
    state: 'incorrect';
    error: string;
}
export interface MarketplaceSelectorLoadedStateData {
    state: 'loaded';
    platforms: Platform[];
    dataSource?: string;
}
export type MarketplaceSelectorStateData =
    | MarketplaceSelectorLoadingStateData
    | MarketplaceSelectorIncorrectStateData
    | MarketplaceSelectorLoadedStateData;

const MarketplaceSelector: FunctionComponent<MarketplaceSelectorProps> = ({ bloc }) => {
    const { t } = useTranslation();

    const [stateData, setStateData] = useState<MarketplaceSelectorStateData>({ state: 'loading' });

    useEffect(() => {
        const subscription = bloc.subscribe(setStateData);
        bloc.start();
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const Content: FunctionComponent = () => {
        const { state, ...rest } = stateData;
        switch (state) {
            case 'loading':
                return <Loader centered />;
            case 'incorrect':
                const { error } = rest as MarketplaceSelectorIncorrectStateData;
                return <NotificationPanel type="danger" message={error} />;
            case 'loaded':
                const { platforms, dataSource } = rest as MarketplaceSelectorLoadedStateData;
                return (
                    <>
                        {platforms.map((platform) => (
                            <MarketplaceCard key={platform} platform={platform}>
                                <ConnectMarketplaceSelector
                                    platform={platform}
                                    dataSource={dataSource}
                                    ignore={[MarketplaceRegion.emag_pl]}
                                />
                            </MarketplaceCard>
                        ))}
                    </>
                );
        }
    };

    return (
        <div className="onboarding-container marketplace-selector">
            <PageTitle
                heading={t('onboarding.stepStatus', { step: 2 })}
                title={t('marketplaceSelector.title')}
                description={t('marketplaceSelector.description')}
            />
            <Content />
            <FooterNavigation
                connectLaterPath={paths.eve.dashboard}
                onboardingStateToSet={EnumCompanyOnboardingState.DONE}
            />
        </div>
    );
};

export default MarketplaceSelector;
