/**
 * Check given list contains given item.
 * @param list
 * @param item
 * @return true if list contains item, false otherwise.
 */
export function inList<T>(item: T, list: T[] = []): boolean {
    if (!item || !list) return false;
    return list.includes(item);
}

export function filterList<T>(list: T[], ignore: T[] = []): T[] {
    if (!list) return [];
    return list.filter((o) => !ignore.includes(o));
}
