import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import * as querystring from 'query-string';
import { locale } from 'moment';
import { ApolloClient } from '@apollo/client';
import { setDefaultLocale } from 'react-datepicker';
import {
    GetUserDocument,
    SaveLanguageDocument,
    SaveLanguageMutation,
    SaveLanguageMutationVariables,
} from '../graphql/generated';
import { isI18nDebugAllowed } from 'src/utils/DebugUtils';
import { cs, enGB } from 'date-fns/locale';

import 'moment/locale/cs';
import 'moment/locale/en-gb';

declare const GQL_CLIENT: ApolloClient<any>;

const LANGUAGES = ['cs', 'en'];
const DEFAULT_LANG = 'en';

export class LanguageService {
    private storeLanguage: boolean;

    constructor(storeLanguage: boolean) {
        this.storeLanguage = storeLanguage;
    }

    getAvailableLanguages(): string[] {
        return LANGUAGES as any as string[];
    }
    getLanguage(): string {
        let storedLanguage = this.getLanguageFromLocalStorage();
        let language = this.getLanguageFromQuery() || storedLanguage || this.getLanguageFromBrowser() || DEFAULT_LANG;
        if (language !== storedLanguage) {
            this.setLanguage(language);
        }
        return language;
    }
    getLanguageFromBrowser() {
        return this.normalizeLanguage(navigator.language || (navigator as any).userLanguage);
    }

    getLanguageFromLocalStorage() {
        return this.normalizeLanguage(localStorage.getItem('language'));
    }
    getLanguageFromQuery() {
        let { lang } = querystring.parse(window.location.href);
        return this.normalizeLanguage(lang as any);
    }
    normalizeLanguage(language: string): string | null {
        if (!language) return null;
        if (language.length > 2) {
            language = language.substr(0, 2);
        }
        if (LANGUAGES.includes(language)) return language;
        return null;
    }

    setMomentLocale(language: string) {
        locale(language);
    }

    async changeLanguage(language: string) {
        await i18next.changeLanguage(language);
        this.setMomentLocale(language);
        this.setLanguage(language);
        setDefaultLocale(
            {
                cs: cs,
                en: enGB,
            }[language],
        );
    }

    async setLanguage(language: string) {
        localStorage.setItem('language', language);
    }

    async storeLanguageToBackendOnUserChanges() {
        GQL_CLIENT.watchQuery({
            query: GetUserDocument,
        }).subscribe(async (result) => {
            if (result.data && result.data.user) {
                let language = this.getLanguage();
                if (!LANGUAGES.includes(language)) {
                    language = DEFAULT_LANG;
                }
                await GQL_CLIENT.mutate<SaveLanguageMutation, SaveLanguageMutationVariables>({
                    mutation: SaveLanguageDocument,
                    variables: { language: language as any },
                });
            }
        });
    }

    async initI18n() {
        let backend: Backend['options'] = {
            loadPath: '/locales/{{lng}}.json',
        };

        let language = this.getLanguage();
        this.setMomentLocale(language);
        const t = await i18next
            .use(Backend)
            .use(initReactI18next)
            .init({
                fallbackLng: DEFAULT_LANG,
                debug: isI18nDebugAllowed(),
                nsSeparator: false,
                lng: language,
                initImmediate: false,
                backend,
                react: {
                    bindI18n: 'languageChanged',
                    useSuspense: false,
                },
            });
        if (this.storeLanguage) await this.storeLanguageToBackendOnUserChanges();
        return t;
    }
}
