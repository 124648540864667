import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikConfig, useFormikContext } from 'formik';
import NotificationPanel from 'src/components/NotificationPanel/NotificationPanel';
import { Loader, Card, CardEditableContent } from 'src/ui';
import { WizardStepStateData } from 'src/library/bloc';
import ConnectMarketplaceFooter from './ConnectMarketplaceFooter';
import { ConnectMarketplaceChildrenProps } from './ConnectMarketplaceWizard';
import { useBloc } from 'src/hooks';
import { paths } from 'src/routing/paths';
import { ConnectMarketplaceState } from './ConnectMarketplaceBloc';

import './ConnectMarketplace.scss';

export interface ConnectMarketplaceStateData<S, T> extends WizardStepStateData<S, T> {
    allowDelete?: boolean;
    onSave?: (data: T) => void;
    onDelete?: () => void;
    onOpen?: () => void;
    [key: string]: any;
}

export interface ConnectFormProps {
    i18nPrefix: string;
    isSubmitting: boolean;
    success?: string;
}

export interface ConnectMarketplaceProps extends ConnectMarketplaceChildrenProps {
    form: FunctionComponent<ConnectFormProps>;
    validationSchema?: any;
    header?: FunctionComponent<ConnectMarketplaceChildrenProps>;
}

const ConnectMarketplace: FunctionComponent<ConnectMarketplaceProps> = (props) => {
    const { bloc, form: Form, header: Header, validationSchema, region, platform, i18nPrefix } = props;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const stateData = useBloc<ConnectMarketplaceStateData<ConnectMarketplaceState, any>>(bloc);
    if (!stateData) return null;
    const { state, error, success, data, allowDelete, onOpen, onSave, onDelete, ...rest } = stateData;

    const isLoading = state === 'loading';
    const isSubmitting = state === 'savingCredentials' || state === 'deletingCredentials';
    const isCompleted = state === 'credentialsSaved' || state === 'credentialsDeleted' || state === 'completed';
    const isLocked = state === 'locked';
    const isOpen = !isLocked && state !== 'completed';

    const formikProps: FormikConfig<any> = {
        enableReinitialize: true,
        initialValues: data,
        validationSchema,
        onSubmit: onSave,
    };

    const Content: FunctionComponent = () => {
        return (
            <CardEditableContent
                contentTitle={t(`${i18nPrefix}.title`)}
                badge={isCompleted ? t('connectMarketplace.badge') : null}
                showSeparator={!!Header}
                isOpen={isOpen}
                onOpen={onOpen}
            >
                {i18n.exists(t(`${i18nPrefix}.p`)) && <p>{t(`${i18nPrefix}.p`)}</p>}
                {error && <NotificationPanel type="danger" message={error} />}
                {success && <NotificationPanel type="success" message={success} />}

                {!isLocked && <Form isSubmitting={isSubmitting} i18nPrefix={i18nPrefix} success={success} {...rest} />}
            </CardEditableContent>
        );
    };

    const Footer: FunctionComponent = () => {
        if (!isOpen) return null;
        const { submitForm } = useFormikContext();

        return (
            <ConnectMarketplaceFooter
                state={state}
                region={region}
                showDelete={allowDelete}
                disabled={isSubmitting}
                i18nPrefix={i18nPrefix}
                onSubmit={submitForm}
                onDelete={onDelete}
                onContinue={() => navigate(paths.eve.marketplaces)}
            />
        );
    };

    return (
        <Card className={isLocked ? 'locked' : ''}>
            {Header && <Header i18nPrefix={i18nPrefix} platform={platform} />}
            {isLoading && <Loader fullpage />}
            {isLocked && <Content />}
            {!isLocked && (
                <Formik {...formikProps}>
                    <>
                        <Content />
                        <Footer /> {/* Must be inside Formik otherwise useFormiContext cannot bet used */}
                        {/* <FormDebug /> */}
                    </>
                </Formik>
            )}
        </Card>
    );
};

export default ConnectMarketplace;
