import { parse, stringify } from 'query-string';

type SimpleType = string | number;
type RouteFunction = () => SimpleType;

export type RouteParams = { [param: string]: SimpleType | RouteFunction };

export type parseUrl = { layouts?: string; component?: string; id?: number; section?: string };

export class RouteUtils {
    static format(str: string, params: RouteParams, queryParams?: any): string {
        const path = str.replace(/:\w+/g, (found): string => {
            if (!{}.hasOwnProperty.call(params, found)) {
                return found;
            }

            if (typeof params[found] === 'function') {
                return (params[found] as RouteFunction)().toString();
            }

            return params[found].toString();
        });
        let query = stringify(queryParams || {});
        if (query.length > 0) query = '?' + query;

        return path + query;
    }

    static getPath() {
        return window.location.pathname;
    }
    static getPathSegments(): string[] {
        const path = RouteUtils.getPath();
        const segments = path.split('/');
        segments.shift(); // path always starts with / so remove first empty segment
        return segments;
    }

    static parseQuery<T = any>(): T {
        return parse(window.location.search) as any as T;
    }

    static openUrl(url: string) {
        window.open(url, '_target');
    }

    static handleOpenUrl(url: string): () => void {
        return () => window.open(url, '_target');
    }

    static handleRedirectToUrl(url: string): () => void {
        return () => (window.location.href = url);
    }
}
