import { FunctionComponent, useEffect } from 'react';
import { HasChildren } from 'src/common/props';
import { useTranslation } from 'src/hooks/useTranslation';
import { RouteUtils } from 'src/utils/RouteUtils';

export interface PageProps extends HasChildren {}

const Page: FunctionComponent<PageProps> = ({ children }) => {
    const { t, exists } = useTranslation({ i18nPrefix: 'pageTitles' });

    useEffect(() => {
        let segments = RouteUtils.getPathSegments();
        // search for most specific title moving up in the hierarchy
        let title = null;
        while (!title) {
            if (!segments.length) title = t('/');

            const key = '/' + segments.join('/');
            if (exists(key)) title = t(key);
            segments = segments.slice(0, segments.length - 1);
            // console.log({ title, key });
        }
        document.title = title;
    }, [RouteUtils.getPath()]);

    return children;
};

export default Page;
