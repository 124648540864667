import { FunctionComponent, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense } from './Suspense';
import { paths } from './paths';
import { Platform } from 'src/graphql/generated';
import { useTranslation } from 'src/hooks/useTranslation';

import MarketplaceSelector from 'src/components/MarketplaceSelector/MarketplaceSelector';
import { MarketplaceSelectorBloc } from 'src/components/MarketplaceSelector/MarketplaceSelectorBloc';

import { marketplacesRoutes } from './ConnectMarketplacesRoutes';
import { GetUserResult } from 'src/services/user/UserService';

const FeedLoading = lazy(() => import('../screens/Onboarding/FeedLoading' /* webpackChunkName: "FeedLoading" */));
const FeedError = lazy(() => import('../screens/Onboarding/FeedError' /* webpackChunkName: "FeedError" */));
const DataSourceSelection = lazy(
    () =>
        import(
            '../screens/Onboarding/dataSource/DataSourceSelectionOnboarding' /* webpackChunkName: "DataSourceSelection" */
        ),
);
const OnboardingWizardShoptet = lazy(
    () =>
        import(
            '../screens/Onboarding/dataSource/shoptet/OnboardingWizardShoptet' /* webpackChunkName: "OnboardingWizardShoptet" */
        ),
);
const OnboardingWizardXML = lazy(
    () =>
        import(
            '../screens/Onboarding/dataSource/xml/OnboardingWizardXML' /* webpackChunkName: "OnboardingWizardXML" */
        ),
);
const OnboardingWizardCSV = lazy(
    () =>
        import(
            '../screens/Onboarding/dataSource/csv/OnboardingWizardCSV' /* webpackChunkName: "OnboardingWizardCSV" */
        ),
);

export const OnboardingRoutes: FunctionComponent<{ user: GetUserResult }> = (userSettings) => {
    const { t } = useTranslation();

    return (
        // <Route key={'onboarding'} path={'onboarding'} element={<ProtectedRoute />} >
        <Routes>
            <Route path="feed-loading" element={<Suspense element={FeedLoading} />} />
            <Route path="feed-error" element={<Suspense element={FeedError} />} />

            <Route path="connect-data-source">
                <Route path="shoptet" element={<Suspense element={OnboardingWizardShoptet} />} />
                <Route path="xml" element={<Suspense element={OnboardingWizardXML} />} />
                <Route path="csv" element={<Suspense element={OnboardingWizardCSV} />} />
                <Route index element={<Suspense element={DataSourceSelection} />} />
            </Route>

            <Route path="connect-marketplaces">
                {marketplacesRoutes(true, t)}
                <Route
                    index
                    element={
                        <MarketplaceSelector bloc={new MarketplaceSelectorBloc([Platform.shoptet, Platform.real], t)} />
                    }
                />
                <Route path="*" element={<Navigate to={paths.onboarding.connectMarketplaces} replace />} />
            </Route>

            <Route element={<Navigate to={paths.onboarding.connectDataSource} replace />} />
        </Routes>
    );
};
