import { FunctionComponent, useEffect } from 'react';

// TODO MSL refactor FTW nested styles a scss empty
import './Timeout.scss';

export interface TimeoutProps {
    seconds: number;
    height?: number;
    callback?: () => void;
}
// TODO MSL move to UI
export const Timeout: FunctionComponent<TimeoutProps> = ({ seconds, height, callback }) => {
    useEffect(() => {
        if (callback) {
            setTimeout(() => callback(), seconds * 1000);
        }
    }, []);
    return (
        <div
            style={{
                display: 'flex',
                overflow: 'hidden',
                borderRadius: '0.2rem',
                height: height,
                animation: `timeoutFrames ${seconds}s ease-in-out forwards`,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    backgroundColor: '#f36f21',
                    transition: 'width 0.6s ease',
                }}
            />
        </div>
    );
};

Timeout.defaultProps = {
    height: 5,
};
