import { useEffect, useState } from 'react';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';
import { StateData } from 'src/library/bloc/StateData';

/**
 * Boilerplate for binding UI with its BLOC.
 * @param bloc that will UI subscribe to
 */
export function useBloc<T extends StateData<string, any>>(bloc: IAbstractBloc): T {
    const [stateData, setStateData] = useState<any>(bloc.getInitialState());

    useEffect(() => {
        let subscription = null;
        if (bloc) {
            subscription = bloc.subscribe(setStateData);
            bloc.start();
        }
        return () => subscription?.unsubscribe();
    }, []);

    return stateData;
}
