import classNames from 'classnames';
import { FunctionComponent } from 'react';

export interface CardFooterProps {
    className?: string;
    showSeparator?: boolean;
}

const CardFooter: FunctionComponent<CardFooterProps> = ({ className, showSeparator, children }) => {
    const classes = classNames({
        'expando-card-footer': true,
        withSeparator: showSeparator,
        [className]: !!className,
    });
    return (
        <>
            {showSeparator && <hr />}
            <div className={classes}>{children}</div>
        </>
    );
};
CardFooter.defaultProps = {
    className: '',
    showSeparator: false,
};

export default CardFooter;
