import { FieldOption } from 'src/components/form';
import { paths } from 'src/routing/paths';

export const authBasicFields: FieldOption[][] = [
    [
        {
            name: 'email',
            size: 12,
        },
    ],
    [
        {
            name: 'password',
            type: 'password',
            size: 12,
            // hintOnClick: () => HISTORY.push(paths.auth.resetPassword)
            // temporary until figuring out how to pass useNavigate to class components
            // maybe create some decorator that wraps component into function component
            hintOnClick: () => (window.location.href = paths.auth.resetPassword),
        },
    ],
];
