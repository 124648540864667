import { TOptions, useTranslation } from 'src/hooks/useTranslation';

function withTranslation(options) {
    return (Component) => (props) => {
        const i18nProps = useTranslation(options);
        return <Component {...props} {...i18nProps} />;
    };
}

export function Translation(options: TOptions): ClassDecorator {
    return (target) => {
        return withTranslation(options)(target as any) as any;
    };
}
