import { FunctionComponent, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext, IUserContext } from '../Contexts/User/UserContext';
import { paths } from './paths';
import { EnumCompanyOnboardingState } from 'src/graphql/generated';
import { HasChildren } from 'src/common/props';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

export interface ProtectedRouteProps extends HasChildren {
    requireSuperadmin?: boolean;
    requireOnboarding?: boolean;
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = (props) => {
    const { children, requireOnboarding, requireSuperadmin } = props;
    const { user } = useContext<IUserContext>(UserContext);
    const location = useLocation();
    const { t } = useTranslation();

    if (!user) {
        return <Navigate to={paths.auth.login} state={{ from: location.pathname }} />;
    }
    if (requireSuperadmin && !user.isSuperadmin) {
        return <Navigate to={paths.notAuthorized} />;
    }
    if (!user.isSuperadmin && (!user.tos || user.tos.version !== t('termsOfService.version'))) {
        return <Navigate to={paths.tos} />;
    }
    if (requireOnboarding && user.company) {
        const { onboardingState } = user.company;
        if (onboardingState === EnumCompanyOnboardingState.SOURCE_SELECTION)
            return <Navigate to={paths.onboarding.connectDataSource} />;
        if (onboardingState === EnumCompanyOnboardingState.MARKETPLACES_CONNECTION)
            return <Navigate to={paths.onboarding.connectMarketplaces} />;
    }

    return children ? children : <Outlet />;
};

ProtectedRoute.defaultProps = {
    requireSuperadmin: false,
};
