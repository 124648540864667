export class BlocLogger {
    private source: string;
    private isActive: boolean;

    constructor(source: string, isActive: boolean) {
        this.source = source;
        this.isActive = isActive;
    }

    logInit(...messages: any[]) {
        if (!this.isActive) return;
        console.log(this.identificator, ...messages);
    }

    logRequest(...messages: any[]) {
        if (!this.isActive) return;
        console.group(this.identificator);
        console.log(...messages);
    }

    logState(...messages: any[]) {
        if (!this.isActive) return;
        console.log(...messages);
        console.groupEnd();
    }

    get identificator() {
        return `[DEBUG] [${this.source}]`;
    }
}
