import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { FormData } from './ConnectGlamiForm';

export class ConnectGlamiStepBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectGlamiStepBloc';
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            clientId: data?.clientId ?? '',
            secretKey: data?.secretKey ?? '',
        };
    }
}
