import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routing/paths';

import './ExpandoLogo.scss';

interface ExpandoLogoProps {
    color?: 'white' | 'black';
}

const ExpandoLogo: FunctionComponent<ExpandoLogoProps> = ({ color }) => {
    const navigate = useNavigate();
    // remove Logo when new media query styles for navigation will be implemented
    return <span className={`expando-logo Logo ${color}`} onClick={() => navigate(paths.root)} />;
};
ExpandoLogo.defaultProps = {
    color: 'white',
};

export default ExpandoLogo;
