import { ILoggerStream, LogGlobalContext, LogLocalContext } from './AppLogger';

export abstract class LoggerStream<T> implements ILoggerStream {
    protected context: Partial<LogGlobalContext> = {};

    constructor(protected name: string) {}

    info(value: string | T, context?: LogLocalContext): void {}
    warn(value: string | T, context?: LogLocalContext): void {}
    error(value: T | Error, context?: LogLocalContext): void {}
    trace(value: T | Error, context?: LogLocalContext): void {}
    track(value: T, context?: LogLocalContext): void {}

    initialize(): Promise<void> {
        console.log(`${this.name} initialized`); // this.constructor.name doesn't work in production build
        return Promise.resolve();
    }

    setContext(context: LogGlobalContext): void {
        this.context = context;
    }
}
