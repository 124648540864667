import { TFunction } from 'i18next';
import { TFunction as TFunction2 } from 'src/hooks/useTranslation';
import { Subject, Subscription } from 'rxjs';
import { OperationFailedError, SpecificError, UnavailableServiceError } from '../../errors';
import { isBlocDebugAllowed } from '../../utils/DebugUtils';
import { BlocLogger } from './BlocLogger';
import { State, StateData } from './StateData';

export interface IAbstractBloc {
    start(): void;
    subscribe(set: (data: any) => void): Subscription;
    getInitialState(): any;
}

export interface AbstractBlocActionData<A, D> {
    action: A;
    isLoading: boolean;
    data?: D;
    error?: Error;
}

/**
 * Note: Second iteration of BLOC classes
 *       - Subject not BehaviourSubject
 *       - payload instead of params
 */
export abstract class AbstractBloc2<A, SD extends StateData<string, any>> extends Subject<SD> implements IAbstractBloc {
    protected logger: BlocLogger = null;

    /**
     * Defines 'bloc' name. Used in debugger to distinguish among bloc events.
     */
    public abstract getName(): string;
    /**
     * Starts producing events.
     * Note: Sometimes when request is cached, event with data is fired before UI subscribes in time.
     *       Same goes for bloc constructor properties are sometimes not available at the time when produceEvent is handled
     */
    public abstract start(): void;

    public getInitialState(): SD {
        return this.initialState;
    }

    protected initialState: SD;
    protected t: TFunction | TFunction2;

    constructor(initialState: SD, t: TFunction | TFunction2) {
        super();
        this.initialState = initialState;
        this.t = t;
        this.logger = new BlocLogger(this.getName(), isBlocDebugAllowed());
        this.logger.logInit('Initialized');
    }

    protected async makeRequest<D>(action: A, request: () => Promise<D>) {
        try {
            this.logger?.logRequest('producing data', { action, isLoading: true });
            this.produceStateData({ action, isLoading: true });
            const data = await request();
            this.logger?.logRequest('producing data', { action, isLoading: false, data });
            this.produceStateData({ action, isLoading: false, data });
        } catch (error) {
            this.logger?.logRequest('producing data', { action, isLoading: false, error });
            this.produceStateData({ action, isLoading: false, error });
        }
    }

    protected produceStateData(props: AbstractBlocActionData<A, any>): void {
        this.next({
            state: this.getState(props),
            data: this.getData(props.data),
            error: this.getError(props.error),
            ...this.produceExtraStateData(props),
        } as SD);
    }

    protected produceExtraStateData(props: AbstractBlocActionData<A, any>): Record<string, any> {
        return {};
    }

    /**
     * Shortcut for producing error state
     * @param action
     * @param error
     */
    protected produceError(action: A, error: Error): void {
        this.produceStateData({ action, isLoading: false, error });
    }

    /**
     * Shortcut for producing data state
     * @param action
     * @param data
     */
    protected produceData(action: A, data: any): void {
        this.produceStateData({ action, isLoading: false, data });
    }

    protected getState(data: AbstractBlocActionData<A, any>): string {
        if (data.error) return 'incorrect' as State;
        if (data.isLoading) return 'requesting' as State;
        return 'requested' as State;
    }

    protected getError(error: Error): string {
        if (error instanceof UnavailableServiceError) {
            return this.t('error.serviceNotAvailable');
        }
        if (error instanceof OperationFailedError) {
            return this.t('error.operationFailed');
        }
        if (error instanceof SpecificError) {
            return this.t(`error.${error.code}`, error.meta);
        }
        return error?.message;
    }

    // just to force error when upgrading from AbstractBloc to AbstractBloc2
    // remove after removing AbstractBloc
    protected produceEvent(a, b, c, d, e, f, g): void {}

    // just to force error when upgrading from AbstractBloc to AbstractBloc2
    // remove after removing AbstractBloc
    protected getErrorMessage(a, b, c, d): string {
        return '';
    }

    protected getData(data: any): any {
        return data;
    }

    /**
     * Wrapper over 'observable.next' just to add some debug logging.
     * @param value
     */
    next(value: SD): void {
        this.logger?.logState('producing state', value);
        super.next(value);
    }
}
