import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CardContent, { CardContentProps } from './CardContent';
import Button from '../button/ExpandoButton';

export interface CardEditableContentProps extends CardContentProps {
    contentTitle: string;
    badge?: string;
    onOpen?: () => void;
}

const CardEditableContent: FunctionComponent<CardEditableContentProps> = (props) => {
    const { contentTitle, badge, onOpen, children, isOpen, showSeparator, ...rest } = props;
    const { t } = useTranslation();

    return (
        <CardContent className="editable" showSeparator={showSeparator}>
            <div className="editable-header">
                <h3>
                    {contentTitle}
                    {!isOpen && badge && <small className="badge badge-success">{badge}</small>}
                </h3>
                <Button text={t('btn.expand')} type="button" color="secondary" hidden={isOpen} onClick={onOpen} />
            </div>
            <CardContent showSeparator={!isOpen} {...rest} className={`editable-content`} isOpen={isOpen}>
                {children}
            </CardContent>
        </CardContent>
    );
};
CardEditableContent.defaultProps = {
    collapsable: true,
    onOpen: () => {},
};

export default CardEditableContent;
