import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectFormProps } from '../ConnectMarketplaceNew';
import { Button } from 'src/ui';
import Form from 'src/components/form/Form';
import { useFormikContext } from 'formik';
import { RouteUtils } from 'src/utils/RouteUtils';
import { ShowIf } from 'src/library/show-if';

export interface FormData {
    authUrl: string;
    registrationUrl: string;
    marketplace: string;
}

const ConnectAmazonForm: FunctionComponent<ConnectFormProps> = (props) => {
    const { i18nPrefix, success } = props;
    const { t } = useTranslation('translation', { keyPrefix: i18nPrefix });

    const LoginButton: FunctionComponent = () => {
        const { getFieldMeta } = useFormikContext();
        const authUrl = getFieldMeta<string>('authUrl')?.value || '';

        return <Button color="tertiary" text={t('btn.login')} onClick={RouteUtils.handleRedirectToUrl(authUrl)} />;
    };
    const RegisterButton: FunctionComponent = () => {
        const { getFieldMeta } = useFormikContext();
        const registrationUrl = getFieldMeta<string>('registrationUrl')?.value || '';

        return <Button color="tertiary" text={t('btn.register')} onClick={RouteUtils.handleOpenUrl(registrationUrl)} />;
    };

    const ThisCard: FunctionComponent<{ step: number }> = ({ step, children }) => {
        return (
            <div className={`nested-card step${step}`}>
                <header>
                    <h4>{t('step', { step })}</h4>
                    <p>{t(`li${step}`)}</p>
                </header>
                {children}
            </div>
        );
    };

    return (
        <Form className="connect-amazon-expando-card">
            <ThisCard step={1}>
                <ShowIf condition={!success}>
                    <LoginButton />
                    <RegisterButton />
                </ShowIf>
            </ThisCard>
            <ThisCard step={2} />
            <ThisCard step={3} />
        </Form>
    );
};

export default ConnectAmazonForm;
