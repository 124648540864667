import { FunctionComponent } from 'react';
import { ErrorMessage } from 'formik';

import exclamationCircleIcon from '../../assets/img/icons/exclamationCircle.svg';

export interface FormErrorProps {
    name: string;
    isInvalid: boolean;
}

const FormError: FunctionComponent<FormErrorProps> = ({ name, isInvalid }) => {
    if (!isInvalid) {
        return null;
    }
    return (
        <div className="expando-form-error">
            <img src={exclamationCircleIcon} />
            <ErrorMessage name={name} />
        </div>
    );
};

export default FormError;
