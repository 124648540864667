import { FunctionComponent } from 'react';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ValidationSchemas } from 'src/components/form/ValidationSchemas';
import { ConnectFormProps } from '../ConnectMarketplaceNew';
import ConnectMarketplaceForm, { FieldOption } from '../ConnectMarketplaceForm';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export interface FormData {
    sellerId: string;
}

export const createValidationSchema = (t: TFunction) =>
    yup.object().shape({
        sellerId: ValidationSchemas.integer(t),
    });

const fields: FieldOption[][] = [[{ name: 'sellerId' }]];

const ConnectCDiscountForm: FunctionComponent<ConnectFormProps> = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: props.i18nPrefix });

    return (
        <>
            <ol>
                <li>{t('li1')}</li>
                <li>{t('li2')}</li>
                <li>{t('li3')}</li>
            </ol>
            <ConnectMarketplaceForm {...props} fields={fields} />
            <Row>
                <Col>{t('p2')}</Col>
            </Row>
        </>
    );
};

export default ConnectCDiscountForm;
