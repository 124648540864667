import * as yup from 'yup';
import { AbstractForm, FieldOption, ValidationSchemas } from 'src/components/form';
import { Translation } from 'src/common/decorators/Translation';

export interface FormData {
    url: string;
}

@Translation({
    i18nPrefix: 'auth.loginShoptet',
    lookup: true,
})
export class ShoptetLoginForm extends AbstractForm<FormData> {
    getFields(): FieldOption[][] {
        return [[{ name: 'url', size: 12 }]];
    }

    getValidationSchema(): { [key in keyof FormData]: yup.BaseSchema<any, any, any> } {
        return {
            url: ValidationSchemas.url(this.t),
        };
    }
}
