import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import PlatformLogo from 'src/components/logo/PlatformLogo';
import CardHeader from 'src/ui/card/CardHeader';
import { ConnectMarketplaceChildrenProps } from './ConnectMarketplaceWizard';

const ConnectMarketplaceHeader: FunctionComponent<ConnectMarketplaceChildrenProps> = ({ platform, i18nPrefix }) => {
    const { t } = useTranslation();

    // i18nPrefix contains step suffix, get rit of it for header
    const descriptionPrefix = i18nPrefix.split('.')[0];

    return (
        <CardHeader
            image={<PlatformLogo type={platform} />}
            title={t(`platform.${platform}`)}
            description={t(`${descriptionPrefix}.headerDescription`)}
        />
    );
};

export default ConnectMarketplaceHeader;
