import * as yup from 'yup';
import { Trans } from 'react-i18next';
import { Translation } from 'src/common/decorators/Translation';
import { AbstractForm, AbstractSimpleRenderProps, FieldOption, ValidationSchemas } from 'src/components/form';
import { authBasicFields } from '../Form/basic-fields';

export interface FormData {
    email: string;
    password: string;
    phone?: string;
    shopUrl?: string;
}

@Translation({
    i18nPrefix: 'auth.registration',
    lookup: true,
})
export class RegistrationForm extends AbstractForm<FormData> {
    getFields(): FieldOption[][] {
        return [
            ...authBasicFields,
            [
                {
                    name: 'phone',
                    size: 12,
                    optional: true,
                },
            ],
            [
                {
                    name: 'shopUrl',
                    size: 12,
                    optional: true,
                },
            ],
        ];
    }

    getValidationSchema(): { [key in keyof FormData]: yup.BaseSchema<any, any, any> } {
        return {
            email: ValidationSchemas.email(this.t),
            password: ValidationSchemas.password(this.t),
            phone: yup.string().optional(),
            shopUrl: yup.string().optional(),
        };
    }

    renderContent(props: AbstractSimpleRenderProps) {
        const { Form, SubmitButton, t } = props;
        return (
            <>
                <Form />
                <p>
                    <Trans
                        i18nKey={'auth.registration.disclaimer'}
                        components={[
                            <a href={t('auth.registration.termConditionsLink')} target="_blank">
                                univers
                            </a>,
                            <a href={t('auth.registration.privacyPolicyLink')} target="_blank">
                                univers
                            </a>,
                        ]}
                    ></Trans>
                </p>
                <SubmitButton />
            </>
        );
    }
}
