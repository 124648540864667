import ms from 'ms';
import { LoggerStream } from './LoggerStream';
import { LogLocalContext } from './AppLogger';

type CoralogixBody = {
    /**
     * UUID
     */
    privateKey: string;
    /**
     * usually used to separate environments
     */
    applicationName: string;
    /**
     * usually used to separate components
     */
    subsystemName: string;
    computerName?: string;
    logEntries: CoralogixLog[];
};

type CoralogixLog = {
    timestamp: number;
    severity: number;
    text: string;
    category?: string;
    className?: string;
    methodName?: string;
    threadId?: string;
};

export type CoralogixQueueOptions = {
    /**
     * Items
     */
    maxQueue: number;
    /**
     * Seconds
     */
    maxTime: number;
};

export type CoralogixOptions = {
    privateKey: string;
    applicationName: string;
    subsystemName: string;
    url: string;
    computerName?: string;
    debug?: boolean;
    queue?: CoralogixQueueOptions;
};

export class CoralogixLogger extends LoggerStream<CoralogixLog> {
    private options: CoralogixOptions;
    private defaultOptions = {
        debug: false,
        queue: {
            maxQueue: 5,
            maxTime: ms('5s'),
        },
    } as CoralogixOptions;

    private severities = {
        debug: 1,
        verbose: 2,
        info: 3,
        warn: 4,
        error: 5,
        critical: 6,
    };

    private updatingQueue = false;
    private queue: any[] = [];

    constructor(customOptions: CoralogixOptions) {
        super('CoralogixLogger');
        this.options = {
            ...this.defaultOptions,
            ...customOptions,
        };
    }

    initialize(): Promise<void> {
        this.startUpdater();
        return super.initialize();
    }

    warn(error: string | CoralogixLog, context: LogLocalContext = {}) {
        this.log(this.severities.warn, error, context);
    }

    error(error: CoralogixLog | Error, context: LogLocalContext = {}): void {
        this.log(this.severities.error, error, context);
    }

    log(level: number, message: string | CoralogixLog | Error, context: LogLocalContext): void {
        let logMessage = null;
        let logError = null;
        if (typeof message === 'string') {
            logMessage = message;
        } else if (typeof message === 'object') {
            logError = message;
            if ('message' in message) {
                logMessage = message.message;
            }
        }

        const item: CoralogixLog = {
            timestamp: Date.now(),
            severity: level,
            text: this.serialize({
                companyId: this.context?.companyId,
                user: this.context?.user,
                message: logMessage,
                error: logError,
                // not sure whether tag or category (in code saw 'tag', in logs saw 'category' => adding both )
                tag: context.category,
                ...context,
            }),
            category: context?.category,
        };
        this.queue.push(item);
        this.shouldUpdate();
    }

    shouldUpdate() {
        if (this.itemCount >= this.options.queue.maxQueue) {
            this.debuglog(`have enought items ${this.itemCount} to update ... updating`);
            this.updateQueue();
        } else {
            this.debuglog('not updating yet, has only: ' + this.itemCount);
        }
    }

    startUpdater() {
        setInterval(() => this.updateQueue(), this.options.queue.maxTime);
    }

    updateQueue() {
        if (this.updatingQueue) {
            return false;
        }
        if (!this.itemCount) {
            return false;
        }

        let tmpitems = this.queue.slice();
        this.updatingQueue = true;
        this.queue = []; // clear the queue;
        this.update(tmpitems);
        this.updatingQueue = false;
    }

    update(logEntries: CoralogixLog[]) {
        let xhr = new XMLHttpRequest();

        const data: CoralogixBody = {
            privateKey: this.options.privateKey,
            applicationName: this.options.applicationName,
            subsystemName: this.options.subsystemName,
            computerName: this.options.computerName,
            logEntries,
        };

        xhr.open('POST', this.options.url);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (xhr.status !== 200) {
                console.log('[coralogix] logger failed: ' + xhr.responseText + ' with error code: ' + xhr.status);
            }
        };
        xhr.send(JSON.stringify(data));
    }

    debuglog(log) {
        if (this.options.debug) {
            console.log('[coralogix] ' + log);
        }
    }

    private get itemCount() {
        return this.queue.length;
    }

    private serialize(obj: Record<string, any>) {
        return JSON.stringify(obj);
    }
}
