import { FunctionComponent } from 'react';

import './StatusPage.scss';

interface StatusPageLoadingOption {
    type: 'loading';
}
export interface StatusPageProps {
    title: string;
    description: string;
    content?: FunctionComponent;
}

const StatusPage: FunctionComponent<StatusPageProps> = ({ title, description, content: Content }) => {
    return (
        <div className="status-page">
            <h1>{title}</h1>
            <p>{description}</p>
            <Content />
        </div>
    );
};
StatusPage.defaultProps = {
    content: () => null,
};

export default StatusPage;
