import { WizardBloc, WizardStepBloc, WizardStepState } from 'src/library/bloc';
import { ConnectMarketplaceStateData } from './ConnectMarketplaceNew';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { MarketplaceRegion } from 'src/graphql/generated';
import { marketplaceToLabel } from 'src/library/format';
import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { MarketplaceError, SpecificError } from 'src/errors';
import { TFunction } from 'i18next';
import { AppLogger } from 'src/logger/AppLogger';
import { ESegmentEvent } from 'src/library/segment';
import { RouteUtils } from 'src/utils/RouteUtils';
import { endOnboarding } from '../OnboardingActions';

declare const SERVICES: ServiceProvider;
declare const LOGGER: AppLogger;

export type ConnectMarketplaceBlocEvent = 'get' | 'set' | 'del';
export type ConnectMarketplaceState =
    | WizardStepState
    | 'hasCredentials'
    | 'missingCredentials'
    | 'savingCredentials'
    | 'credentialsSaved'
    | 'deletingCredentials'
    | 'credentialsDeleted';

export abstract class ConnectMarketplaceBloc<FD> extends WizardStepBloc<
    ConnectMarketplaceBlocEvent,
    ConnectMarketplaceState,
    ConnectMarketplaceStateData<ConnectMarketplaceState, FD>
> {
    protected credentials: MarketplaceCredentials = null;

    protected abstract getFormData(data: MarketplaceCredentials): FD;
    protected region: MarketplaceRegion;

    constructor(
        region: MarketplaceRegion,
        parentBloc: WizardBloc<any>,
        prevStepBloc: WizardStepBloc<any, any, any>,
        t: TFunction,
    ) {
        super(parentBloc, prevStepBloc, t);
        this.region = region;
    }

    protected getRequestData(formData: FD): MarketplaceCredentials {
        return {
            serviceScope: this.region,
            ...this.credentials,
            ...formData,
        };
    }

    public produceEvent(action: ConnectMarketplaceBlocEvent, isLoading: boolean, data: any, error: Error) {
        const state = this.getStepState(action, isLoading, data, error, this.isDone);
        if (state === 'credentialsSaved') {
            this.credentials = data;
            endOnboarding();
        }
        if (state === 'credentialsDeleted') {
            this.credentials = null;
        }

        this.trackMarketplaceConnected(action, state, error);

        this.next({
            state,
            data: this.getFormData(this.credentials),
            error: this.getErrorMessage(error),
            allowDelete: !!this.credentials,
            onSave: this.handleSaveCredentials,
            onDelete: this.handleDeleteCredentials,
            onOpen: this.handleOpen,
        });
    }

    protected getStepState(
        action: ConnectMarketplaceBlocEvent,
        isLoading: boolean,
        data: any,
        error: Error,
        isDone: boolean,
    ): ConnectMarketplaceState {
        if (isDone) return 'completed';

        const determineGetState = (): ConnectMarketplaceState => {
            if (isLoading) return 'loading';
            if (error) return 'incorrect';
            if (data) return 'hasCredentials';
            return 'missingCredentials';
        };
        const determineSetState = (): ConnectMarketplaceState => {
            if (isLoading) return 'savingCredentials';
            if (error || !data) return 'incorrect';
            return 'credentialsSaved';
        };
        const determineDelState = (): ConnectMarketplaceState => {
            if (isLoading) return 'deletingCredentials';
            if (error || !data) return 'incorrect';
            return 'credentialsDeleted';
        };

        switch (action) {
            case 'get':
                return determineGetState();
            case 'set':
                return determineSetState();
            case 'del':
                return determineDelState();
            default:
                return 'locked';
        }
    }

    protected handleOpen = (): void => {
        this.isDone = false;
        this.produceEvent('get', false, this.credentials, null);
    };

    protected handleSaveCredentials = (formData: FD) => {
        this.makeRequest('set', () => SERVICES.marketplace.saveCredentials(this.getRequestData(formData)));
    };

    protected handleDeleteCredentials = () => {
        SERVICES.system.modal.confirmation.showDisconnectMarketplace(
            this.region,
            marketplaceToLabel(this.region as any),
            () =>
                this.makeRequest('del', () =>
                    SERVICES.marketplace.disconnectMarketplace({
                        serviceScope: this.region,
                    }),
                ),
        );
    };

    protected onParentCompleted(data: MarketplaceCredentials): void {
        this.credentials = data;
        this.isDone = !!data;
        this.produceEvent('get', false, data, null);
    }

    protected onPrevStepCompleted(data: any): void {
        this.produceEvent('get', false, {}, null);
    }

    protected getErrorMessage(error: Error): string {
        if (error instanceof MarketplaceError && error.type === 'duplicitBranchId') {
            return this.t('connectMarketplace.error.duplicateBranchID');
        }
        if (error instanceof SpecificError) {
            return this.t('connectMarketplace.error.' + error.code, error.meta);
        }
        return super.getErrorMessage(error);
    }

    private trackMarketplaceConnected(
        action: ConnectMarketplaceBlocEvent,
        state: ConnectMarketplaceState,
        error: Error,
    ) {
        if (state === 'credentialsSaved' || (action === 'set' && state === 'incorrect')) {
            const { source } = RouteUtils.parseQuery<{ source: string }>();

            LOGGER.track({
                event: ESegmentEvent.MARKETPLACE_SELECTED,
                dataSource: source,
                region: this.region,
                error: this.getErrorMessage(error),
            });
        }
    }
}
