import ConnectMarketplace from '../ConnectMarketplaceNew';
import ConnecAlzaForm, { createValidationSchema } from './ConnectAlzaForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectMarketplaceWizard, { ConnectMarketplaceWizardProps } from '../ConnectMarketplaceWizard';
import ConnectMarketplaceHeader from '../ConnectMarketplaceHeader';
import { MarketplaceRegion } from 'src/graphql/generated';
import { ConnectAlzaStepBloc } from './ConnectAlzaStepBloc';
import { ConnectMarketplaceWizardBloc } from '../ConnectMarketplaceWizardBloc';

interface ConnectAlzaOrchestratorProps {
    region: MarketplaceRegion;
    showWizardComponents: boolean;
}

export const ConnectAlzaOrchestrator: FunctionComponent<ConnectAlzaOrchestratorProps> = ({
    region,
    showWizardComponents,
}) => {
    const { t } = useTranslation();
    const parentBloc = new ConnectMarketplaceWizardBloc(1, region, t);
    const step1Bloc = new ConnectAlzaStepBloc(region, parentBloc, null, t);
    return (
        <ConnectAlza
            region={region}
            bloc={parentBloc}
            stepBlocs={[step1Bloc]}
            i18nPrefix="connectAlza"
            showWizardComponents={showWizardComponents}
        />
    );
};

const ConnectAlza: FunctionComponent<ConnectMarketplaceWizardProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ConnectMarketplaceWizard {...props}>
            <ConnectMarketplace
                header={ConnectMarketplaceHeader}
                form={ConnecAlzaForm}
                validationSchema={createValidationSchema(t)}
            />
        </ConnectMarketplaceWizard>
    );
};
export default ConnectAlza;
