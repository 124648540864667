import { FormData } from './ResetPasswordForm';
import { AbstractFormBloc } from 'src/library/bloc/AbstractFormBloc';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { CreatePasswordResetEmailResult } from 'src/graphql/generated';

declare const SERVICES: ServiceProvider;

export class ResetPasswordBloc extends AbstractFormBloc<any, FormData> {
    getName(): string {
        return 'ResetPasswordBloc';
    }

    getFormData(data: any): FormData {
        return {
            email: data?.email || '',
        };
    }

    requestGetData(): Promise<any> {
        return Promise.resolve();
    }

    requestSetData(data: FormData): Promise<CreatePasswordResetEmailResult> {
        return SERVICES.auth.resetPassword(data.email);
    }

    responseSetData() {
        this.next({
            state: 'requested',
            data: this.formData,
            // TODO 't' nemoze byt v bloc inak sa pri zmene jazyka nezmeni
            success: this.t('auth.resetPassword.successNotification'),
            onSubmit: this.handleSubmit,
        });
    }
}
