import { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { SimpleModalBloc } from './SimpleModalBloc';

export interface SimpleModalProps {
    bloc: SimpleModalBloc;
}
export interface SimpleModalStateData {
    title: string;
    element: JSX.Element;
    isOpen: boolean;
    className?: string;
}

const modalDefaults: SimpleModalStateData = {
    isOpen: false,
    title: '',
    element: null,
    className: '',
};

const SimpleModal: FunctionComponent<SimpleModalProps> = ({ bloc }) => {
    const [stateData, setStateData] = useState<SimpleModalStateData>(modalDefaults);
    const { isOpen, title, element, className } = stateData;

    useEffect(() => {
        const modalSubscription = bloc.subscribe(setStateData);
        return () => {
            modalSubscription.unsubscribe();
        };
    }, []);

    return (
        <Modal isOpen={isOpen} className={className}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{element}</ModalBody>
        </Modal>
    );
};

export default SimpleModal;
