import {
    CompanyReturnAddress,
    EnumCompanyOnboardingState,
    GetReturnAddressAsUserDocument,
    GetReturnAddressAsUserQuery,
    GetReturnAddressAsUserQueryVariables,
    MutationupdateReturnAddressArgs,
    UpdateOnboardingStateDocument,
    UpdateOnboardingStateMutation,
    UpdateOnboardingStateMutationVariables,
    UpdateReturnAddressDocument,
    UpdateReturnAddressMutation,
    UpdateReturnAddressMutationVariables,
} from 'src/graphql/generated';
import { Service } from '../Service';

export class CompanyService extends Service {
    async updateOnboardingState(state: EnumCompanyOnboardingState): Promise<void> {
        await this.mutate<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>(
            {
                mutation: UpdateOnboardingStateDocument,
                variables: { state },
            },
            {
                method: 'updateOnboardingState',
                params: { state },
            },
        );
    }

    async updateReturnAddress(address: MutationupdateReturnAddressArgs): Promise<void> {
        await this.mutate<UpdateReturnAddressMutation, UpdateReturnAddressMutationVariables>(
            {
                mutation: UpdateReturnAddressDocument,
                variables: address,
            },
            {
                method: 'updateReturnAddress',
                params: address,
            },
        );
    }

    async getReturnAddress(): Promise<CompanyReturnAddress> {
        const result = await this.query<GetReturnAddressAsUserQuery, GetReturnAddressAsUserQueryVariables>(
            {
                query: GetReturnAddressAsUserDocument,
                fetchPolicy: 'cache-first',
            },
            {
                method: 'company',
            },
        );
        return result?.company?.returnAddress;
    }
}
