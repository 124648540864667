import { MutationOptions } from '@apollo/client';
import { MalformedUrlError, NotFoundError, SpecificError, UnexpectedError } from 'src/errors';
import { RouteUtils } from 'src/utils/RouteUtils';
import {
    LoginAsUserDocument,
    LoginAsUserMutation,
    LoginAsUserMutationVariables,
    StartShoptetLoginDocument,
    StartShoptetLoginMutation,
    StartShoptetLoginMutationVariables,
    FinalizePasswordResetDocument,
    FinalizePasswordResetMutation,
    FinalizePasswordResetMutationVariables,
    ResetPasswordMutationVariables,
    ResetPasswordMutation,
    CreatePasswordResetEmailResult,
    ResetPasswordDocument,
    CompleteShoptetLoginMutation,
    CompleteShoptetLoginMutationVariables,
    CompleteShoptetLoginDocument,
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables,
    ConfirmEmailDocument,
} from '../../graphql/generated';
import { EnvUtils } from '../../utils/EnvUtils';
import { ErrorInterceptor, RequestContext, Service } from '../Service';
import { IAuthService } from './IAuthService';

export class AuthService extends Service implements IAuthService {
    /**
     * @inheritdoc
     */
    async passwordLogin(email: string, password: string, errorInterceptor?: ErrorInterceptor): Promise<void> {
        const url = `${EnvUtils.getApiEndpoint()}/auth/login`;
        const body = {
            email,
            password,
        };
        const context: RequestContext = {
            method: 'passwordLogin',
            params: { url, email },
            errorInterceptor,
        };

        await this.post(url, body, {}, context);
    }

    /**
     * @inheritdoc
     */
    async superLogin(companyId: string, errorInterceptor?: ErrorInterceptor): Promise<void> {
        await this.clearCache();

        const url = `${EnvUtils.getApiEndpoint()}/auth/superlogin`;
        const body = {
            companyId,
            password: '¯\\_(ツ)_/¯', //required otherwise passport module won't authenticate
        };
        const context: RequestContext = {
            method: 'superLogin',
            params: {
                url,
                ...body,
            },
            errorInterceptor,
        };
        await this.post(url, body, {}, context);
    }

    /**
     * @inheritdoc
     */
    async shoptetLogin(shopHostname: string): Promise<string> {
        return this._startShoptetLogin({ shopHostname });
    }

    async startShoptetLogin(): Promise<string> {
        const variables = RouteUtils.parseQuery<StartShoptetLoginMutationVariables>();

        return this._startShoptetLogin(variables);
    }

    async completeShoptetLogin(): Promise<string> {
        const { code, state } = RouteUtils.parseQuery<{
            code: string;
            state: string;
        }>();

        if (!code && !state) {
            throw new MalformedUrlError();
        }

        const request: MutationOptions<CompleteShoptetLoginMutation, CompleteShoptetLoginMutationVariables> = {
            mutation: CompleteShoptetLoginDocument,
            variables: {
                code,
                state,
            },
        };
        const context: RequestContext = {
            method: 'completeShoptetLogin',
            params: request.variables,
        };
        const response = await this.mutate(request, context);
        return response?.completeShoptetLogin?.redirectUrl ?? null;
    }

    /**
     * @inheritdoc
     */
    async loginAsUser(email: string, errorInterceptor?: ErrorInterceptor): Promise<void> {
        await this.clearCache();

        const request: MutationOptions<LoginAsUserMutation, LoginAsUserMutationVariables> = {
            mutation: LoginAsUserDocument,
            variables: {
                email,
            },
        };
        const context: RequestContext = {
            method: 'loginAsUser',
            params: request.variables,
            errorInterceptor,
        };

        await this.mutate(request, context);
    }

    /**
     * @inheritdoc
     */
    async logout(errorInterceptor?: ErrorInterceptor): Promise<void> {
        const url = `${EnvUtils.getApiEndpoint()}/auth/logout`;
        const context: RequestContext = {
            method: 'logout',
            params: { url },
            errorInterceptor,
        };
        await this.post(url, null, { redirect: 'manual' }, context);
        await this.clearCache();
    }

    /**
     * @inheritdoc
     */
    async resetPassword(email: string): Promise<CreatePasswordResetEmailResult> {
        const request: MutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables> = {
            mutation: ResetPasswordDocument,
            variables: { email },
        };
        const context: RequestContext = {
            method: 'sendPasswordResetEmail',
            params: request.variables,
        };

        const result = await this.mutate(request, context);

        if (!result.sendPasswordResetEmail.success) {
            throw new UnexpectedError();
        }

        return result.sendPasswordResetEmail ?? null;
    }

    /**
     * @inheritdoc
     */
    async finalizePasswordReset(password: string, code: string): Promise<void> {
        const request: MutationOptions<FinalizePasswordResetMutation, FinalizePasswordResetMutationVariables> = {
            mutation: FinalizePasswordResetDocument,
            variables: {
                password,
                code,
            },
        };
        const context: RequestContext = {
            method: 'resetPassword',
            params: request.variables,
        };

        const result = await this.mutate(request, context);

        if (!result.resetPassword.success) {
            if (result.resetPassword.codeAlreadyUsed) {
                throw new SpecificError('codeAlreadyUsed');
            }
            if (result.resetPassword.codeExpired) {
                throw new SpecificError('codeExpired');
            }
            if (result.resetPassword.invalidCode) {
                throw new SpecificError('invalidCode');
            }
        }
    }

    async confirmEmail(code: string): Promise<string> {
        const result = await this.mutate<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
            {
                mutation: ConfirmEmailDocument,
                variables: { code },
            },
            {
                method: 'confirmEmail',
                params: { code },
            },
        );

        if (!result?.completeRegistration) {
            throw new SpecificError('verificationDoesntExist');
        }
        if (result?.completeRegistration?.hasExpired) {
            throw new SpecificError('verificationExpired');
        }
        if (result?.completeRegistration?.verified) {
            throw new SpecificError('alreadyVerified');
        }

        return result?.completeRegistration?.email;
    }

    private async _startShoptetLogin(variables: StartShoptetLoginMutationVariables): Promise<string> {
        if (!variables?.shopId && !variables?.shopHostname) {
            throw new MalformedUrlError();
        }

        const request: MutationOptions<StartShoptetLoginMutation, StartShoptetLoginMutationVariables> = {
            mutation: StartShoptetLoginDocument,
            variables,
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'startShoptetLogin',
            params: request.variables,
        };
        const response = await this.mutate(request, context);
        if (!response?.startShoptetLogin?.authUrl) {
            throw new NotFoundError();
        }

        return response.startShoptetLogin.authUrl;
    }
}
