import { LanguageService } from '../localization/LanguageService';

export async function initOneSignal(userId: number) {
    let languageService = new LanguageService(false);
    let language = languageService.getLanguage();
    let actionMessage = '';
    let welcomeMessage = '';
    switch (language) {
        case 'en':
            actionMessage = 'Would you like to be notified of new orders?';
            welcomeMessage = 'Thank you for subscription.';
            break;
        case 'cs':
            actionMessage = 'Přejete si být informováni o nových objednávkách?';
            welcomeMessage = 'Děkujeme za odběr.';
            break;
    }

    window.OneSignal &&
        window.OneSignal.push(function () {
            window.OneSignal.init({
                appId: '60279c4c-a024-4e6f-80a6-495fe01b92b2',
                autoResubscribe: true,
                promptOptions: {
                    actionMessage: actionMessage,
                },
                welcomeNotification: {
                    title: 'Expando App',
                    message: welcomeMessage,
                    // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
                },
                allowLocalhostAsSecureOrigin: true,
            });
            let notificationPromptDelay = 3000;
            let navigationStart = window.performance.timing.navigationStart;
            let timeNow = Date.now();

            setTimeout(promptAndSubscribeUser, Math.max(notificationPromptDelay - (timeNow - navigationStart), 0));
        });

    function promptAndSubscribeUser(): void {
        window.OneSignal &&
            window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
                if (!isEnabled) {
                    window.OneSignal && window.OneSignal.showSlidedownPrompt();
                }
            });
    }

    await userIdToOneSignal(userId);
}

export async function userIdToOneSignal(userId: number) {
    window.OneSignal && window.OneSignal.setExternalUserId(userId);
}
