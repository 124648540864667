import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from './ErrorPage';
import { useLocation } from 'react-router-dom';

export interface ServerErrorLocationState {
    desc?: string;
}
export interface ServerErrorPageProps {
    desc?: string;
}

export const ServerErrorPage: FunctionComponent<ServerErrorPageProps> = ({ desc }) => {
    const { t } = useTranslation();
    const location = useLocation();

    let descFinal = desc;
    const state: ServerErrorLocationState = location.state;
    if (state?.desc) {
        descFinal = state.desc;
    }

    return (
        <ErrorPage
            code={500}
            title={t('error.unexpectedError.title')}
            message={t('error.unexpectedError.message')}
            desc={descFinal}
        />
    );
};
