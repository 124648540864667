import { FunctionComponent, useEffect } from 'react';
import { Box, Container } from 'react-layout-components';
import { Heading, Paragraph } from 'grommet';
import profileIcon from '../../assets/profile.svg';
import { Trans } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { Timeout } from '../../components/Timeout';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { useService } from 'src/hooks/useService';
import { useNavigate, useParams } from 'react-router-dom';
import { SpecificError } from 'src/errors';
import NotificationPanel from 'src/components/NotificationPanel/NotificationPanel';
import { paths } from 'src/routing/paths';

import './ConfirmEmail.scss';

// TODO MSL redesign
declare const SERVICES: ServiceProvider;

export const ConfirmEmailPage: FunctionComponent = () => {
    const { makeRequest, isLoading, data, error } = useService();
    const { code } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (code) {
            makeRequest(() => SERVICES.auth.confirmEmail(code));
        }
    }, []);

    const redirectSegment = () => {
        return (
            <Container column center paddingLeft={24} paddingRight={24}>
                <Paragraph>You will be redirected momentarily</Paragraph>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                    <Timeout seconds={4} callback={() => navigate(paths.eve.root)} />
                </div>
            </Container>
        );
    };

    const content = (alreadyVerified = false) => {
        return (
            <Box center>
                <Container width={500} column>
                    <Container padding={24} center>
                        <img src={profileIcon} alt="profile icon" className="Icon" />
                    </Container>
                    <Heading alignSelf="center">
                        <Trans>Email {alreadyVerified ? 'already ' : ''}verified</Trans>
                    </Heading>
                    {redirectSegment()}
                </Container>
            </Box>
        );
    };

    const verificationDoesntExist = () => {
        return (
            <Box center>
                <Container width={540} column>
                    <Container padding={24} center>
                        <img src={profileIcon} alt="profile icon" className="Icon" />
                    </Container>
                    <Heading alignSelf="center">
                        <Trans>Verification doesn't exist</Trans>
                    </Heading>
                    {redirectSegment()}
                </Container>
            </Box>
        );
    };

    const verificationExpired = () => {
        return (
            <Box center>
                <Container width={540} column>
                    <Container padding={24} center>
                        <img src={profileIcon} alt="profile icon" className="Icon" />
                    </Container>
                    <Heading alignSelf="center">
                        <Trans>Verification expired</Trans>
                    </Heading>
                    {redirectSegment()}
                </Container>
            </Box>
        );
    };

    if (!code)
        return (
            <p>
                <Trans>missing parameter code</Trans>
            </p>
        );

    if (isLoading)
        return (
            <Box center>
                <Spinner color="primary" className="mr-2" />
            </Box>
        );

    if (error instanceof SpecificError) {
        if (error.code === 'verificationDoesntExist') {
            return verificationDoesntExist();
        }
        if (error.code === 'verificationExpired') {
            return verificationExpired();
        }
        if (error.code === 'alreadyVerified') {
            return content(true);
        }
    }
    if (error) {
        return <NotificationPanel message={error.message} type="danger" />;
    }
    return content();
};

export default ConfirmEmailPage;
