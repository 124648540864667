import { MarketplaceName } from 'src/graphql/generated';
import { RequestContext } from 'src/services/Service';

export abstract class LoggedError extends Error {
    public context?: RequestContext;

    constructor(message?: string, context?: RequestContext) {
        super(message);
        this.context = context;
    }
}
export class MalformedUrlError extends LoggedError {}
export class NotFoundError extends LoggedError {}
export class UnexpectedError extends LoggedError {}
export class UnavailableServiceError extends LoggedError {
    constructor(context?: RequestContext) {
        super('Service unavailable', context);
    }
}
/**
 * Just some error that differs from general one and contains code which specifies error better.
 */
export class SpecificError extends LoggedError {
    public code: string;
    public meta: Record<string, any>;

    constructor(code: string, meta: Record<string, any> = {}) {
        super('Error. See `error.code` and `error.meta` for more detail');
        this.code = code;
        this.meta = meta;
    }
}
// @deprecated, use more general Specific error instead
export class MarketplaceError extends LoggedError {
    public marketplace: MarketplaceName;
    public type: 'duplicitBranchId';
    public payload: any;

    constructor(marketplace: MarketplaceName, type: 'duplicitBranchId', payload: any) {
        super();
        this.marketplace = marketplace;
        this.type = type;
        this.payload = payload;
    }
}

export class OperationFailedError extends LoggedError {
    public data: Partial<{
        property: string;
        value: string;
        context: RequestContext;
    }> = {};

    constructor(property: string, value: string, context?: RequestContext) {
        super(`Operation failed with '${property}' of '${value}'`, context);
        this.data = {
            context,
            property,
            value,
        };
    }
}

export class StatusError extends Error {
    public statusCode: number;

    constructor(statusCode: number, message: string) {
        super(message);
        this.statusCode = statusCode;
    }
}

export class MissingReportError extends Error {}
