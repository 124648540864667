import { FunctionComponent, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { paths } from './paths';
import { Suspense } from './Suspense';
import { ProtectedRoute } from './ProtectedRoute';
import { sharedRoutes } from './SharedRoutes';
import { HasRoutingProps } from 'src/common/props';
import { marketplacesRoutes } from './ConnectMarketplacesRoutes';
import { useTranslation } from 'src/hooks/useTranslation';

// Leave the comment in import section for webpack to gracefully name code split chunks
// see more at https://hackernoon.com/effective-code-splitting-in-react-a-practical-guide-2195359d5d49
const Dashboard = lazy(() => import('../screens/Dashboard/Dashboard' /* webpackChunkName: "Dashboard" */));
const Statistics = lazy(() => import('../screens/Statistics/Statistics' /* webpackChunkName: "Statistics" */));
const Marketplaces = lazy(
    () => import('../screens/Marketplaces/ConnectedMarketplaces' /* webpackChunkName: "Marketplaces" */),
);
const ProductSourceSettings = lazy(
    () => import('../screens/ProductSource/ProductSourceSettings' /* webpackChunkName: "ProductSourceSettings" */),
);
const Orders = lazy(() => import('../screens/Orders/Orders_old' /* webpackChunkName: "Orders" */));
const Products = lazy(() => import('../screens/Products' /* webpackChunkName: "Products" */));
const ProductsFileUpdate = lazy(
    () => import('../screens/ProductsFileUpdate' /* webpackChunkName: "ProductsFileUpdate" */),
);
const Autopricer = lazy(() => import('../screens/Autopricer' /* webpackChunkName: "Autopricer" */));
const AutopricerFileUpdate = lazy(
    () => import('../screens/Autopricer/AutopricerFileUpdate' /* webpackChunkName: "AutopricerFileUpdate" */),
);
const UserStats = lazy(() => import('../screens/UserStats' /* webpackChunkName: "UserStats" */));
const AdminStatistics = lazy(() => import('../screens/AdminStatistics' /* webpackChunkName: "AdminStatistics" */));
const UsersTable = lazy(() => import('../screens/UsersTable/UsersTable' /* webpackChunkName: "UsersTable" */));
const Settings = lazy(() => import('../screens/Settings' /* webpackChunkName: "Settings" */));

export const DashboardRoutes: FunctionComponent<HasRoutingProps> = ({ url }) => {
    const { t } = useTranslation();

    return (
        <Routes>
            <Route path="dashboard" element={<Suspense element={Dashboard} />} />
            <Route path="orders-statistics" element={<Suspense element={Statistics} />} />
            <Route path="marketplaces" element={<Suspense element={Marketplaces} />} />
            <Route path="product-feed" element={<Suspense element={ProductSourceSettings} />} />
            <Route path="orders" element={<Suspense element={Orders} />} />
            <Route path="products" element={<Suspense element={Products} />} />
            <Route path="update-products-by-file" element={<Suspense element={ProductsFileUpdate} />} />
            <Route path="autopricer" element={<Suspense element={Autopricer} />} />
            <Route path="update-autopricer-by-file" element={<Suspense element={AutopricerFileUpdate} />} />
            <Route
                path="stats"
                element={<ProtectedRoute requireSuperadmin>{<Suspense element={UserStats} />}</ProtectedRoute>}
            />
            <Route path="admin-statistics" element={<Suspense element={AdminStatistics} />} />
            <Route path="users-table" element={<Suspense element={UsersTable} />} />
            <Route path="sync-settings" element={<Suspense element={Settings} />} />

            <Route path="connect-marketplaces">{marketplacesRoutes(false, t)}</Route>

            <Route path="pricing" element={<Navigate to={paths.eve.autopricer} replace />} />

            <Route index element={<Navigate to={paths.eve.dashboard} replace />} />

            {sharedRoutes(url)}
        </Routes>
    );
};
