import { FormStateData } from 'src/components/form/SimpleForm';
import { AbstractBloc2, AbstractBlocActionData } from './AbstractBloc2';

export type FormEvent = 'get' | 'set' | 'del';

/**
 * Common bloc pattern functionality sufficient for majority of forms.
 */
export abstract class AbstractFormBloc<D, FD> extends AbstractBloc2<FormEvent, FormStateData<string, FD>> {
    protected formData: FD;

    /**
     * Converts data to form data
     * @param data
     * @return form data
     */
    protected abstract getFormData(data: D): FD;

    protected abstract requestGetData(): Promise<D>;
    protected responseGetData(data: any) {}

    protected abstract requestSetData(data: FD): Promise<D>;
    protected responseSetData(data: any) {}

    public start(): void {
        this.makeRequest('get', () => this.requestGetData());
    }

    public produceStateData(props: AbstractBlocActionData<FormEvent, D>): void {
        const { data, error } = props;
        const state = this.getState(props);
        this.beforeProduceState(props, state);
        if (data || !this.formData) {
            this.formData = this.getFormData(data);
        }
        this.next({
            state,
            data: this.formData,
            error: this.getError(error),
            onSubmit: (data) => this.handleSubmit(data),
            ...this.produceExtraStateData(props),
        });
        this.afterProduceState(props, state);
    }

    protected beforeProduceState(props: AbstractBlocActionData<FormEvent, D>, state: string) {
        const { action, data } = props;
        if (action === 'get' && state === 'requested') this.responseGetData(data);
        if (action === 'set' && state === 'requested') this.responseSetData(data);
    }
    protected afterProduceState(props: AbstractBlocActionData<FormEvent, D>, state: string) {}

    // I prefer arrow functions for handling methods, but not in classes, since arrow functions cannot be overriden by inheritance.
    // Following error occurs when calling 'super.handleSubmit(data)' in child:
    // "An unhandled error was caught from submitForm() TypeError: (intermediate value).handleSubmit is not a function"
    protected handleSubmit(data: FD) {
        this.formData = data;
        this.makeRequest('set', () => this.requestSetData(data));
    }
}
