import { FunctionComponent } from 'react';
import PreviousButton from 'src/components/onboarding/partial/PreviousButton';
import ConnectLater from 'src/components/onboarding/partial/ConnectLaterButton';
import { useTranslation } from 'react-i18next';
import { EnumCompanyOnboardingState } from 'src/graphql/generated';

export interface FooterNavigationProps {
    previousPath?: string;
    connectLaterPath?: string;
    onboardingStateToSet?: EnumCompanyOnboardingState;
    /**
     * Each step can specify custom texts for buttons under `{i18nPrefix}.btn.previousStep` or `{i18nPrefix}.btn.connectLater`.
     * If no such text is found, an fallback onboarding text is used under `onboarding.btn.previousStep` or `onboarding.btn.connectLater`.
     */
    i18nPrefix?: string;
}

const I18N_FALLBACK_PREFIX = 'onboarding';
const FooterNavigation: FunctionComponent<FooterNavigationProps> = (props) => {
    const { previousPath, connectLaterPath, onboardingStateToSet, i18nPrefix } = props;

    const { t, i18n } = useTranslation();

    const getText = (suffix: string) => {
        const textCandidate = `${i18nPrefix}.btn.${suffix}`;
        return i18n.exists(textCandidate) ? t(textCandidate) : t(`${I18N_FALLBACK_PREFIX}.btn.${suffix}`);
    };

    return (
        <footer className="onboarding-footer">
            {previousPath && <PreviousButton path={previousPath} text={getText('previousStep')} />}
            {connectLaterPath && (
                <ConnectLater
                    path={connectLaterPath}
                    text={getText('connectLater')}
                    onboardingStateToSet={onboardingStateToSet}
                />
            )}
        </footer>
    );
};
FooterNavigation.defaultProps = {
    previousPath: null,
    connectLaterPath: null,
    i18nPrefix: I18N_FALLBACK_PREFIX,
};

export default FooterNavigation;
