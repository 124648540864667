import { MutationOptions, QueryOptions } from '@apollo/client';
import { OperationFailedError, UnexpectedError } from 'src/errors';
import { Report } from 'src/hooks/useReportPolling';
import {
    AutopricingReport,
    AutopricingReportOptions,
    AutopricingReportState,
    BulkUpdateAutopricerLimitsDocument,
    BulkUpdateAutopricerLimitsMutation,
    BulkUpdateAutopricerLimitsMutationVariables,
    GetAutopricingReportDocument,
    GetAutopricingReportQuery,
    GetProductPricingDataDocument,
    GetProductPricingDataQuery,
    GetProductPricingDataQueryVariables,
    GetSyncSettingsDocument,
    GetSyncSettingsQuery,
    GetSyncSettingsQueryVariables,
    newSettings,
    RequestAutopricingReportDocument,
    RequestAutopricingReportMutation,
    RequestAutopricingReportMutationVariables,
    SyncSettingsAutopricing,
} from '../../graphql/generated';
import { ErrorInterceptor, ExtendedQueryOptions, RequestContext, Service, StopPollingWhenPrototype } from '../Service';

export interface GetReportResult extends Report, Pick<AutopricingReport, 'resultItems'> {}

export class AutopricerService extends Service {
    async findAll(options?: GetProductPricingDataQueryVariables) {
        let result = await this.query<GetProductPricingDataQuery>(
            {
                query: GetProductPricingDataDocument,
                variables: options,
            },
            {
                method: 'findAll',
                params: { options },
            },
        );

        return result;
    }

    /**
     * Updates autopricer limit values in bulk
     * @param data
     * @param err
     * @returns true if operation succeeded, false otherwise
     */
    async bulkUpdate(data: newSettings, errorInterceptor?: ErrorInterceptor): Promise<boolean> {
        const request: MutationOptions<BulkUpdateAutopricerLimitsMutation> = {
            mutation: BulkUpdateAutopricerLimitsDocument,
            variables: {
                data,
            },
        };
        const context: RequestContext = {
            method: 'bulkUpdate',
            params: data,
            errorInterceptor,
        };
        const response = await this.mutate<
            BulkUpdateAutopricerLimitsMutation,
            BulkUpdateAutopricerLimitsMutationVariables
        >(request, context);

        if (!response.updateProductAutopricingLimitsBulk?.success) {
            throw new OperationFailedError('success', 'false', context);
        }

        return true;
    }

    /**
     * Requests an autopricing report to be generated
     * @param options
     * @param errorInterceptor
     */
    async generateReport(options: AutopricingReportOptions, errorInterceptor?: ErrorInterceptor): Promise<void> {
        const request: MutationOptions<RequestAutopricingReportMutation> = {
            mutation: RequestAutopricingReportDocument,
            variables: options,
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'generateReport',
            params: options,
            errorInterceptor,
        };
        const response = await this.mutate<RequestAutopricingReportMutation, RequestAutopricingReportMutationVariables>(
            request,
            context,
        );

        const result = response?.requestAutopricingReport;
        if (!result) {
            throw new UnexpectedError('no data', context);
        }
        if (result.state === AutopricingReportState.error) {
            throw new OperationFailedError('state', AutopricingReportState.error);
        }
    }

    /**
     * Gets an autopricing report
     * @returns AutopricingReport
     */
    async getSyncSettings(): Promise<SyncSettingsAutopricing> {
        const request: QueryOptions<GetSyncSettingsQueryVariables, GetSyncSettingsQuery> = {
            query: GetSyncSettingsDocument,
        };
        const context: RequestContext = {
            method: 'getSyncSettings',
        };
        const response = await this.query(request, context);

        return response?.syncSettings?.autopricing ?? null;
    }

    /**
     * Polls a report. Returns promise that is resolved when report is finished. But 'stopPollingWhen' gets the result of each poll.
     * @param stopPollingWhen determines when to stop pooling
     * @returns AutopricingReport when finished
     */
    async pollReport(stopPollingWhen?: StopPollingWhenPrototype): Promise<GetReportResult> {
        const getReport = (response: GetAutopricingReportQuery): GetReportResult => {
            const result = response?.getAutopricingReport ?? {};
            return {
                state: result?.state as any,
                progress: result?.progress,
                resultItems: result?.resultItems,
            };
        };
        const pollInterval = 500;
        const request: ExtendedQueryOptions<GetAutopricingReportQuery, {}> = {
            query: GetAutopricingReportDocument,
            pollInterval,
            stopPollingWhen: (response) => stopPollingWhen(getReport(response)),
            fetchPolicy: 'no-cache',
        };
        const context: RequestContext = {
            method: 'poolReport',
        };
        const result = await this.watchQuery(request, context);

        return getReport(result);
    }
}
