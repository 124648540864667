import { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import Form from 'src/components/form/Form';
import ConnectMarketplaceInput from './ConnectMarketplaceInput';

export interface ConnectMarketplaceFormProps {
    // Fields per row
    fields: FieldOption[][];
    i18nPrefix: string;
    isSubmitting: boolean;
}

export interface FieldOption {
    name: string;
    /**
     * Large screen size, default: 6
     * Small screen is always 12
     */
    size?: 6 | 12;
}

const ConnectMarketplaceForm: FunctionComponent<ConnectMarketplaceFormProps> = ({
    fields: rows,
    i18nPrefix,
    isSubmitting,
}) => {
    let tabIndex = 1;

    const Field: FunctionComponent<{ name: string }> = ({ name }) => {
        return (
            <ConnectMarketplaceInput
                name={name}
                tabIndex={tabIndex++}
                isSubmitting={isSubmitting}
                i18nPrefix={i18nPrefix}
            />
        );
    };

    const Cols: FunctionComponent<{ cols: FieldOption[] }> = ({ cols }) => {
        return (
            <>
                {cols.map((field) => {
                    return (
                        <Col key={`col-${field.name}`} xs="12" lg={field.size ?? 6}>
                            <Field name={field.name} />
                        </Col>
                    );
                })}
            </>
        );
    };

    return (
        <Form>
            {rows.map((cols, idx) => (
                <Row key={`row-${idx}`}>
                    <Cols cols={cols}></Cols>
                </Row>
            ))}
        </Form>
    );
};
ConnectMarketplaceForm.defaultProps = {
    isSubmitting: false,
};

export default ConnectMarketplaceForm;
