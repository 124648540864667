import { FunctionComponent } from 'react';
import { OptionsButton } from 'src/ui';
import { LanguageSelectorImplProps } from './LanguageSelector';
import { useTranslation } from 'src/hooks/useTranslation';

import '../../ui/button/ExpandoButton.scss';

const TextLanguageSelector: FunctionComponent<LanguageSelectorImplProps> = (props) => {
    const { selected, direction, languages, onSelection } = props;
    const { t } = useTranslation();

    const options = languages.map((lng) => ({
        label: t(`country.${lng}`),
        value: lng,
    }));

    return (
        <OptionsButton
            color="link"
            direction={direction}
            text={t(`country.${selected}`)}
            options={options}
            onSelection={(lng) => onSelection(lng.value)}
        />
    );
};

export default TextLanguageSelector;
