import { forwardRef, Fragment, FunctionComponent, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, getIn } from 'formik';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import { getDateFormat } from 'src/library/date-formats';
import FormError from './FormError';

import 'react-datepicker/dist/react-datepicker.css';

export interface FormDateProps extends InputHTMLAttributes<any> {}

const FormDate: FunctionComponent<FormDateProps> = (props) => {
    const { name, className, disabled, ...rest } = props;
    let { i18n } = useTranslation();
    let dateFormat = getDateFormat(i18n.language)
        // workaround to match react-datepicker requirements for
        .replace(/D/g, 'd')
        .replace(/Y/g, 'y');

    return (
        <div className={`expando-form-control ${className ?? ''}`}>
            <Field name={name}>
                {({ field, form }) => {
                    const isInvalid = getIn(form.touched, name) && !!getIn(form.errors, name);
                    // TODO MSL customInput didn't work well so not sure how to provide error classes
                    const inputClasses = cn({
                        'expando-form-input': true,
                        invalid: isInvalid,
                    });
                    const CustomInput = forwardRef((props, ref) => (
                        <input {...field} ref={ref} {...props} {...rest} className={inputClasses} />
                    ));
                    return (
                        <Fragment>
                            <DatePicker
                                name={field.name}
                                selected={field.value}
                                onChange={(selected: Date) => {
                                    form.setFieldTouched(name);
                                    form.handleChange({ currentTarget: { name, value: selected } });
                                }}
                                dateFormat={dateFormat}
                                // customInput={<CustomInput />}
                                readOnly={disabled}
                            />
                            <FormError name={name} isInvalid={isInvalid} />
                        </Fragment>
                    );
                }}
            </Field>
        </div>
    );
};
FormDate.defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
};

export default FormDate;
