import { GetPlatformStatusesQuery, Platform } from 'src/graphql/generated';
import { PLATFORMS } from 'src/library/marketplace';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { TFunction } from 'src/hooks/useTranslation';
import { RouteUtils } from 'src/utils/RouteUtils';
import {
    MarketplaceSelectorIncorrectStateData,
    MarketplaceSelectorLoadedStateData,
    MarketplaceSelectorLoadingStateData,
    MarketplaceSelectorStateData,
} from './MarketplaceSelector';
import { AbstractBloc } from 'src/library/bloc';

declare const SERVICES: ServiceProvider;

export class MarketplaceSelectorBloc extends AbstractBloc<'get', MarketplaceSelectorStateData> {
    public getName(): string {
        return 'MarketplaceSelectorBloc';
    }
    private ignorePlatforms: Platform[];
    /**
     * Platforms to be listed. List order is preserved.
     * @param ignorePlatforms
     * @param t
     * @param initialState
     */
    constructor(
        ignorePlatforms: Platform[] = [],
        t: TFunction,
        initialState: MarketplaceSelectorStateData = { state: 'loading' },
    ) {
        super(initialState, t);
        this.ignorePlatforms = ignorePlatforms;
    }

    start = () => this.makeRequest('get', () => SERVICES.platform.getPlatformStatuses());

    produceEvent(action: 'get', isLoading: boolean, data: GetPlatformStatusesQuery, error: Error) {
        if (isLoading) {
            return this.produceStateDataEvent<MarketplaceSelectorLoadingStateData>({
                state: 'loading',
            });
        }

        if (error) {
            return this.produceStateDataEvent<MarketplaceSelectorIncorrectStateData>({
                state: 'incorrect',
                error: error.message,
            });
        }

        const platforms = filterPlatforms(
            this.ignorePlatforms,
            data?.glamiEnabledFeaturetoggle?.enabled || false,
            data?.ebayEnabledFeaturetoggle?.enabled || false,
            data?.platformStatuses || ({} as any),
        );

        return this.produceStateDataEvent<MarketplaceSelectorLoadedStateData>({
            state: 'loaded',
            platforms,
            dataSource: RouteUtils.parseQuery()?.source,
        });
    }
}

export function filterPlatforms(
    ignorePlatforms: Platform[],
    isGlamiEnabled: boolean,
    isEbayEnabled: boolean,
    platformStatuses: Pick<GetPlatformStatusesQuery, 'platformStatuses'>,
) {
    return PLATFORMS.filter((o) => {
        if (ignorePlatforms.includes(o)) {
            return false;
        }
        if (o === Platform.glami) {
            return isGlamiEnabled;
        }
        if (o === Platform.ebay) {
            return isEbayEnabled;
        }
        if ([Platform.allegro, Platform.baskedo, Platform.bol, Platform.expando_shop, Platform.limetka].includes(o)) {
            return false;
        }
        return !platformStatuses?.[o]?.hidden;
    });
}
