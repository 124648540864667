import i18next from 'i18next';
import moment, { MomentInput } from 'moment';
import { getDateFormat, getDateTimeFormat, getTimeFormat } from './date-formats';
import { getCurrency } from './currency';
import { MarketplaceName } from '../graphql/generated';

export function number(input: number) {
    let formatter = new Intl.NumberFormat(i18next.language);
    if (input === null || input === undefined) input = 0;
    return formatter.format(input);
}

export function time(date) {
    if (!(date instanceof Date)) {
        return '';
    }

    let hours = date.getHours().toString();
    let mins = date.getMinutes().toString();
    if (mins.length < 2) mins = '0' + mins;

    return `${hours}:${mins}`;
}

export function formatDateTime(date: MomentInput) {
    let lang = i18next.language;
    return moment(date).format(getDateTimeFormat(lang));
}

export function formatDate(date: MomentInput) {
    let lang = i18next.language;
    return moment(date).format(getDateFormat(lang));
}

export function formatTime(date: MomentInput) {
    let lang = i18next.language;
    return moment(date).format(getTimeFormat(lang));
}

export function parseDate(dateString: MomentInput) {
    let lang = i18next.language;
    try {
        let result = moment(dateString, getDateFormat(lang), lang).toDate();
        if (isNaN(result.getTime())) return null;
        return result;
    } catch (e) {
        return null;
    }
}

export function marketplaceToLabel(marketplaceName: MarketplaceName) {
    const words = marketplaceName.split('_');
    if (words.length === 1) return capitalizeFirst(words[0]);
    return [...words.slice(0, -1).map(capitalizeFirst), ...words.slice(-1).map(uppercase)].join(' ');
}

export function money(input: { value: number; currency: string }): string {
    let value = input && input.value;
    if (typeof value !== 'number') return '--';

    let lang = i18next.language;
    const moneyFormat = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: (input && input.currency) || 'EUR',
    });
    return moneyFormat.format(value);
}

export const formatPrice = (price, marketplace) => {
    const currency = getCurrency(marketplace);
    return money({ value: price, currency });
};

export function capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uppercase(string) {
    return string.toUpperCase();
}
