import { FunctionComponent } from 'react';
import { ResetPasswordForm } from './ResetPasswordForm';
import { LoginFooter } from '../Login/LoginFooter';
import AuthLayout from '../AuthLayout';
import { useTranslation } from 'src/hooks/useTranslation';
import { ResetPasswordBloc } from './ResetPasswordBloc';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';

export interface ResetPasswordProps {
    bloc: IAbstractBloc;
}
export const ResetPassword: FunctionComponent<ResetPasswordProps> = ({ bloc }) => {
    return (
        <AuthLayout
            type="resetPassword"
            bloc={bloc}
            authWithCards={[]}
            content={ResetPasswordForm}
            footer={LoginFooter}
        />
    );
};

export const ResetPasswordOrchestrator: FunctionComponent = () => {
    const { t } = useTranslation();
    return <ResetPassword bloc={new ResetPasswordBloc({ state: 'empty' }, t)} />;
};
