import { ApplicationCredentials, MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { FormData } from './ConnectEbayForm';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { RouteUtils } from 'src/utils/RouteUtils';
import { ebayRegionsMapping, EbayRegionsMappingVal, getAuthUrl, getRegistrationUrl } from './helpers';

declare const SERVICES: ServiceProvider;

export class ConnectEbayBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectEbayBloc';
    }

    public produceEvent(action: 'get', isLoading: boolean, data: any, error: Error) {
        const query = RouteUtils.parseQuery<{ error?: string; succ?: string }>();
        if (query.error) {
            error = new Error(query.error);
        }
        this.next({
            state: this.getStepState(action, isLoading, data, error, this.isDone),
            error: query.error ? this.t('connectEbayApi.failed', { error: this.getErrorMessage(error) }) : '',
            success: query.succ ? this.t('connectEbayApi.success') : '',
            data: this.getApplicationFormData(data),
            onOpen: () => {
                this.isDone = true;
                this.produceEvent(action, false, data, null);
            },
            onSave: () => {
                this.isDone = true;
                this.produceEvent(action, false, data, null);
            },
        });
    }

    protected onPrevStepCompleted(): void {
        throw new Error('Method not implemented.');
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        throw new Error('Method not implemented.');
    }

    protected getApplicationFormData(data: ApplicationCredentials): FormData {
        const mapping: EbayRegionsMappingVal = ebayRegionsMapping[this.region];
        return {
            authUrl: getAuthUrl(mapping),
            registrationUrl: getRegistrationUrl(mapping, data),
            marketplace: mapping.marketplace,
        };
    }
}
