import { FormData } from './SetPasswordForm';
import { AbstractFormBloc } from 'src/library/bloc/AbstractFormBloc';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { RouteUtils } from 'src/utils/RouteUtils';
import { paths } from 'src/routing/paths';
import { SpecificError } from 'src/errors';

declare const SERVICES: ServiceProvider;

export class SetPasswordBloc extends AbstractFormBloc<any, FormData> {
    getName(): string {
        return 'SetPasswordBloc';
    }

    getFormData(): FormData {
        return {
            password: '',
            passwordConfirm: '',
        };
    }

    requestGetData(): Promise<any> {
        return Promise.resolve();
    }

    requestSetData(data: FormData): Promise<any> {
        const query = RouteUtils.parseQuery<{ code: string }>();
        if (query?.code) {
            this.produceError('set', new SpecificError('urlIsMalformed'));
        }
        return SERVICES.auth.finalizePasswordReset(data.password, query.code);
    }

    responseSetData() {
        window.location.href = paths.auth.login;
    }

    protected getErrorMessage(error: Error) {
        if (error instanceof SpecificError) {
            const i18nPrefix = 'auth.setPassword.error';
            switch (error.code) {
                case 'urlIsMalformed':
                    return this.t(`${i18nPrefix}.urlIsMalformed`);
                case 'codeAlreadyUsed':
                    return this.t(`${i18nPrefix}.codeAlreadyUsed`);
                case 'codeExpired':
                    return this.t(`${i18nPrefix}.codeExpired`);
                case 'invalidCode':
                    return this.t(`${i18nPrefix}.invalidCode`);
                default:
                    return this.t(`${i18nPrefix}.unexpectedError`);
            }
        }
        return super.getError(error);
    }
}
