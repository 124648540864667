import { useTranslation as useI18nextTranslation } from 'react-i18next';
import { HasI18nProps } from 'src/common/props';

export interface TFunction {
    (key: string, params?: Record<string, any>): string;
}
export interface ExistsFunction {
    (key: string): boolean;
}

export interface TOptions {
    i18nPrefix?: string;
    /**
     * If key is not found withing prefix if defined.
     * It will be looked up in parent up to root.
     * Note: false by default, since it can cause sometimes unwanted lookup
     *       Consider conditionally displayed text (by it's presence) in translation file
     *       but when not found it whould lookup in parents which could match unwanted translations
     */
    lookup?: boolean;
}

export const defaults: TOptions = {
    i18nPrefix: '',
    lookup: false,
};

/**
 * Same as 'i18next' useTranslation hook with extra lookup for parent key candidates.
 * This allows us to have some general texts under specific key and with prefixe we can override this texts for specific cases.
 * @see useTranslation.spec.tsx for some examples
 *
 * As extra benefit is that it isolates dependency on 'i18next' library ( this pottentialy allows us to swich i18n library )
 *
 * @param options
 * @returns
 */
export function useTranslation(options?: TOptions): HasI18nProps {
    const i18nPrefix = options?.i18nPrefix ?? defaults.i18nPrefix;
    const lookup = options?.lookup ?? defaults.lookup;

    const { t, i18n } = useI18nextTranslation();

    const getKey = (prefix: string, key: string) => {
        return prefix.length > 0 ? `${prefix}.${key}` : key;
    };

    /**
     * Get key candidate either within given prefix or any higher up to root if lookup enabled or key itself.
     * @param prefix
     * @param key
     * @returns key
     */
    const getKeyCandidate = (prefix: string, key: string) => {
        if (!prefix) return key;

        const keyCandidate = getKey(prefix, key);
        if (i18n.exists(keyCandidate)) {
            return keyCandidate;
        }
        if (!lookup) return keyCandidate;

        const dotIndex = prefix.lastIndexOf('.');
        if (dotIndex === -1) {
            return getKeyCandidate('', key);
        }
        const newPrefix = prefix.substring(0, dotIndex);
        return getKeyCandidate(newPrefix, key);
    };

    const newExist: ExistsFunction = (key: string) => {
        const finalKey = getKeyCandidate(i18nPrefix, key);
        return i18n.exists(finalKey);
    };

    const newT: TFunction = (key: string, params: Record<string, any>) => {
        const existingKey = getKeyCandidate(i18nPrefix, key);
        return t(existingKey, params);
    };

    return {
        t: newT,
        exists: newExist,
    };
}
