import { FunctionComponent } from 'react';
import { Form as FormikForm } from 'formik';
import cn from 'classnames';
import { isNil } from 'lodash';

import './Form.scss';

export interface FormProps {
    className?: string;
}

const Form: FunctionComponent<FormProps> = (props) => {
    const { className, children } = props;

    const classes = cn({
        'expando-form': true,
        [className]: !isNil(className),
    });

    return <FormikForm className={classes}>{children}</FormikForm>;
};

export default Form;
