import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { useToggleState } from 'src/hooks';
import { Button } from 'src/ui';

import './CopyCard.scss';

export interface CopyCardProps {
    value: string;
}

const CopyCard: FunctionComponent<CopyCardProps> = ({ value }) => {
    const { t } = useTranslation();

    const { isOpen, open, close } = useToggleState();
    let timeoutId = null;

    useEffect(() => {
        return () => clearBadgeTimeout();
    }, []);

    const clearBadgeTimeout = () => timeoutId && clearTimeout(timeoutId);

    const handleCopy = () => {
        open();
        clearBadgeTimeout();
        timeoutId = setTimeout(close, 5000);
        navigator.clipboard.writeText(value);
    };

    const badgeClasses = isOpen ? '' : 'showing';

    return (
        <div className="copy-card">
            <div className="input-control">
                <input type="text" readOnly value={value} className="expando-form-input" />
                <Badge color="success" className={badgeClasses}>
                    {t('general.copied')}
                </Badge>
            </div>
            <Button text={t('btn.copy')} color="secondary" onClick={handleCopy} />
        </div>
    );
};

export default CopyCard;
