import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from 'reactstrap';
import { IUserContext, UserContext } from '../../../Contexts/User/UserContext';
import { ServiceProvider } from '../../../services/ServiceProvider';

declare const SERVICES: ServiceProvider;

export const LogoutButton: FunctionComponent = () => {
    const { onLogout } = useContext<IUserContext>(UserContext);
    const { t } = useTranslation();

    const handleLogout = async () => {
        try {
            await SERVICES.auth.logout();
            await onLogout();
        } catch (error) {
            alert(error.message);
        }
    };

    return <DropdownItem onClick={handleLogout}>{t('btn.signOut')}</DropdownItem>;
};
