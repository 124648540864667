import React, { FunctionComponent, useEffect, useState } from 'react';
import PageTitle from 'src/ui/title/PageTitle';
import { platformFromMarketplaceRegion } from 'src/library/marketplace';
import { useTranslation } from 'react-i18next';
import { MarketplaceRegion, Platform } from '../../../graphql/generated';
import FooterNavigation from '../FooterNavigationNew';
import { WizardBloc, WizardStateData, WizardStepBloc } from 'src/library/bloc';
import Loader from 'src/ui/loader/Loader';
import NotificationPanel from 'src/components/NotificationPanel/NotificationPanel';
import { paths } from 'src/routing/paths';

import '../Onboarding.scss';

export interface ConnectMarketplaceWizardProps {
    bloc: WizardBloc<any>;
    stepBlocs: WizardStepBloc<any, any, any>[];
    region: MarketplaceRegion;
    showWizardComponents: boolean;
    i18nPrefix?: string;
}
export interface ConnectMarketplaceChildrenProps {
    bloc?: WizardStepBloc<any, any, any>;
    region?: MarketplaceRegion;
    platform?: Platform;
    name?: string;
    i18nPrefix?: string;
}

const ConnectMarketplaceWizard: FunctionComponent<ConnectMarketplaceWizardProps> = (props) => {
    const { bloc, stepBlocs, region, i18nPrefix, showWizardComponents, children } = props;

    const { t } = useTranslation();

    const [stateData, setStateData] = useState<WizardStateData<any>>({ state: 'loading' });

    useEffect(() => {
        const subscription = bloc.subscribe(setStateData);
        return () => subscription?.unsubscribe();
    }, []);

    const platform = platformFromMarketplaceRegion(region);
    const name = t(`platform.${platform}`);
    const { state, error } = stateData;

    const childrenProps: ConnectMarketplaceChildrenProps = {
        region,
        platform,
        name,
    };

    return (
        <div className="onboarding-container connectMarketplace-container">
            <PageTitle
                heading={showWizardComponents ? t('onboarding.stepStatus', { step: 3 }) : null}
                title={t('connectMarketplace.pageTitle', { name })}
                description={t('connectMarketplace.pageDescription', { name })}
            />

            {/* <VideoCard
                source={platform}
                url={`${i18nPrefix}.videoUrl`}
            /> */}

            {state === 'loading' && <Loader fullpage />}
            {state === 'incorrect' && <NotificationPanel type="danger" message={error} />}

            {/* Inject common properties to each child */}
            {React.Children.map(children, (child, idx) => {
                const childrenPropsExtended: ConnectMarketplaceChildrenProps = {
                    ...childrenProps,
                    bloc: stepBlocs[idx],
                    i18nPrefix: `${i18nPrefix}.step${idx + 1}`,
                };
                return React.cloneElement(child as any, childrenPropsExtended);
            })}

            {showWizardComponents && (
                <FooterNavigation
                    previousPath={paths.onboarding.connectMarketplaces}
                    connectLaterPath={paths.eve.marketplaces}
                    i18nPrefix={i18nPrefix}
                />
            )}
        </div>
    );
};

export default ConnectMarketplaceWizard;
