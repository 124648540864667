import * as yup from 'yup';
import { AbstractForm, FieldOption, ValidationSchemas } from 'src/components/form';
import { Translation } from 'src/common/decorators/Translation';

export interface FormData {
    password: string;
    passwordConfirm: string;
}

@Translation({
    i18nPrefix: 'auth.setPassword',
    lookup: true,
})
export class SetPasswordForm extends AbstractForm<FormData> {
    getFields(): FieldOption[][] {
        return [
            [
                {
                    name: 'password',
                    type: 'password',
                    size: 12,
                },
            ],
            [
                {
                    name: 'passwordConfirm',
                    type: 'password',
                    size: 12,
                },
            ],
        ];
    }

    getValidationSchema(): { [key in keyof FormData]: yup.BaseSchema<any, any, any> } {
        return {
            password: ValidationSchemas.password(this.t),
            passwordConfirm: ValidationSchemas.passwordConfirm(this.t),
        };
    }
}
