import { FunctionComponent, useEffect, useState } from 'react';
import { DashboardRoutes } from '../routing/DashboardRoutes';
import NavbarComponent from '../components/NavbarComponent';
import { GetSidebarInfoComponent } from '../graphql/generated';
import { initOneSignal } from '../library/oneSignal';
import { initHeapAnalytics } from '../library/heapAnalytics';
import { Sidebar } from 'src/components/Sidebar';
import { EnvUtils } from '../utils/EnvUtils';
import { HasRoutingProps } from 'src/common/props';
import { GetUserResult } from 'src/services/user/UserService';

function isUserLogged(user: GetUserResult): boolean {
    return user != null;
}

function isUserSuperadmin(user: GetUserResult): boolean {
    return user?.isSuperadmin || false;
}

const DashboardLayout: FunctionComponent<HasRoutingProps> = (routingProps) => {
    const { user } = routingProps;
    const [isVisibleSidebar, setVisibleSidebar] = useState(localStorage.getItem('sidebar_partial') === 'true' ?? false);
    const [isOneSignalInitialized, setOneSignalInitialized] = useState(false);
    const [isHeapAnalyticsEnabled, setHeapAnalyticsEnabled] = useState(false);

    useEffect(() => {
        // TODO MSL refactor using media queries
        // HISTORY.listen(() => {
        //     autoHideSidebarOnSmallScreen();
        // });

        // autoHideSidebarOnSmallScreen();

        init3rdPartyServices();
    }, []);

    const init3rdPartyServices = () => {
        if (EnvUtils.isProduction() && isUserLogged(user) && !isUserSuperadmin(user)) {
            if (!isOneSignalInitialized)
                initOneSignal(user._id).then(() => {
                    setOneSignalInitialized(true);
                });

            if (!isHeapAnalyticsEnabled)
                initHeapAnalytics().then((success) => {
                    if (success) setHeapAnalyticsEnabled(true);
                });
        }
    };

    const handleToggleSidebar = (value?: boolean) => {
        const newValue = value || !isVisibleSidebar;

        localStorage.setItem('sidebar_partial', newValue ? 'true' : 'false');

        setVisibleSidebar(newValue);
    };

    const autoHideSidebarOnSmallScreen = () => {
        const width = window.innerWidth;
        const mediaBreakpointMd = 992;
        const isSmall = width < mediaBreakpointMd;

        if (isSmall) handleToggleSidebar(false);
    };

    return (
        <div className="wrapper">
            <Navigation isVisibleSidebar={isVisibleSidebar} />
            <div className="main">
                <NavbarComponent onToggleNavigation={handleToggleSidebar} />
                <div className="content">
                    <DashboardRoutes {...routingProps} />
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;

/*
 * Navigation
 */
const Navigation: FunctionComponent<{ isVisibleSidebar: boolean }> = ({ isVisibleSidebar }) => {
    return (
        <GetSidebarInfoComponent>
            {(result) => {
                let lateOrdersCount = result?.data?.lateOrdersCount ?? 0;
                let unshippedOrdersCount = result?.data?.unshippedOrdersCount ?? 0;
                return (
                    <Sidebar
                        lateOrdersCount={lateOrdersCount}
                        unshippedOrdersCount={unshippedOrdersCount}
                        isVisibleSidebar={isVisibleSidebar}
                    />
                );
            }}
        </GetSidebarInfoComponent>
    );
};
