import { FunctionComponent } from 'react';
import { Field, getIn } from 'formik';
import cn from 'classnames';
import FormError from './FormError';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { isNil } from 'lodash';

export const arrayToOptions = (array: string[]) =>
    array.map((o) => ({
        value: o,
        label: o,
    }));
export interface FormSelectItem {
    value: string;
    label: string;
}
export interface FormSelectProps extends StateManagerProps {
    name: string;
    options: FormSelectItem[];
    className?: string;
    disabled?: boolean;
    hidden?: boolean;
}

const FormSelect: FunctionComponent<FormSelectProps> = (props) => {
    const { name, options, disabled, hidden, className, ...rest } = props;

    return (
        <Field name={name}>
            {({ field, form }) => {
                const isInvalid = getIn(form.touched, name) && !!getIn(form.errors, name);
                const classes = cn({
                    'expando-form-select': true,
                    invalid: isInvalid,
                    hidden: hidden,
                    [className]: !isNil(className),
                });
                // react-select needs value to be in FormSelectItem format
                field.value = options?.find((o) => o.value === field.value);

                return (
                    <>
                        <Select
                            {...rest}
                            {...field}
                            isDisabled={disabled ?? false}
                            className={classes}
                            classNamePrefix="expando-select"
                            options={options as any}
                            onChange={(selected: any) => {
                                form.setFieldTouched(name);
                                form.handleChange({ currentTarget: { name, value: selected.value } });
                                props.onChange(selected, null);
                            }}
                        />
                        <FormError name={name} isInvalid={isInvalid} />
                    </>
                );
            }}
        </Field>
    );
};
FormSelect.defaultProps = {
    onChange: () => {},
    hidden: false,
    disabled: false,
};

export default FormSelect;
