import Logo from './logo/ExpandoLogo';
import LogoXSymbol from './LogoXSymbol';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'reactstrap';
import { BarChart, List, Users } from 'react-feather';
import React from 'react';
import SlidersIcon from 'react-feather/dist/icons/sliders';
import SettingsIcon from 'react-feather/dist/icons/settings';
import TruckIcon from 'react-feather/dist/icons/truck';
import ProductsIcon from 'react-feather/dist/icons/tag';
import { useContext, useState } from 'react';
import { MarketplacesContext } from '../Contexts/MarketplacesContext';
import { IUserContext, UserContext } from 'src/Contexts/User/UserContext';
import { paths } from 'src/routing/paths';

import './Sidebar.scss';

export function Sidebar({
    lateOrdersCount = 0,
    unshippedOrdersCount = 0,
    isVisibleSidebar,
}: {
    lateOrdersCount: number;
    unshippedOrdersCount: number;
    isVisibleSidebar: boolean;
}) {
    const { hasAmazon } = useContext(MarketplacesContext);
    const { user } = useContext<IUserContext>(UserContext);

    const [isProductsSubmenuOpen, setIsProductsSubmenuOpen] = useState(false);
    const [isSettingsSubmenuOpen, setIsSettingsSubmenuOpen] = useState(false);

    const navClasses = ({ isActive }: { isActive: boolean }): string => {
        return cn({
            'sidebar-link': true,
            active: isActive,
        });
    };

    let isVisibleSidebarClass = isVisibleSidebar ? 'full' : 'partial';
    if (user && !user.isSuperadmin) {
        let lateUnshippedOrdersCountTruckColorClass: string = lateOrdersCount
            ? undefined
            : unshippedOrdersCount
            ? 'text-primary'
            : undefined;
        let lateUnshippedOrdersCountTruckColor: string = lateOrdersCount
            ? 'red'
            : unshippedOrdersCount
            ? undefined
            : 'white';
        let lateUnshippedOrdersCountBadgeColor: string = lateOrdersCount
            ? '#f44455'
            : unshippedOrdersCount
            ? '#f36f21'
            : '#fff';

        return (
            <nav id="menuSidebar" className={'sidebar ' + isVisibleSidebarClass + ' sidebar-sticky'}>
                <div className="sidebar-content">
                    <div className="scrollbar-container ps">
                        <a className="sidebar-brand" href="/eve">
                            <Logo />
                            <LogoXSymbol />
                        </a>
                        <ul className="sidebar-nav ml-0">
                            <li className="sidebar-item">
                                <NavLink to={paths.eve.dashboard} className={navClasses}>
                                    <SlidersIcon size={18} />
                                    <span className="label">
                                        <Trans>general.dashboard</Trans>
                                    </span>
                                </NavLink>
                            </li>
                            {hasAmazon ? (
                                <li className={'sidebar-item ' + isProductsSubmenuOpen ? 'active' : ''}>
                                    <span
                                        data-toggle="collapse"
                                        className="sidebar-link collapsed"
                                        onClick={() => setIsProductsSubmenuOpen(!isProductsSubmenuOpen)}
                                        aria-expanded="false"
                                    >
                                        <ProductsIcon size={18} />
                                        <span className="label">
                                            <Trans>general.products</Trans>
                                        </span>
                                    </span>
                                    <Collapse isOpen={isProductsSubmenuOpen}>
                                        <ul id="item" className="sidebar-dropdown list-unstyled">
                                            <li className="sidebar-item">
                                                <NavLink to={paths.eve.products} className={navClasses}>
                                                    <span className="abbrv">
                                                        <Trans>general.allProductsAbbrv</Trans>
                                                    </span>
                                                    <span className="label">
                                                        <Trans>general.allProducts</Trans>
                                                    </span>
                                                </NavLink>
                                            </li>

                                            <li className="sidebar-item">
                                                <NavLink to={paths.eve.autopricer} className={navClasses}>
                                                    <span className="abbrv">
                                                        <Trans>general.autopricerAbbrv</Trans>
                                                    </span>
                                                    <span className="label">
                                                        <Trans>general.autopricer</Trans>
                                                    </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Collapse>
                                </li>
                            ) : (
                                <li className="sidebar-item">
                                    <NavLink to={paths.eve.products} className={navClasses}>
                                        <ProductsIcon size={18} />
                                        <span className="label">
                                            <Trans>general.products</Trans>
                                        </span>
                                    </NavLink>
                                </li>
                            )}

                            <li className="sidebar-item">
                                <NavLink to={paths.eve.marketplaces} className={navClasses}>
                                    <FontAwesomeIcon icon={faExchangeAlt} fixedWidth className={'fa-rotate-90'} />
                                    <span className="label">
                                        <Trans>general.marketplaces</Trans>
                                    </span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink to={paths.eve.orders} className={navClasses}>
                                    <TruckIcon
                                        size={18}
                                        className={`align-middle ${lateUnshippedOrdersCountTruckColorClass}`}
                                        color={lateUnshippedOrdersCountTruckColor}
                                    />
                                    <span className="label">
                                        <Trans>general.orders</Trans>
                                        {lateOrdersCount || unshippedOrdersCount ? (
                                            <Badge
                                                className={'ml-2'}
                                                style={{ backgroundColor: lateUnshippedOrdersCountBadgeColor }}
                                                tag="small"
                                            >
                                                {lateOrdersCount + unshippedOrdersCount}
                                            </Badge>
                                        ) : null}
                                    </span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink to={paths.eve.ordersStatistics} className={navClasses}>
                                    <BarChart size={18} />
                                    <span className="label">
                                        <Trans>general.statistics</Trans>
                                    </span>
                                </NavLink>
                            </li>

                            <li className={'sidebar-item ' + isSettingsSubmenuOpen ? 'active' : ''}>
                                <span
                                    data-toggle="collapse"
                                    className="sidebar-link collapsed"
                                    onClick={() => setIsSettingsSubmenuOpen(!isSettingsSubmenuOpen)}
                                    aria-expanded="false"
                                >
                                    <SettingsIcon size={18} />
                                    <span className="label">
                                        <Trans>general.settings</Trans>
                                    </span>
                                </span>
                                <Collapse isOpen={isSettingsSubmenuOpen}>
                                    <ul id="item" className="sidebar-dropdown list-unstyled">
                                        <li className="sidebar-item">
                                            <NavLink to={paths.eve.productFeed} className={navClasses}>
                                                <span className="abbrv">
                                                    <Trans>general.productsSourceAbbrv</Trans>
                                                </span>
                                                <span className="label">
                                                    <Trans>general.productsSource</Trans>
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={paths.eve.syncSettings} className={navClasses}>
                                                <span className="abbrv">
                                                    <Trans>general.generalAbbrv</Trans>
                                                </span>
                                                <span className="label">
                                                    <Trans>general.general</Trans>
                                                </span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapse>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    } else if (user && user.isSuperadmin) {
        return (
            <nav className={'sidebar ' + isVisibleSidebarClass + ' sidebar-sticky'}>
                <div className="sidebar-content">
                    <div className="scrollbar-container ps">
                        <a className="sidebar-brand" href="/eve">
                            <Logo />
                            <LogoXSymbol />
                        </a>
                        <ul className="sidebar-nav ml-0">
                            <li className="sidebar-item">
                                <NavLink to={paths.eve.stats} className={navClasses}>
                                    <List size={18} />
                                    <span className="label">
                                        <Trans>general.overview</Trans>
                                    </span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink to={paths.eve.adminStatistics} className={navClasses}>
                                    <BarChart size={18} />
                                    <span className="label">
                                        <Trans>general.statistics</Trans>
                                    </span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink to={paths.eve.usersTable} className={navClasses}>
                                    <Users size={18} />
                                    <span className="label">
                                        <Trans>general.accounts</Trans>
                                    </span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink to={paths.eve.orders} className={navClasses}>
                                    <TruckIcon size={18} />
                                    <span className="label">
                                        <Trans>general.orders</Trans>
                                    </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    } else {
        return (
            <nav className={'sidebar ' + isVisibleSidebarClass + ' sidebar-sticky'}>
                <div className="sidebar-content">
                    <div className="scrollbar-container ps">
                        <a className="sidebar-brand" href="/eve">
                            <Logo />
                            <LogoXSymbol />
                        </a>
                        <ul className="sidebar-nav ml-0" />
                    </div>
                </div>
            </nav>
        );
    }
}
