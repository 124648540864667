import ConnectMarketplace from '../ConnectMarketplaceNew';
import ConnectCDiscountForm, { createValidationSchema } from './ConnectCDiscountForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectMarketplaceWizard, { ConnectMarketplaceWizardProps } from '../ConnectMarketplaceWizard';
import ConnectMarketplaceHeader from '../ConnectMarketplaceHeader';
import { MarketplaceRegion } from 'src/graphql/generated';
import { ConnectCDiscountStepBloc } from './ConnectCDiscountStepBloc';
import { ConnectMarketplaceWizardBloc } from '../ConnectMarketplaceWizardBloc';

interface ConnectCDiscountOrchestratorProps {
    region: MarketplaceRegion;
    showWizardComponents: boolean;
}

export const ConnectCDiscountOrchestrator: FunctionComponent<ConnectCDiscountOrchestratorProps> = ({
    region,
    showWizardComponents,
}) => {
    const { t } = useTranslation();
    const parentBloc = new ConnectMarketplaceWizardBloc(1, region, t);
    const step1Bloc = new ConnectCDiscountStepBloc(region, parentBloc, null, t);
    return (
        <ConnectCDiscount
            region={region}
            bloc={parentBloc}
            stepBlocs={[step1Bloc]}
            i18nPrefix="connectCDiscount"
            showWizardComponents={showWizardComponents}
        />
    );
};

const ConnectCDiscount: FunctionComponent<ConnectMarketplaceWizardProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ConnectMarketplaceWizard {...props}>
            <ConnectMarketplace
                header={ConnectMarketplaceHeader}
                form={ConnectCDiscountForm}
                validationSchema={createValidationSchema(t)}
            />
        </ConnectMarketplaceWizard>
    );
};
export default ConnectCDiscount;
