import { FunctionComponent } from 'react';
import cx from 'classnames';
import { Spinner } from 'reactstrap';

import './Loader.scss';

export interface LoaderProps {
    /**
     * - full width of parent
     * - full height of parent
     * - centered content
     * - overlay
     */
    fullpage?: boolean;
    text?: string;
    /**
     * - full width of parent
     * - centered content
     */
    centered?: boolean;
}

const Loader: FunctionComponent<LoaderProps> = ({ fullpage, text, centered }) => {
    const classes = cx({
        'la-overlay': true,
        fullpage: fullpage,
        centered: centered,
    });

    return (
        <div className={classes}>
            <Spinner />
            {text && <small>{text}</small>}
        </div>
    );
};
Loader.defaultProps = {
    fullpage: false,
    centered: false,
};

export default Loader;
