import { FunctionComponent } from 'react';
import { Button } from 'src/ui';
import { useTranslation } from 'src/hooks/useTranslation';
import { NavLink } from 'react-router-dom';
import { paths } from 'src/routing/paths';

export const LoginFooter: FunctionComponent = () => {
    const { t } = useTranslation({ i18nPrefix: 'auth.login' });

    return (
        <footer>
            {t('footer.noAccount')}
            <NavLink to={paths.auth.registration}>
                <Button color="secondary" text={t('footer.createAccount')} />
            </NavLink>
        </footer>
    );
};
