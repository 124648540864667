import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks/useTranslation';
import { Button } from 'src/ui';

import './ErrorPage.scss';

export interface ErrorPageProps {
    code: number;
    title: string;
    message: string;
    desc?: string;
}

export const ErrorPage: FunctionComponent<ErrorPageProps> = ({ code, title, message, desc }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const returnUrl = (state as any)?.returnUrl;

    return (
        <div className="error-page">
            <div className="code">{code}</div>
            <h1>{title}</h1>
            <p>{message}</p>
            {desc && <p>{desc}</p>}
            <p>
                <span className="location">{returnUrl}</span>
            </p>
            {returnUrl && (
                <Button text={t('btn.tryAgain')} onClick={() => navigate(returnUrl, { state: { returnUrl } })} />
            )}
        </div>
    );
};
