import { MutationOptions } from '@apollo/client';
import { UnexpectedError } from 'src/errors';
import {
    CustomProductFeedDocument,
    CustomProductFeedInput,
    CustomProductFeedQuery,
    EFeedSetupState,
    FeedPropsFragment,
    GetFeedSummaryDocument,
    GetFeedSummaryQuery,
    GetFeedSummaryQueryVariables,
    SaveCustomFeedDocument,
    SaveCustomFeedMutation,
    SaveCustomFeedMutationVariables,
    ValidateProductFeedField,
    ValidateProductFeedFieldDocument,
    ValidateProductFeedFieldMutation,
    ValidateProductFeedFieldMutationVariables,
    ValidationFieldTypeEnum,
} from '../../graphql/generated';
import { RequestContext, Service } from '../Service';
import { getStatusLikeError } from '../ServiceUtils';

export type FeedResult = FeedPropsFragment;
export type FeedSummaryResult = GetFeedSummaryQuery;
export type SaveFeedRequest = Partial<CustomProductFeedInput>;
export type ValidateFieldResult = {
    key: string;
    type: ValidationFieldTypeEnum;
    validations: Pick<ValidateProductFeedField, 'value' | 'parsedValue' | 'status'>[];
};

export class FeedService extends Service {
    /**
     * Get feed data.
     * @returns true/false
     */
    async get(): Promise<FeedResult> {
        let response = await this.query<CustomProductFeedQuery>(
            { query: CustomProductFeedDocument },
            { method: 'getFeed' },
        );

        return (response?.customProductFeed as FeedResult) ?? null;
    }

    async getSummary(): Promise<FeedSummaryResult> {
        const response = await this.query<GetFeedSummaryQuery, GetFeedSummaryQueryVariables>(
            {
                query: GetFeedSummaryDocument,
                fetchPolicy: 'no-cache',
            },
            { method: 'getFeedSummary' },
        );
        if (response?.productFeedResult?.error) {
            throw new Error(response?.productFeedResult?.error);
        }
        return response;
    }

    /**
     * Saves feed properties
     * @param feed
     * @returns
     */
    async save(feed: SaveFeedRequest, step: EFeedSetupState): Promise<FeedResult> {
        const request: MutationOptions<SaveCustomFeedMutation> = {
            mutation: SaveCustomFeedDocument,
            variables: { feed, step },
        };
        const context: RequestContext = {
            method: 'saveFeed',
            params: request.variables,
        };
        const response = await this.mutate<SaveCustomFeedMutation, SaveCustomFeedMutationVariables>(request, context);

        return response?.setCustomProductFeed ?? null;
    }

    async validateField(key: string, type: ValidationFieldTypeEnum, values: string[]): Promise<ValidateFieldResult> {
        const request: MutationOptions<ValidateProductFeedFieldMutation> = {
            mutation: ValidateProductFeedFieldDocument,
            variables: { type, values },
        };
        const context: RequestContext = {
            method: 'validateField',
            params: request.variables,
        };
        const response = await this.mutate<ValidateProductFeedFieldMutation, ValidateProductFeedFieldMutationVariables>(
            request as any,
            context,
        ); //TODO MSL fix any

        const result = response?.validateProductFeedField ?? null;
        if (!result) {
            throw new UnexpectedError('no data', context);
        }

        return {
            key,
            type,
            validations: result,
        };
    }
}
