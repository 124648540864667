import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import {
    EnumMarketplaceStatus,
    MarketplaceName,
    MarketplaceRegion,
    Platform,
    UpdateMwsMarketplaceInputStatus,
} from '../graphql/generated';
import { ServiceProvider } from '../services/ServiceProvider';
import { IUserContext, UserContext } from './User/UserContext';
import LoadingPage from '../screens/LoadingPage';
import { GetMarketplacesResult } from 'src/services/marketplace/MarketplaceService';
import { platformFromMarketplace, platformFromMarketplaceRegion } from 'src/library/marketplace';
import { inList } from 'src/library/utils/ListUtils';

declare const SERVICES: ServiceProvider;

export interface IMarketplacesContext {
    onlineMarketplaces: MarketplaceName[];
    marketplaceStatuses: GetMarketplacesResult[];
    hasAmazon: boolean;
    hasConnectedMarketplace: boolean;
    getOnlineMarketplacesCountByRegions: (regions: MarketplaceRegion[]) => number;
    getStatusByMarketplace: (marketplaceName: MarketplaceName) => GetMarketplacesResult;
    isMarketplaceConnected: (marketplace: MarketplaceName) => boolean;
    updateMarketplaceStatus: (marketplace: MarketplaceName, status: UpdateMwsMarketplaceInputStatus) => Promise<void>;
}

export const MarketplacesContext = React.createContext<IMarketplacesContext>(null);

export const MarketplacesContextProvider = ({ children }) => {
    const { user } = useContext<IUserContext>(UserContext);

    const [context, setContext] = useState<IMarketplacesContext>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const getContext = (statuses: GetMarketplacesResult[]) => {
        const context: IMarketplacesContext = {
            onlineMarketplaces: [],
            marketplaceStatuses: [],
            hasConnectedMarketplace: false,
            hasAmazon: false,
            getOnlineMarketplacesCountByRegions: (regions: MarketplaceRegion[]) =>
                statuses.filter((o) => inList(o.region, regions) && o.status === EnumMarketplaceStatus.online).length,
            getStatusByMarketplace: (marketplaceName: MarketplaceName) =>
                statuses.find((o) => o.marketplace === marketplaceName),
            isMarketplaceConnected: (marketplace: MarketplaceName) =>
                statuses.some((o) => o.marketplace === marketplace),
            // just wrapper to reload context if successfully updated
            updateMarketplaceStatus: (marketplace: MarketplaceName, status: UpdateMwsMarketplaceInputStatus) =>
                SERVICES.marketplace.updateMarketplaceStatus({ marketplace, status }).then((statuses) => {
                    setLoading(true);
                    setContext(getContext(statuses));
                    setLoading(false);
                }),
        };
        for (const marketplaceStatus of statuses) {
            if (marketplaceStatus.status !== EnumMarketplaceStatus.error) {
                context.hasConnectedMarketplace = true;
            }
            if (marketplaceStatus.status === EnumMarketplaceStatus.online) {
                context.onlineMarketplaces.push(marketplaceStatus.marketplace);
            }
            if (platformFromMarketplace(marketplaceStatus.marketplace) === Platform.amazon) {
                context.hasAmazon = true;
            }
        }

        return context;
    };

    useEffect(() => {
        if (user) {
            SERVICES.marketplace
                .getMarketplaces()
                .then((marketplaceStatuses) => setContext(getContext(marketplaceStatuses)))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    if (user && loading) {
        return <LoadingPage />;
    }

    return <MarketplacesContext.Provider value={context}>{children}</MarketplacesContext.Provider>;
};
