import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CopyCard from 'src/components/card/CopyCard';
import { ConnectFormProps } from '../ConnectMarketplaceNew';
import { useFormikContext } from 'formik';

export interface FormData {
    webhookUrl: string;
}

const ConnectMallStep2Form: FunctionComponent<ConnectFormProps> = ({ i18nPrefix }) => {
    const { t } = useTranslation('translation', { keyPrefix: i18nPrefix });

    const ThisCopyCard: FunctionComponent = () => {
        const { getFieldMeta } = useFormikContext();
        const { value } = getFieldMeta<string>('webhookUrl');
        return <CopyCard value={value} />;
    };

    return (
        <ol>
            <li>{t('li1')}</li>
            <li>{t('li2')}</li>
            <ThisCopyCard />
            <li>{t('li3')}</li>
        </ol>
    );
};

export default ConnectMallStep2Form;
