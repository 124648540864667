import * as PropTypes from 'prop-types';
import {
    EnumMarketplaceStatus,
    MarketplaceName,
    MarketplaceRegion,
    Platform,
    UpdateMwsMarketplaceInputStatus,
} from '../graphql/generated';

export const MarketplacePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    marketplace: PropTypes.string.isRequired,
    formula: PropTypes.shape({
        add: PropTypes.number.isRequired,
        multiply: PropTypes.number.isRequired,
    }).isRequired,
    currentListingStatistics: PropTypes.shape({
        ok: PropTypes.number,
        error: PropTypes.number,
    }),
});

export const parseMarketplaceName = (marketplace: MarketplaceName): { platform: Platform; countryCode: string } => {
    if (!marketplace) return null;

    return {
        platform: platformFromMarketplace(marketplace),
        countryCode: countryCodeFromMarketplaceName(marketplace),
    };
};

export const platformFromMarketplace = (marketplace: MarketplaceName): Platform => {
    if (!marketplace) return null;
    if (!MARKETPLACES.includes(marketplace)) {
        throw new Error(`'${marketplace}' doesn't seems to be a marketplace name`);
    }
    return marketplace.replace(/_[a-z]+$/i, '') as Platform;
};
export const platformFromMarketplaceRegion = (region: MarketplaceRegion): Platform => {
    if (!MARKETPLACE_REGIONS.includes(region)) {
        throw new Error(`'${region}' doesn't seems to be a marketplace region`);
    }
    return region.replace(/_[a-z]+$/i, '') as Platform;
};

export const regionFromMarketplace = (marketplace: MarketplaceName): MarketplaceRegion => {
    const platform = platformFromMarketplace(marketplace);
    if (platform === Platform.amazon) {
        switch (marketplace) {
            case MarketplaceName.amazon_br:
            case MarketplaceName.amazon_ca:
            case MarketplaceName.amazon_mx:
            case MarketplaceName.amazon_us:
                return MarketplaceRegion.amazon_america;
            case MarketplaceName.amazon_de:
            case MarketplaceName.amazon_uk:
            case MarketplaceName.amazon_fr:
            case MarketplaceName.amazon_it:
            case MarketplaceName.amazon_es:
            case MarketplaceName.amazon_nl:
            case MarketplaceName.amazon_se:
            case MarketplaceName.amazon_tr:
            case MarketplaceName.amazon_pl:
            case MarketplaceName.amazon_be:
                return MarketplaceRegion.amazon_europe;
            case MarketplaceName.amazon_sg:
                return MarketplaceRegion.amazon_far_east_sg;
            case MarketplaceName.amazon_au:
                return MarketplaceRegion.amazon_far_east_au;
            case MarketplaceName.amazon_jp:
                return MarketplaceRegion.amazon_far_east_jp;
        }
    }
    return marketplace as unknown as MarketplaceRegion; // TODO MarketplaceRegion is missing emag & mall
};

export const marketplacesFromRegion = (region: MarketplaceRegion): MarketplaceName[] => {
    switch (region) {
        case MarketplaceRegion.amazon_america:
            return [
                MarketplaceName.amazon_br,
                MarketplaceName.amazon_ca,
                MarketplaceName.amazon_mx,
                MarketplaceName.amazon_us,
            ];
        case MarketplaceRegion.amazon_europe:
            return [
                MarketplaceName.amazon_de,
                MarketplaceName.amazon_uk,
                MarketplaceName.amazon_fr,
                MarketplaceName.amazon_it,
                MarketplaceName.amazon_es,
                MarketplaceName.amazon_nl,
                MarketplaceName.amazon_se,
                MarketplaceName.amazon_tr,
                MarketplaceName.amazon_pl,
                MarketplaceName.amazon_be,
            ];
        case MarketplaceRegion.amazon_far_east_sg:
            return [MarketplaceName.amazon_sg];
        case MarketplaceRegion.amazon_far_east_au:
            return [MarketplaceName.amazon_au];
        case MarketplaceRegion.amazon_far_east_jp:
            return [MarketplaceName.amazon_jp];
        default:
            return [region] as unknown as MarketplaceName[];
    }
};

export const getMarketplaceNamesForPlatform = (platform: Platform): MarketplaceName[] => {
    return MARKETPLACES.filter((o) => o.startsWith(platform)) as MarketplaceName[];
};
export const getMarketplaceRegionsForPlatform = (platform: Platform): MarketplaceRegion[] => {
    return MARKETPLACE_REGIONS.filter((o) => o.startsWith(platform)) as MarketplaceRegion[];
};

export const countryCodeFromMarketplaceName = (marketplace: MarketplaceName) => {
    let split = marketplace.split('_');
    return split && split.length && split[split.length - 1].toUpperCase();
};
export const countryCodeFromMarketplaceNames = (marketplaces: MarketplaceName[]) =>
    marketplaces.map((o) => countryCodeFromMarketplaceName(o));

export const countryCodeFromMarketplaceRegion = (region: MarketplaceRegion): string[] => {
    const split = region.split('_');
    const possibleCountryCode = split?.[split.length - 1] ?? '';
    const customMapping: { [key: string]: string[] } = {
        [MarketplaceRegion.amazon_america]: ['us', 'ca', 'mx', 'br'],
        [MarketplaceRegion.amazon_europe]: ['de', 'uk', 'es', 'fr', 'it', 'nl', 'be'],
    };
    const countryCodes = customMapping[region] ?? [possibleCountryCode];
    return countryCodes.map((o) => o.toUpperCase());
};

export const toggleStatus = (
    status: UpdateMwsMarketplaceInputStatus | EnumMarketplaceStatus,
): UpdateMwsMarketplaceInputStatus & EnumMarketplaceStatus => {
    // @ts-ignore to avoid verbose typing
    return status === 'offline' ? 'online' : 'offline';
};

export function isAmazon(code): boolean {
    if (code == null || typeof code !== 'string') return false;
    return code.startsWith('amazon');
}

export function isKaufland(code): boolean {
    if (code == null || typeof code !== 'string') return false;
    return code.startsWith('kaufland');
}

export function supportBulkBlock(code): boolean {
    return isAmazon(code) || isKaufland(code);
}

export function isAmazonFarEast(region: MarketplaceRegion): boolean {
    return AMAZON_FAR_EAST_REGIONS.includes(region);
}

export function getMarketplaceUrl(marketplace) {
    return marketplaceUrl(marketplace, 'www');
}

export function getMarketplaceOrderUrl(marketplace) {
    return marketplaceUrl(marketplace, 'sellercentral');
}

const domainTranslation = {
    uk: 'co.uk',
    us: 'com',
    be: 'com.be',
};

function marketplaceUrl(marketplace, prefix) {
    const code = /amazon_(.+)/.exec(marketplace)?.[1];
    if (!code) return null;
    const domain = domainTranslation[code] ?? code;
    return `https://${prefix}.amazon.${domain}`;
}

export function getUrlOfAmazonPage(marketplace, asin, type) {
    if (!isAmazon(marketplace) || asin == null || asin === '') return null;
    let marketplaceUrl = getMarketplaceUrl(marketplace);
    if (marketplaceUrl == null) return null;
    if (type === 'product') {
        return marketplaceUrl + '/dp/' + asin;
    } else if (type === 'sellers') {
        return marketplaceUrl + '/gp/offer-listing/' + asin + '/ref=dp_olp_new?ie=UTF8&condition=new';
    } else return null;
}

export const PLATFORMS: Platform[] = Object.keys(Platform)
    .map((o) => Platform[o])
    .sort();
export const MARKETPLACES: MarketplaceName[] = Object.keys(MarketplaceName).map((o) => MarketplaceName[o]);
export const MARKETPLACE_REGIONS: MarketplaceRegion[] = Object.keys(MarketplaceRegion).map((o) => MarketplaceRegion[o]);
export const AMAZON_FAR_EAST_REGIONS = [
    MarketplaceRegion.amazon_far_east_au,
    MarketplaceRegion.amazon_far_east_jp,
    MarketplaceRegion.amazon_far_east_sg,
];
