import { FunctionComponent } from 'react';
import AuthLayout from '../AuthLayout';
import { RegistrationFooter } from './RegistrationFooter';
import { RegistrationForm } from './RegistrationForm';
import { AuthWithShoptet } from '../AuthWith/AuthWithShoptet';
import { useTranslation } from 'src/hooks/useTranslation';
import { RegistrationBloc } from './RegistrationBloc';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';

export interface RegistrationProps {
    bloc: IAbstractBloc;
}
export const Registration: FunctionComponent<RegistrationProps> = ({ bloc }) => {
    return (
        <AuthLayout
            type="registration"
            bloc={bloc}
            authWithCards={[AuthWithShoptet]}
            content={RegistrationForm}
            footer={RegistrationFooter}
        />
    );
};

export const RegistrationOrchestrator: FunctionComponent = () => {
    const { t } = useTranslation();
    return <Registration bloc={new RegistrationBloc({ state: 'empty' }, t)} />;
};
