import { FunctionComponent } from 'react';
import { MarketplaceName, MarketplaceRegion } from 'src/graphql/generated';
import {
    countryCodeFromMarketplaceNames,
    MARKETPLACE_REGIONS,
    platformFromMarketplaceRegion,
    regionFromMarketplace,
} from 'src/library/marketplace';
import { Button, Flag } from 'src/ui';
import { Link } from 'react-router-dom';
import { paths } from 'src/routing/paths';
import { RouteUtils } from 'src/utils/RouteUtils';
import { AppLogger } from 'src/logger/AppLogger';
import { ESegmentEvent } from 'src/library/segment';
import { Badge } from 'reactstrap';
import { Hero, HeroContent, HeroFooter, HeroHeader } from 'src/ui';
import { useTranslation } from 'src/hooks/useTranslation';
import { inList } from 'src/library/utils/ListUtils';

declare const LOGGER: AppLogger;

interface ConnectMarketplaceHeroProps {
    name: string;
    marketplaces: MarketplaceName[];
    dataSource?: string;
}

const ConnectMarketplaceHero: FunctionComponent<ConnectMarketplaceHeroProps> = ({ name, marketplaces, dataSource }) => {
    const { t, exists } = useTranslation();
    const countryCodes = countryCodeFromMarketplaceNames(marketplaces);
    const hasBadge = exists(`marketplaceHero.badge.${name}`);
    const titleI18nPrefix = marketplaces.length === 1 ? 'marketplaceName' : 'region';
    const region = inList(name, MARKETPLACE_REGIONS)
        ? (name as MarketplaceRegion)
        : regionFromMarketplace(name as MarketplaceName);
    const connectPath = RouteUtils.format(
        paths.onboarding.connectMarketplacesRegion,
        { ':region': region },
        { source: dataSource },
    );

    const handleLearnMore = () => window.open(t(`marketplaceHero.learnMoreLink.${name}`), '_blank');

    const handleConnect = () => {
        LOGGER.track({
            event: ESegmentEvent.MARKETPLACE_SELECTED,
            platform: platformFromMarketplaceRegion(region),
            region,
            dataSource,
        });
    };

    return (
        <Hero>
            <HeroHeader>
                {t(titleI18nPrefix + '.' + name)}
                <div className="flags-wrapper">
                    {countryCodes.map((o) => (
                        <Flag key={o} code={o} size="small" rounded />
                    ))}
                </div>
            </HeroHeader>
            <HeroContent>
                <p>{t(`marketplaceHero.paragraph.${name}`)}</p>
            </HeroContent>
            <HeroFooter>
                <Link to={connectPath}>
                    <Button
                        id={`marketplace-connection-card-${name}`}
                        text={t('marketplaceHero.btn.connect')}
                        color="primary"
                        onClick={handleConnect}
                    />
                </Link>
                <Button
                    id={`marketplace-connection-card-${name}-helpLink`}
                    text={t('marketplaceHero.btn.learnMore')}
                    onClick={handleLearnMore}
                    color="link"
                />
                {hasBadge && <Badge color="danger">{t(`marketplaceHero.badge.${name}`)}</Badge>}
            </HeroFooter>
        </Hero>
    );
};

export default ConnectMarketplaceHero;
