import React, { Fragment, FunctionComponent, InputHTMLAttributes } from 'react';
import { Field, getIn } from 'formik';
import cn from 'classnames';
import FormError from './FormError';
export interface FormInputProps extends InputHTMLAttributes<any> {
    onChangeExtra?: (e) => void;
}

const FormInput: FunctionComponent<FormInputProps> = (props) => {
    const { name, className, onChangeExtra, ...rest } = props;

    return (
        <div className={`expando-form-control ${className ?? ''}`}>
            <Field name={name}>
                {({ field, form }) => {
                    const isInvalid = getIn(form.touched, name) && !!getIn(form.errors, name);
                    const classes = cn({
                        'expando-form-input': true,
                        invalid: isInvalid,
                    });
                    if (onChangeExtra) {
                        const onChangeOriginal = field.onChange;
                        field.onChange = (e) => {
                            onChangeOriginal(e);
                            onChangeExtra(e);
                        };
                    }
                    return (
                        <Fragment>
                            <input {...field} {...rest} className={classes} />
                            <FormError name={name} isInvalid={isInvalid} />
                        </Fragment>
                    );
                }}
            </Field>
        </div>
    );
};
FormInput.defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
    onChangeExtra: null,
};

export default FormInput;
