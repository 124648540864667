import { TFunction } from 'i18next';
import { MarketplaceRegion } from 'src/graphql/generated';
import { ConfirmationModalStateData } from './ConfirmationModal';
import { ModalBloc } from './ModalBloc';

export interface ConfirmationModalBlocData extends Omit<ConfirmationModalStateData, 'isOpen'> {}

export class ConfirmationModalBloc extends ModalBloc<ConfirmationModalBlocData, ConfirmationModalStateData> {
    showDisconnectMarketplace(region: MarketplaceRegion, name: string, onConfirm?: () => void, onCancel?: () => void) {
        this.next({
            isOpen: true,
            title: this.t('connectMarketplace.deleteAccount'),
            paragraphs: [this.t('connectMarketplace.disconnectMarketplaceNotification', { name })],
            cancelId: `diconnectMarketplace-${region}-cancel`,
            confirmId: `diconnectMarketplace-${region}-confirm`,
            onConfirm,
            onCancel,
        });
    }

    // TODO MSL - for second step there should be different text
    // - this one informs abount updating feed url but in seconds step element item is updated
    // - therefore we should inform accordingly
    showFeedMappingConfirmation(onConfirm?: () => void, onCancel?: () => void) {
        this.next({
            isOpen: true,
            title: this.t('feedMapping.confirmation.title'),
            paragraphs: [this.t('feedMapping.confirmation.message')],
            cancelId: 'feed-changes-canceled',
            confirmId: 'feed-changes-confirmed',
            onConfirm,
            onCancel,
        });
    }
}
