import { FunctionComponent } from 'react';
import { useTranslation } from 'src/hooks/useTranslation';
import AuthWithCard from './AuthWithCard';
import { paths } from 'src/routing/paths';
import { AuthType } from '../Form/AuthType';
import { Envelope } from 'src/ui/icon';
import { useNavigate } from 'react-router-dom';

export const AuthWithEmail: FunctionComponent<{ type: AuthType }> = ({ type }) => {
    const authWithType = type.startsWith('login') ? 'login' : 'registration';
    const { t } = useTranslation({ i18nPrefix: `auth.authWith.email.${authWithType}` });
    const navigate = useNavigate();

    return (
        <AuthWithCard
            t={t}
            onClick={() => navigate(paths.auth[authWithType])}
            logo={<Envelope style={{ fontSize: '1.5em' }} />}
        />
    );
};
