import { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { HasFormProps, HasI18nProps } from 'src/common/props';
import { FormikContextType, useFormikContext } from 'formik';
import { StateData } from 'src/library/bloc/StateData';
import FormInputLabeled from './FormInputLabeled';
import { FormSelectItem } from './FormSelect';

export interface FormStateData<S, FD>
    extends StateData<S, FD>,
        Partial<Pick<HasFormProps<FD>, 'initialErrors' | 'initialTouched' | 'onSubmit'>> {}

export type FormState = 'empty' | 'requesting' | 'requested' | 'incorrect' | 'invalid';

export interface SimpleFormProps extends HasI18nProps {
    // Fields per row
    fields: FieldOption[][];
    isSubmitting: boolean;
}

export interface SimpleFormInputProps extends HasI18nProps {
    name: string;
    tabIndex: number;
    isSubmitting: boolean;
}

export interface FieldOption {
    name: string;
    type?: 'text' | 'password' | 'date' | 'select';
    /**
     * Required if type === 'select'
     */
    options?: FormSelectItem[];
    /**
     * Large screen size, default: 6
     * Small screen is always 12
     */
    size?: 6 | 12;
    optional?: boolean;
    hintOnClick?: () => void;
    showIf?: (ctx: FormikContextType<any>) => boolean;
    disabledIf?: (ctx: FormikContextType<any>) => boolean;
}

const SimpleForm: FunctionComponent<SimpleFormProps> = ({ fields: rows, isSubmitting, t, exists }) => {
    let tabIndex = 1;

    const Field: FunctionComponent<FieldOption> = ({
        name,
        type,
        optional,
        options,
        hintOnClick,
        showIf,
        disabledIf,
    }) => {
        const ctx = useFormikContext();
        if (showIf && !showIf(ctx)) return null;
        const explicitlyDisabled = disabledIf && disabledIf(ctx);

        return (
            <FormInputLabeled
                t={t}
                exists={exists}
                type={type}
                name={name}
                optional={optional}
                tabIndex={tabIndex++}
                disabled={isSubmitting || explicitlyDisabled}
                hintOnClick={() => hintOnClick && hintOnClick()}
                options={options}
            />
        );
    };

    const Cols: FunctionComponent<{ cols: FieldOption[] }> = ({ cols }) => {
        return (
            <>
                {cols.map((field) => {
                    return (
                        <Col key={`col-${field.name}`} xs="12" lg={field.size ?? 6} className="col">
                            <Field {...field} />
                        </Col>
                    );
                })}
            </>
        );
    };

    return (
        <>
            {rows.map((cols, idx) => (
                <Row key={`row-${idx}`}>
                    <Cols cols={cols}></Cols>
                </Row>
            ))}
        </>
    );
};
SimpleForm.defaultProps = {
    isSubmitting: false,
};

export default SimpleForm;
