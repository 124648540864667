import {
    GetShipmentInfoDocument,
    GetShipmentInfoQuery,
    GetShipmentInfoQueryVariables,
    ShipmentInfoItem,
} from 'src/graphql/generated';
import { Service } from '../Service';

export class ShipmentService extends Service {
    async getStatuses(shipmentNumbers: string[]): Promise<ShipmentInfoItem[]> {
        let result = await this.query<GetShipmentInfoQuery, GetShipmentInfoQueryVariables>(
            {
                query: GetShipmentInfoDocument,
                variables: { shipmentNumbers },
                fetchPolicy: 'cache-first',
            },
            {
                method: 'getShipmentInfo',
            },
        );

        return result?.getShipmentInfo?.shipments ?? [];
    }
}
