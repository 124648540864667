import { FunctionComponent } from 'react';
import { MarketplaceRegion, Platform } from 'src/graphql/generated';
import { marketplacesFromRegion, getMarketplaceRegionsForPlatform } from 'src/library/marketplace';
import ConnectMarketplaceHero from './ConnectMarketplaceHero';

import './MarketplaceHero.scss';

export interface ConnectMarketplaceSelectorProps {
    platform: Platform;
    dataSource?: string;
    ignore?: MarketplaceRegion[];
}

const ConnectMarketplaceSelector: FunctionComponent<ConnectMarketplaceSelectorProps> = ({
    platform,
    dataSource,
    ignore,
}) => {
    const regions = getMarketplaceRegionsForPlatform(platform);

    return (
        <div className="marketplace-hero-container">
            {regions
                .filter((region) => !ignore.includes(region))
                .map((region) => (
                    <ConnectMarketplaceHero
                        key={region}
                        name={region}
                        marketplaces={marketplacesFromRegion(region)}
                        dataSource={dataSource}
                    />
                ))}
        </div>
    );
};
ConnectMarketplaceSelector.defaultProps = {
    ignore: [],
};

export default ConnectMarketplaceSelector;
