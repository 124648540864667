import { TFunction } from 'i18next';
import { TFunction as TFunction2 } from 'src/hooks/useTranslation';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OperationFailedError, SpecificError, UnavailableServiceError } from 'src/errors';
import { isBlocDebugAllowed } from 'src/utils/DebugUtils';
import { BlocLogger } from './BlocLogger';
import { State } from './StateData';

export interface IAbstractBloc {
    start(): void;
    subscribe(set: (data: any) => void): Subscription;
    getInitialState(): any;
}

/**
 * Note: This is so far second iteration how to approach business logic separation topic.
 *       Instead of using with react states consider separated business class that holds state and produces UI data on change
 */
export abstract class AbstractBloc<E, T> extends BehaviorSubject<T> implements IAbstractBloc {
    protected logger: BlocLogger = null;

    /**
     * Starts producing events.
     * Note: Sometimes when request is cached, event with data is fired before UI subscribes in time.
     *       Same goes for bloc constructor properties are sometimes not available at the time when produceEvent is handled
     */
    public abstract start(): void;
    /**
     * Defines 'bloc' name. Used in debugger to distinguish among bloc events.
     */
    public abstract getName(): string;

    public getInitialState(): T {
        return this.initialState;
    }

    public abstract produceEvent(action: E, isLoading: boolean, data: any, error: Error): void;

    protected initialState: T;
    protected t: TFunction | TFunction2;

    constructor(initialState: T, t: TFunction | TFunction2) {
        super(initialState);
        this.initialState = initialState;
        this.t = t;
        this.logger = new BlocLogger(this.getName(), isBlocDebugAllowed());
        this.logger.logInit('Initialized');
    }

    protected async makeRequest<D>(action: E, request: () => Promise<D>) {
        try {
            this.logger?.logRequest('producing data', { action, isLoading: true });
            this.produceEvent(action, true, null, null);
            const data = await request();
            this.logger?.logRequest('producing data', { action, isLoading: false, data });
            this.produceEvent(action, false, data, null);
        } catch (error) {
            this.logger?.logRequest('producing data', { action, isLoading: false, error });
            this.produceEvent(action, false, null, error);
        }
    }

    /**
     * Shortcut for producing error state
     * @param action
     * @param error
     */
    public produceError(action: E, error: Error): void {
        this.produceEvent(action, false, null, error);
    }

    protected getState(action: E, isLoading: boolean, data: any, error: Error): State | string {
        if (error) return 'incorrect';
        if (isLoading) return 'requesting';
        return 'requested';
    }

    protected getErrorMessage(error: Error): string {
        if (error instanceof UnavailableServiceError) {
            return this.t('error.serviceNotAvailable');
        }
        if (error instanceof OperationFailedError) {
            return this.t('error.operationFailed');
        }
        if (error instanceof SpecificError) {
            return this.t(`error.${error.code}`, error.meta);
        }
        return error?.message;
    }

    // just wrapper/shortcut with typed creation
    protected produceStateDataEvent<D extends T>(state: D): void {
        this.next(state);
    }

    /**
     * Wrapper over 'observable.next' just to add some debug logging.
     * @param value
     */
    next(value: T): void {
        this.logger?.logState('producing state', value);
        super.next(value);
    }
}
