import { TFunction } from 'i18next';
import { TFunction as TFunction2 } from 'src/hooks/useTranslation';
import * as yup from 'yup';

const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const INT_REGEX = /^\d+$/gi;
export class ValidationSchemas {
    static email(t: TFunction | TFunction2): yup.StringSchema {
        return (
            yup
                .string()
                // TODO check this vs our 'isEmail' func
                .email(t('validation.pattern'))
                .max(255, t('validation.max'))
                .required(t('validation.required'))
        );
    }

    static url(t: TFunction | TFunction2): yup.StringSchema {
        return (
            yup
                .string()
                .label('URL')
                // .url(t('error.pattern.url'))
                .matches(URL_REGEX, t('error.pattern.url'))
                .required()
        );
    }

    static integer(t: TFunction | TFunction2): yup.StringSchema {
        return yup
            .string()
            .matches(INT_REGEX, t('validation.integer')) // user reges since I cannot pass message to .number()
            .required(t('validation.required'));
    }

    static password(t: TFunction | TFunction2): yup.StringSchema {
        return yup
            .string()
            .required(t('validation.required'))
            .min(8, t('validation.min', { min: 8 }));
    }

    static passwordConfirm(t: TFunction | TFunction2): yup.StringSchema {
        return yup
            .string()
            .required(t('validation.required'))
            .oneOf([yup.ref('password')], 'Passwords does not match');
    }
}
