import { ApplicationCredentials, MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { FormData } from './ConnectAmazonForm';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { RouteUtils } from 'src/utils/RouteUtils';
import { amazonRegionsMapping, AmazonRegionsMappingVal, getAuthUrl, getRegistrationUrl } from './helpers';

declare const SERVICES: ServiceProvider;

export class ConnectAmazonBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectAmazonSpApiBloc';
    }

    public produceEvent(action: 'get', isLoading: boolean, data: any, error: Error) {
        const query = RouteUtils.parseQuery<{ error?: string; succ?: string }>();
        if (query.error) {
            error = new Error(query.error);
        }
        this.next({
            state: this.getStepState(action, isLoading, data, error, this.isDone),
            error: query.error ? this.t('connectAmazonSpApi.spApiFailed', { error: this.getErrorMessage(error) }) : '',
            success: query.succ ? this.t('connectAmazonSpApi.spApiSuccess') : '',
            data: this.getApplicationFormData(data),
            onOpen: () => {
                this.isDone = false;
                this.produceEvent(action, false, data, null);
            },
            onSave: () => {
                this.isDone = true;
                this.produceEvent(action, false, data, null);
            },
        });
    }

    protected onParentCompleted(data: MarketplaceCredentials): void {
        this.isDone = !!data;
        this.makeRequest('get', () => SERVICES.marketplace.getApplicationCredentials(this.region));
    }

    protected onPrevStepCompleted(): void {
        throw new Error('Method not implemented.');
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        throw new Error('Method not implemented.');
    }

    protected getApplicationFormData(data: ApplicationCredentials): FormData {
        const mapping: AmazonRegionsMappingVal = amazonRegionsMapping[this.region];
        return {
            authUrl: getAuthUrl(mapping),
            registrationUrl: getRegistrationUrl(mapping, data),
            marketplace: mapping.marketplace,
        };
    }
}
