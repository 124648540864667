import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { FormData } from './ConnectKauflandForm';

export class ConnectKauflandStepBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectKauflandStepBloc';
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            clientKey: data?.clientKey ?? '',
            secretKey: data?.secretKey ?? '',
        };
    }
}
