import { TFunction } from 'i18next';
import { MarketplaceRegion } from 'src/graphql/generated';
import { WizardBloc } from 'src/library/bloc';
import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ServiceProvider } from 'src/services/ServiceProvider';

declare const SERVICES: ServiceProvider;

export class ConnectMarketplaceWizardBloc extends WizardBloc<MarketplaceCredentials> {
    protected region: MarketplaceRegion;

    public getName(): string {
        return 'ConnectMarketplaceWizardBloc';
    }

    constructor(requiredConfirmedInits: number, region: MarketplaceRegion, t: TFunction) {
        super(requiredConfirmedInits, t);
        this.region = region;
    }

    protected onStart(): void {
        this.makeRequest('get', () => SERVICES.marketplace.getCredentials(this.region));
    }
}
