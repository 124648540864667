import { FunctionComponent } from 'react';
import { Collapse } from 'reactstrap';
import Loader from '../loader/Loader';

export interface CardContentProps {
    className?: string;
    collapsable?: boolean;
    isOpen?: boolean;
    isLoading?: boolean;
    showSeparator?: boolean;
}

const CardContent: FunctionComponent<CardContentProps> = (props) => {
    const { collapsable, isOpen, isLoading, showSeparator, className, children } = props;

    const Separator: FunctionComponent = () => {
        return showSeparator && <hr />;
    };

    const Content: FunctionComponent = () => {
        if (isLoading) {
            return (
                <>
                    <Separator />
                    <Loader fullpage />
                </>
            );
        }

        return (
            <>
                <Separator />
                <div className={`expando-card-content ${className}`}>{children}</div>
            </>
        );
    };

    if (collapsable) {
        return (
            <Collapse isOpen={isOpen}>
                <Content />
            </Collapse>
        );
    }

    return <Content />;
};
CardContent.defaultProps = {
    className: '',
    collapsable: false,
    showSeparator: false,
    isOpen: false,
    isLoading: false,
};

export default CardContent;
