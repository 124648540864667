import { FunctionComponent } from 'react';
import Icon, { IconMap } from 'src/ui/icon/Icon';
import { UISize } from 'src/ui/UISize';

import ShoptetLogo from '../../assets/logo/shoptet-logo.svg';
import AmazonLogo from '../../assets/logo/amazon-logo.svg';
import AlzaLogo from '../../assets/logo/alza_cz-logo.svg';
import MallLogo from '../../assets/logo/mall-group-logo.svg';
import EmagLogo from '../../assets/logo/emag-logo.svg';
import KauflandLogo from '../../assets/logo/kaufland-logo2.svg';
import GlamiLogo from '../../assets/logo/glami-logo-black.svg';
import CDiscountLogo from '../../assets/logo/cdiscount-logo.svg';
import EbayLogo from '../../assets/logo/ebay_logo.svg';

import './PlatformLogo.scss';

export const platformLogoMap: IconMap = {
    shoptet: { src: ShoptetLogo },
    amazon: { src: AmazonLogo },
    alza: { src: AlzaLogo },
    mall: { src: MallLogo },
    emag: { src: EmagLogo },
    real: { src: KauflandLogo },
    kaufland: { src: KauflandLogo },
    glami: { src: GlamiLogo },
    cdiscount: { src: CDiscountLogo },
    ebay: { src: EbayLogo },
};

type PlatformLogoType = keyof typeof platformLogoMap;
interface PlatformLogoProps {
    type: PlatformLogoType;
    size?: UISize;
}

const PlatformLogo: FunctionComponent<PlatformLogoProps> = ({ type, size }) => {
    return <Icon className={`platform-logo platform-logo-${size}`} {...platformLogoMap[type]} />;
};
PlatformLogo.defaultProps = {
    size: 'normal',
};

export default PlatformLogo;
