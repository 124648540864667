import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';

export function LoadingPage() {
    return (
        <Container>
            <Row className="justify-content-center mt-6">
                <Col className="col-auto">
                    <Spinner size="large" color="primary" />
                </Col>
            </Row>
        </Container>
    );
}
export default LoadingPage;
