import { useState } from 'react';

export interface UseToggleStateContext {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
}

export default function useToggleState(isInitialyOpened: boolean = false): UseToggleStateContext {
    const [isOpen, setIsOpen] = useState<boolean>(isInitialyOpened);

    const toggle = () => setIsOpen(!isOpen);
    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    return {
        isOpen,
        open,
        close,
        toggle,
    };
}
