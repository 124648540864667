import { Navigate, Route } from 'react-router-dom';
import { NotAuthorizedPage } from 'src/screens/error/NotAuthorizedPage';
import { NotFoundPage } from 'src/screens/error/NotFoundPage';
import { ServerErrorPage } from 'src/screens/error/ServerErrorPage';
import Page from './Page';
import { paths } from './paths';

export const sharedRoutes = (location: string) => [
    <Route
        key={paths.notFound}
        path={paths.notFound}
        element={
            <Page>
                <NotFoundPage />
            </Page>
        }
    />,
    <Route
        key={paths.notAuthorized}
        path={paths.notAuthorized}
        element={
            <Page>
                <NotAuthorizedPage />
            </Page>
        }
    />,
    <Route
        key={paths.serverError}
        path={paths.serverError}
        element={
            <Page>
                <ServerErrorPage />
            </Page>
        }
    />,
    <Route key="*" path="*" element={<Navigate to={paths.notFound} state={{ returnUrl: location }} />} />,
];
