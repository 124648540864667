import { UserRegistrationRes } from 'src/graphql/generated';
import { AbstractFormBloc } from 'src/library/bloc/AbstractFormBloc';
import { ESegmentEvent } from 'src/library/segment';
import { AppLogger } from 'src/logger/AppLogger';
import { SegmentLog } from 'src/logger/SegmentLogger';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { FormData } from './RegistrationForm';

declare const SERVICES: ServiceProvider;
declare const LOGGER: AppLogger;

export class RegistrationBloc extends AbstractFormBloc<any, FormData> {
    getName(): string {
        return 'RegistrationBloc';
    }

    getFormData(): FormData {
        return {
            email: '',
            password: '',
            phone: '',
            shopUrl: '',
        };
    }
    requestGetData(): Promise<any> {
        return Promise.resolve();
    }

    requestSetData(data: FormData): Promise<UserRegistrationRes> {
        return SERVICES.user.register(data);
    }

    responseSetData(data: UserRegistrationRes): void {
        const event: SegmentLog = {
            event: ESegmentEvent.USER_REGISTERED,
            email: data.email,
        };
        LOGGER.track(event);

        window.location.pathname = '/';
    }
}
