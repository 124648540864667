import { TFunction } from 'i18next';
import { Subject } from 'rxjs';

export abstract class ModalBloc<BLOC_DATA, STATE_DATA extends { isOpen: boolean }> extends Subject<STATE_DATA> {
    protected t: TFunction;

    constructor(t: TFunction) {
        super();
        this.t = t;
    }

    hide() {
        this.next({ isOpen: false } as any);
    }

    show(data: BLOC_DATA) {
        this.next({
            ...data,
            isOpen: true,
        } as any);
    }
}
