import { FunctionComponent, PropsWithChildren } from 'react';

import './Card.scss';

export interface CardProps extends PropsWithChildren<any> {
    className?: string;
}

const Card: FunctionComponent<CardProps> = ({ className, children }) => {
    return <section className={`expando-card ${className || ''}`}>{children}</section>;
};

export default Card;
