import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useToasts, Options, UpdateOptions } from 'react-toast-notifications';
import { Subject } from 'rxjs';

export type ToastWrapperData =
    | { action: 'add'; params: { content: ReactNode; options?: Options; callback?: (id: string) => void } }
    | { action: 'update'; params: { id: string; options?: UpdateOptions; callback?: (id: string) => void } }
    | { action: 'remove'; params: { id: string; callback?: (id: string) => void } }
    | { action: 'removeAll' };

export interface ToastWrapperProps {
    bloc: Subject<ToastWrapperData>;
}

const ToastWrapper: FunctionComponent<ToastWrapperProps> = ({ bloc }) => {
    const { addToast, updateToast, removeToast, removeAllToasts } = useToasts();

    useEffect(() => {
        const subscription = bloc.subscribe(handleAction);
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const handleAction = (payload: ToastWrapperData) => {
        switch (payload.action) {
            case 'add':
                addToast(payload.params.content, payload.params.options, payload.params.callback);
                break;
            case 'update':
                updateToast(payload.params.id, payload.params.options, payload.params.callback);
                break;
            case 'remove':
                removeToast(payload.params.id, payload.params.callback);
                break;
            case 'removeAll':
                removeAllToasts();
                break;
        }
    };

    return null;
};

export default ToastWrapper;
