import { Route } from 'react-router-dom';
import { MARKETPLACE_REGIONS, platformFromMarketplaceRegion } from '../library/marketplace';
import { MarketplaceRegion, Platform } from 'src/graphql/generated';
import { TFunction } from 'src/hooks/useTranslation';

import { NotFoundPage } from 'src/screens/error/NotFoundPage';

import { ConnectAlzaOrchestrator } from '../screens/Onboarding/ConnectMarketplace/Alza/ConnectAlza';
import { ConnectAmazonOrchestrator } from '../screens/Onboarding/ConnectMarketplace/Amazon/ConnectAmazon';
import { ConnectCDiscountOrchestrator } from 'src/screens/Onboarding/ConnectMarketplace/cDiscount/ConnectCDiscount';
import { ConnectEMagOrchestrator } from 'src/screens/Onboarding/ConnectMarketplace/eMag/ConnectEMag';
import { ConnectGlamiOrchestrator } from '../screens/Onboarding/ConnectMarketplace/Glami/ConnectGlami';
import { ConnectKauflandOrchestrator } from 'src/screens/Onboarding/ConnectMarketplace/Kaufland/ConnectKaufland';
import { ConnectMallOrchestrator } from 'src/screens/Onboarding/ConnectMarketplace/Mall/ConnectMall';
import { ConnectEbayOrchestrator } from 'src/screens/Onboarding/ConnectMarketplace/Ebay/ConnectEbay';
import React from 'react';

function getMarketplaceComponent(region: MarketplaceRegion, showWizardComponents: boolean, t: TFunction) {
    switch (platformFromMarketplaceRegion(region)) {
        case Platform.alza:
            return <ConnectAlzaOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.mall:
            return <ConnectMallOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.emag:
            return <ConnectEMagOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.real:
            return <ConnectKauflandOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.kaufland:
            return <ConnectKauflandOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.glami:
            return <ConnectGlamiOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.amazon:
            return <ConnectAmazonOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.cdiscount:
            return <ConnectCDiscountOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        case Platform.ebay:
            return <ConnectEbayOrchestrator region={region} showWizardComponents={showWizardComponents} />;
        default:
            return <NotFoundPage desc={t('error.pageNotFound.regionNotImplemented', { region })} />;
    }
}

export const marketplacesRoutes = (showWizardComponents: boolean, t: TFunction) =>
    MARKETPLACE_REGIONS.map((region) => (
        <Route key={region} path={region} element={getMarketplaceComponent(region, showWizardComponents, t)} />
    ));
