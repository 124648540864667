import { OperationFailedError, UnexpectedError } from 'src/errors';
import { RequestContext } from './Service';

export interface StatusLikeErrorOptions {
    /** name of property holding status-like values */
    statusProperty?: string;
    /** status-like values that will results to error */
    relatedStatuses?: string[];
    /** application logger context */
    context?: RequestContext;
}

const defaultOptions: StatusLikeErrorOptions = {
    statusProperty: 'status',
};

/**
 *
 * @param result
 * @returns
 */
export function getStatusLikeError<T>(result: T, options?: StatusLikeErrorOptions): Error {
    const { statusProperty, relatedStatuses, context } = { ...defaultOptions, ...options };

    if (!result) {
        return new UnexpectedError('no data', context);
    }

    const status: string = result[statusProperty];
    if (status && status.toLocaleLowerCase() !== 'ok' && (!relatedStatuses || relatedStatuses.includes(status))) {
        return new OperationFailedError(statusProperty, status, context);
    }

    return null;
}
