import { MarketplaceName, MarketplaceRegion } from 'src/graphql/generated';
import { ApplicationCredentials } from 'src/services/marketplace/MarketplaceService';
import { EnvUtils } from 'src/utils/EnvUtils';

export type EbayRegionsMappingKey =
    | MarketplaceRegion.ebay_de
    | MarketplaceRegion.ebay_us
    | MarketplaceRegion.ebay_uk
    | MarketplaceRegion.ebay_fr
    | MarketplaceRegion.ebay_pl
    | MarketplaceRegion.ebay_cz;

export type EbayRegionsMappingVal = {
    marketplace: MarketplaceName;
    apiUrl: string;
};

export const ebayRegionsMapping: { [key in EbayRegionsMappingKey]: EbayRegionsMappingVal } = {
    [MarketplaceRegion.ebay_us]: {
        marketplace: MarketplaceName.ebay_us,
        apiUrl: 'https://auth.sandbox.ebay.com',
    },
    [MarketplaceRegion.ebay_uk]: {
        marketplace: MarketplaceName.ebay_uk,
        apiUrl: 'https://auth.sandbox.ebay.com',
    },
    [MarketplaceRegion.ebay_cz]: {
        marketplace: MarketplaceName.ebay_cz,
        apiUrl: 'https://auth.sandbox.ebay.com',
    },
    [MarketplaceRegion.ebay_pl]: {
        marketplace: MarketplaceName.ebay_pl,
        apiUrl: 'https://auth.sandbox.ebay.com',
    },
    [MarketplaceRegion.ebay_fr]: {
        marketplace: MarketplaceName.ebay_fr,
        apiUrl: 'https://auth.sandbox.ebay.com',
    },
    [MarketplaceRegion.ebay_de]: {
        marketplace: MarketplaceName.ebay_de,
        apiUrl: 'https://auth.sandbox.ebay.com',
    },
};

export function getAuthUrl(mapping: EbayRegionsMappingVal) {
    return `${EnvUtils.getApiEndpoint()}/ebay/oauth2/code?service=${mapping.marketplace}`;
}

export function getRegistrationUrl(mapping: EbayRegionsMappingVal, credentials: ApplicationCredentials) {
    return 'https://signup.ebay.com/pa/crte';
}
