import {
    GetShopInfoDocument,
    GetShopInfoQuery,
    GetShopInfoQueryVariables,
    ShopInfo,
    ShoptetFullSyncDocument,
    ShoptetFullSyncMutation,
    ShoptetFullSyncMutationVariables,
} from 'src/graphql/generated';
import { Service } from '../Service';

export type GetInfoResult = Pick<ShopInfo, 'shopId' | 'hostname'>;

export class ShoptetService extends Service {
    async getInfo(): Promise<GetInfoResult> {
        const response = await this.query<GetShopInfoQuery, GetShopInfoQueryVariables>(
            { query: GetShopInfoDocument },
            { method: 'GetShopInfo' },
        );

        return response?.shopInfo ?? null;
    }

    async fullSync(): Promise<void> {
        const response = await this.mutate<ShoptetFullSyncMutation, ShoptetFullSyncMutationVariables>(
            { mutation: ShoptetFullSyncDocument },
            { method: 'ShoptetFullSync' },
        );

        if (!response.shoptetFullSync.success) {
            throw new Error(response.shoptetFullSync.error);
        }
    }
}
