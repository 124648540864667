import { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ValidationSchemas } from 'src/components/form/ValidationSchemas';
import ConnectMarketplaceForm, { FieldOption } from '../ConnectMarketplaceForm';
import { ConnectFormProps } from '../ConnectMarketplaceNew';

export interface FormData {
    supplierId: string;
    supplierBranchId: string;
    secretKey: string;
}

export const createValidationSchema = (t: TFunction) =>
    yup.object().shape({
        supplierId: ValidationSchemas.integer(t),
        supplierBranchId: ValidationSchemas.integer(t),
        secretKey: yup.string().required(t('error.required.general')),
    });

const fields: FieldOption[][] = [[{ name: 'supplierId' }], [{ name: 'supplierBranchId' }, { name: 'secretKey' }]];

const ConnectAlzaForm: FunctionComponent<ConnectFormProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <ConnectMarketplaceForm {...props} fields={fields} />
            <Row>
                <Col>{t('connectAlza.step1.p2')}</Col>
            </Row>
        </>
    );
};

export default ConnectAlzaForm;
