import ConnectMarketplace from '../ConnectMarketplaceNew';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectMarketplaceWizard, { ConnectMarketplaceWizardProps } from '../ConnectMarketplaceWizard';
import ConnectMarketplaceHeader from '../ConnectMarketplaceHeader';
import { MarketplaceRegion } from 'src/graphql/generated';
import { ConnectMarketplaceWizardBloc } from '../ConnectMarketplaceWizardBloc';
import { ConnectAmazonBloc } from './ConnectAmazonBloc';
import ConnectAmazonForm from './ConnectAmazonForm';

interface ConnectAmazonOrchestratorProps {
    region: MarketplaceRegion;
    showWizardComponents: boolean;
}

export const ConnectAmazonOrchestrator: FunctionComponent<ConnectAmazonOrchestratorProps> = ({
    region,
    showWizardComponents,
}) => {
    const { t } = useTranslation();

    const parentBloc = new ConnectMarketplaceWizardBloc(1, region, t);
    const stepBlocs = [new ConnectAmazonBloc(region, parentBloc, null, t)];
    return (
        <ConnectAmazon
            region={region}
            bloc={parentBloc}
            stepBlocs={stepBlocs}
            i18nPrefix={'connectAmazon'}
            showWizardComponents={showWizardComponents}
        />
    );
};

export const ConnectAmazon: FunctionComponent<ConnectMarketplaceWizardProps> = (props) => {
    return (
        <ConnectMarketplaceWizard {...props}>
            <ConnectMarketplace form={ConnectAmazonForm as any} header={ConnectMarketplaceHeader} />
        </ConnectMarketplaceWizard>
    );
};
