import { FunctionComponent, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Direction } from 'reactstrap/lib/Dropdown';
import { Tooltip } from 'react-tippy';
import cn from 'classnames';
import { ButtonColors } from './ExpandoButton';
import { random } from 'lodash';

import './OptionsButton.scss';

export interface OptionsButtonOption {
    value: string;
    label: string;
    disabled?: boolean;
    tooltip?: string;
    icon?: JSX.Element;
    iconPosition?: 'left' | 'right';
}
export interface OptionsButtonProps {
    text: string;
    color?: ButtonColors | 'transparent';
    icon?: JSX.Element;
    caret?: boolean;
    disabled?: boolean;
    direction?: Direction;
    className?: string;
    options: OptionsButtonOption[];
    RootRenderer?: FunctionComponent<OptionsButtonOption>;
    OptionRenderer?: FunctionComponent<OptionsButtonOption>;
    onSelection: (option: OptionsButtonOption) => void;
}

const Content: FunctionComponent<OptionsButtonOption> = ({ label, icon, iconPosition }) => {
    const hasIcon = !!icon;

    return (
        <>
            {iconPosition === 'left' && hasIcon && <span className="mr-2">{icon}</span>}
            {label}
            {iconPosition === 'right' && hasIcon && <span className="ml-2">{icon}</span>}
        </>
    );
};
Content.defaultProps = {
    iconPosition: 'left',
};

const OptionsButton: FunctionComponent<OptionsButtonProps> = (props) => {
    const {
        className,
        text,
        color,
        caret,
        disabled,
        direction,
        icon,
        options,
        RootRenderer,
        OptionRenderer,
        onSelection,
    } = props;
    // When overriding options button, make sure targetting just particular OptionsButton instance, therefore generate identificator.
    const rootClasses = `expando-options-button ${className || random(100)}`;

    useEffect(() => {
        // Note: this way we can override bootstrap styles totaly
        const buttons = document.getElementsByClassName(rootClasses);
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i].children[0];
            button.className = cn({
                'dropdown-toggle': true, // this should not matter for menu items
                'expando-btn': true,
                normal: true,
                [color]: true,
            });
        }
    }, []);

    return (
        <UncontrolledDropdown className={rootClasses} direction={direction}>
            <DropdownToggle disabled={disabled}>
                <RootRenderer label={text} icon={icon} value="" />
                {caret && <div className={`dropdown-toggle-caret ${direction}`}></div>}
            </DropdownToggle>
            <DropdownMenu>
                {options.map((o) => (
                    <Tooltip key={'option-tooltip-' + o.value} title={o.tooltip} disabled={!o.tooltip}>
                        <DropdownItem
                            key={'option-' + o.value}
                            onClick={() => onSelection(o)}
                            disabled={o.disabled || false}
                        >
                            <OptionRenderer {...o} />
                        </DropdownItem>
                    </Tooltip>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
OptionsButton.defaultProps = {
    text: '',
    color: 'transparent',
    caret: true,
    direction: 'down',
    disabled: false,
    className: null,
    options: [],
    RootRenderer: Content,
    OptionRenderer: Content,
    onSelection: () => {},
};

export default OptionsButton;
