import { MarketplaceName, MarketplaceRegion } from 'src/graphql/generated';
import { ApplicationCredentials } from 'src/services/marketplace/MarketplaceService';
import { EnvUtils } from 'src/utils/EnvUtils';
import qs from 'query-string';

export type AmazonRegionsMappingKey =
    | MarketplaceRegion.amazon_europe
    | MarketplaceRegion.amazon_america
    | MarketplaceRegion.amazon_far_east_jp
    | MarketplaceRegion.amazon_far_east_au
    | MarketplaceRegion.amazon_far_east_sg;

export type AmazonRegionsMappingVal = {
    marketplace: MarketplaceName;
    sellerCentralUrl: string;
};

// https://developer-docs.amazon.com/sp-api/docs/seller-central-urls
export const amazonRegionsMapping: { [key in AmazonRegionsMappingKey]: AmazonRegionsMappingVal } = {
    [MarketplaceRegion.amazon_europe]: {
        marketplace: MarketplaceName.amazon_uk,
        sellerCentralUrl: 'https://sellercentral.amazon.co.uk',
    },
    [MarketplaceRegion.amazon_america]: {
        marketplace: MarketplaceName.amazon_us,
        sellerCentralUrl: 'https://sellercentral.amazon.com',
    },
    [MarketplaceRegion.amazon_far_east_jp]: {
        marketplace: MarketplaceName.amazon_jp,
        sellerCentralUrl: 'https://sellercentral.amazon.co.jp',
    },
    [MarketplaceRegion.amazon_far_east_au]: {
        marketplace: MarketplaceName.amazon_au,
        sellerCentralUrl: 'https://sellercentral.amazon.com.au',
    },
    [MarketplaceRegion.amazon_far_east_sg]: {
        marketplace: MarketplaceName.amazon_sg,
        sellerCentralUrl: 'https://sellercentral.amazon.sg',
    },
};

export function getAuthUrl(mapping: AmazonRegionsMappingVal) {
    return `${EnvUtils.getApiEndpoint()}/amazon/oauth2/code?service=${mapping.marketplace}`;
}

export function getRegistrationUrl(mapping: AmazonRegionsMappingVal, credentials: ApplicationCredentials) {
    if (!credentials) return '';
    const { id, name } = credentials;
    const path = '/gp/mws/registration/register.html';
    const query = qs.stringify({
        signInPageDisplayed: 2,
        developerName: name,
        devMWSAccountId: id,
    });

    return `${mapping.sellerCentralUrl}/${path}?${query}`;
}
