import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import Application from './Application';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { GraphqlClient } from './graphql/GraphqlClient';
import { bootstrapIntercom } from './intercom';
import { ToastProvider } from 'react-toast-notifications';
import { MarketplacesContextProvider } from './Contexts/MarketplacesContext';
import { UserContextProvider } from './Contexts/User/UserContext';
import { AppLogger } from './logger/AppLogger';
import { ServiceProvider } from './services/ServiceProvider';
import { LanguageService } from './localization/LanguageService';
import { LanguageContextProvider } from './Contexts/Language/LanguageContext';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-tippy/dist/tippy.css';
import 'grommet/grommet-aruba.min.css';
import './index.scss';
import './Application.scss';
import './assets/animate.scss';

async function main() {
    console.log({
        NODE_ENV: process.env.NODE_ENV,
        REACT_APP_GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL,
    });

    window.LOGGER = await AppLogger.initialize();
    window.GQL_CLIENT = await GraphqlClient.initialize();
    window.HISTORY = createBrowserHistory();
    bootstrapIntercom(window.HISTORY);

    const languageService = new LanguageService(true);
    const t = await languageService.initI18n();

    // if 't' can be passed different way, whole LanguageService initialization could go to LanguageContextProvider
    window.SERVICES = new ServiceProvider(t);

    ReactDOM.render(
        <HttpsRedirect>
            <I18nextProvider i18n={i18next}>
                <LanguageContextProvider languageService={languageService}>
                    <ApolloProvider client={window.GQL_CLIENT}>
                        <ToastProvider placement="bottom-center">
                            <BrowserRouter>
                                <UserContextProvider>
                                    <MarketplacesContextProvider>
                                        <Application />
                                    </MarketplacesContextProvider>
                                </UserContextProvider>
                            </BrowserRouter>
                        </ToastProvider>
                    </ApolloProvider>
                </LanguageContextProvider>
            </I18nextProvider>
        </HttpsRedirect>,
        document.getElementById('content'),
    );
}

main().catch(console.error);
