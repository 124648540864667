import { FunctionComponent } from 'react';
import { HasChildren } from 'src/common/props';

import './Hero.scss';

export interface HeroProps extends HasChildren {}

export const Hero: FunctionComponent<HeroProps> = ({ children }) => {
    return <article className="expando-hero-card">{children}</article>;
};
