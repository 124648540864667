import { MutationOptions } from '@apollo/client';
import gql from 'graphql-tag';
import ms from 'ms';
import { chunk, random } from 'lodash';
import { AffectedRowsUpdater } from 'src/components/FileUpdateWizard/CSVMapper';
import { OperationFailedError, SpecificError, UnexpectedError } from 'src/errors';
import {
    CountProductVariantsDocument,
    CountProductVariantsMutation,
    CountProductVariantsMutationVariables,
    ExportProductsDocument,
    ExportProductsMutation,
    ExportProductsMutationVariables,
    GetItemConditionsDocument,
    GetItemConditionsQuery,
    GetItemConditionsQueryVariables,
    GetProductExportDocument,
    GetProductExportQuery,
    GetProductExportQueryVariables,
    GetProductExportsDocument,
    GetProductExportsQuery,
    GetProductExportsQueryVariables,
    GetProductStatsDocument,
    GetProductStatsQuery,
    GetProductStatsQueryVariables,
    ImportProductVariantsDocument,
    ImportProductVariantsMutation,
    ImportProductVariantsMutationVariables,
    ItemCondition,
    MarketplaceName,
    ProductExport,
    ProductStats,
    ProductVariantInput,
    UpdateProductVariantsDocument,
    UpdateProductVariantsMutation,
    UpdateProductVariantsMutationVariables,
} from 'src/graphql/generated';
import deepEqual from 'fast-deep-equal';
import { ProgressBarBloc } from 'src/ui/progressBar/ProgressBarBloc';
import { ExtendedMutationOptions, RequestContext, Service } from '../Service';

export type CreateExportResult = Pick<ProductExport, '_id' | 'status' | 'requestedAt'>;
export type GetExportResult = Pick<
    ProductExport,
    '_id' | 'status' | 'requestedAt' | 'doneAt' | 'failedAt' | 'downloadLink'
>;

type Cache = { [userId: number]: CacheItem[] };
type CacheItem = { filter: any; expiresAt: number };

const PRODUCT_SYNC_QUERY = `
    query {
        productSync {
            isFeedSyncing
        }
    }
`;

const CHUNK_SIZE = 100;
const CACHE_EXPIRE_TIME = ms('10s');
const CACHE_CLEAR_TIME = ms('5s');

export class ProductService extends Service {
    // To avoid generating same report (same filter), cache localy filters for current instance
    // cannot use expiring-cache since don't have unique id for filter but comparing its contents
    private cacheExportFilters: Cache = {};

    constructor() {
        super();
        setInterval(() => {
            const now = Date.now();
            const newCache: Cache = {};
            for (const [userId, filters] of Object.entries(this.cacheExportFilters)) {
                const newFilters = filters.filter((o) => o.expiresAt > now);
                if (newFilters.length) {
                    newCache[userId] = newFilters;
                }
            }
            this.cacheExportFilters = newCache;
        }, CACHE_CLEAR_TIME);
    }

    /**
     * Checks if product's feed is syncing
     * @returns true if is syncing, false otherwise
     */
    async isFeedSyncing(): Promise<boolean> {
        type Result = {
            productSync: {
                isFeedSyncing?: boolean;
            };
        };
        let result = await this.query<Result>({ query: gql(PRODUCT_SYNC_QUERY) }, { method: 'isSyncing' });

        return result?.productSync?.isFeedSyncing ?? false;
    }

    /**
     * @return count of all user products
     */
    async countAll(): Promise<number> {
        const context: RequestContext = { method: 'countProducts' };
        const request: MutationOptions<CountProductVariantsMutation, CountProductVariantsMutationVariables> = {
            mutation: CountProductVariantsDocument,
        };
        const response = await this.mutate(request, context);

        return response?.countProductVariants;
    }

    /**
     * @return count of updated records
     */
    async update(
        data: ProductVariantInput[],
        progressUpdater?: ProgressBarBloc,
        affectedRowsUpdater?: AffectedRowsUpdater,
    ): Promise<void> {
        let processedCount = 0;
        let totalCount = data.length;
        const context: RequestContext = {
            method: 'updateProducts',
            params: { count: totalCount },
        };

        for (const chunkOfData of chunk(data, CHUNK_SIZE)) {
            const request: ExtendedMutationOptions<
                UpdateProductVariantsMutation,
                UpdateProductVariantsMutationVariables
            > = {
                mutation: UpdateProductVariantsDocument,
                variables: {
                    products: chunkOfData,
                },
                retry: 5,
            };
            await this.mutate(request, context);

            processedCount += chunkOfData.length;
            progressUpdater?.update(totalCount, processedCount);
            affectedRowsUpdater?.(processedCount);
        }
    }

    async import(
        data: ProductVariantInput[],
        updater?: ProgressBarBloc,
        affectedRowsUpdater?: AffectedRowsUpdater,
    ): Promise<void> {
        let processedCount = 0;
        let totalCount = data.length;
        const context: RequestContext = {
            method: 'importProducts',
            params: { count: totalCount },
        };

        for (const chunkOfData of chunk(data, CHUNK_SIZE)) {
            const request: ExtendedMutationOptions<
                ImportProductVariantsMutation,
                ImportProductVariantsMutationVariables
            > = {
                mutation: ImportProductVariantsDocument,
                variables: {
                    products: chunkOfData,
                },
                retry: 5,
            };
            const response = await this.mutate(request, context);
            if (!response.uploadProductVariants?.success) {
                throw new UnexpectedError('no data', context);
            }

            processedCount += chunkOfData.length;
            updater?.update(totalCount, processedCount);
            affectedRowsUpdater?.(processedCount);
        }
    }

    // left for testing
    // private list = [
    //     { _id: '123', status: 'Pending', requestedAt: new Date().toISOString(), downloadLink: 'www.google.com' },
    //     { _id: '1234', status: 'Pending', requestedAt: new Date().toISOString(), downloadLink: 'www.google.com' },
    // ];

    async getExports(): Promise<GetExportResult[]> {
        // left for testing
        // this.list.forEach(o => {
        //     if (Date.now() - new Date(o.requestedAt).getTime() > 15_000) {
        //         o.status = 'Done';
        //         // o.status = 'Error';
        //     }
        // })
        // return this.list;
        const response = await this.query<GetProductExportsQuery, GetProductExportsQueryVariables>(
            {
                query: GetProductExportsDocument,
                fetchPolicy: 'no-cache',
            },
            { method: 'getExports' },
        );
        return response?.productExports;
    }

    async getExport(exportId: string): Promise<GetExportResult> {
        // left for testing
        // return this.list.find(o => o._id === exportId);
        const response = await this.query<GetProductExportQuery, GetProductExportQueryVariables>(
            {
                query: GetProductExportDocument,
                variables: { exportId },
            },
            {
                method: 'getExports',
                params: { exportId },
            },
        );
        const result = response?.productExport;
        if (result.status === 'Failed') {
            throw new OperationFailedError('status', 'Failed');
        }
        return response?.productExport;
    }

    /**
     * Creates product exports
     * @param userId needed for caching variables
     * @param variables used to filter product in export
     * @returns document with export state
     */
    async createExport(userId: string, variables: ExportProductsMutationVariables): Promise<CreateExportResult> {
        this.checkCacheExportFilters(userId, variables);
        // left for testing
        // const item = { _id: Math.floor(random(1000.0)) + '', status: 'Pending', requestedAt: new Date().toISOString(), downloadLink: '' }
        // this.list.push(item);
        // this.setCacheExportFilters(userId, variables);
        // return item;
        const response = await this.mutate<ExportProductsMutation, ExportProductsMutationVariables>(
            {
                mutation: ExportProductsDocument,
                variables,
            },
            {
                method: 'createExport',
                params: variables,
            },
        );
        const result = response?.exportProducts;
        if (result.status === 'Failed') {
            throw new OperationFailedError('status', 'Failed');
        }
        this.setCacheExportFilters(userId, variables);
        return response?.exportProducts;
    }

    /**
     * Checks whether user have already created report with given filter for current app instance.
     * @param userId
     * @param filter
     * @throws error if found such filter for given user
     */
    checkCacheExportFilters(userId: string, filter: ExportProductsMutationVariables) {
        const usedFilers: CacheItem[] = this.cacheExportFilters[userId] ?? [];
        for (const usedFilter of usedFilers) {
            if (deepEqual(usedFilter.filter, filter)) {
                throw new SpecificError('filterUsed');
            }
        }
    }

    /**
     * Sets used filter after success export.
     * @param userId
     * @param filter
     */
    setCacheExportFilters(userId: string, filter: ExportProductsMutationVariables) {
        if (!this.cacheExportFilters[userId]) {
            this.cacheExportFilters[userId] = [];
        }
        this.cacheExportFilters[userId].push({
            filter,
            expiresAt: Date.now() + CACHE_EXPIRE_TIME,
        });
    }

    async getStats(variables: GetProductStatsQueryVariables): Promise<ProductStats> {
        const result = await this.query<GetProductStatsQuery, GetProductStatsQueryVariables>(
            {
                query: GetProductStatsDocument,
                variables,
            },
            {
                method: 'getStats',
                params: variables,
            },
        );

        return result.productStats ?? null;
    }
    async getItemConditions(variables: GetItemConditionsQueryVariables): Promise<ItemCondition[]> {
        const result = await this.query<GetItemConditionsQuery, GetItemConditionsQueryVariables>(
            {
                query: GetItemConditionsDocument,
                variables,
            },
            {
                method: 'getItemConditionList',
                params: variables,
            },
        );
        return result.getItemConditionList ?? [];
    }
}
