import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import LeftArrowIcon from '../../../assets/img/icons/chevron-left.svg';
export interface PreviousButtonProps {
    path: string;
    text: string;
}

const PreviousButton: FunctionComponent<PreviousButtonProps> = ({ path, text }) => {
    return (
        <NavLink to={path} className="previous-btn">
            {/* font icons faChevronLeft was just too bold */}
            <img src={LeftArrowIcon} alt="left-arrow" />
            {text}
        </NavLink>
    );
};

export default PreviousButton;
