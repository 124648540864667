import { FunctionComponent, useContext } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import FullpageLayout from './layouts/FullpageLayout';
import DashboardLayout from './layouts/DashboardLayout';
import { ProtectedRoute } from './routing/ProtectedRoute';
import { IUserContext, UserContext } from './Contexts/User/UserContext';
import { HasRoutingProps } from './common/props';
import ConfirmationModal from './ui/modal/ConfirmationModal';
import { ServiceProvider } from './services/ServiceProvider';
import SimpleModal from './ui/modal/SimpleModal';
import ToastWrapper from './ui/toast/ToastWrapper';

declare const SERVICES: ServiceProvider;

const Application: FunctionComponent = () => {
    const { user } = useContext<IUserContext>(UserContext);
    const { state, pathname } = useLocation();
    const url = (state as any)?.returnUrl || pathname;
    const routingProps: HasRoutingProps = { user, url };

    return (
        <>
            <Routes>
                <Route
                    path="eve/*"
                    element={
                        <ProtectedRoute>
                            <DashboardLayout {...routingProps} />
                        </ProtectedRoute>
                    }
                />
                <Route path={'*'} element={<FullpageLayout {...routingProps} />} />
            </Routes>
            <ConfirmationModal bloc={SERVICES.system.modal.confirmation} />
            <SimpleModal bloc={SERVICES.system.modal.simple} />
            <ToastWrapper bloc={SERVICES.system.toast} />
        </>
    );
};

export default Application;
