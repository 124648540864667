import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icon from '@fortawesome/free-solid-svg-icons';
import * as FeatherIcon from 'react-feather';

const createIconComponent =
    (icon) =>
    (props: any = {}) =>
        <FontAwesomeIcon {...props} icon={icon} />;

export const Show = createIconComponent(icon.faEye);
export const Search = createIconComponent(icon.faSearch);
export const Info = createIconComponent(icon.faInfoCircle);
export const Check = createIconComponent(icon.faCheck);
export const Edit = createIconComponent(icon.faPencilAlt);
export const Print = createIconComponent(icon.faPrint);
export const ExclamationCircle = createIconComponent(icon.faExclamationCircle);
export const ExclamationTriangle = createIconComponent(icon.faExclamationTriangle);
export const Exclamation = createIconComponent(icon.faExclamation);
export const Bell = createIconComponent(icon.faBell);
export const AngleRight = createIconComponent(icon.faAngleRight);
export const Circle = createIconComponent(icon.faCircle);
export const Globe = createIconComponent(icon.faGlobe);
export const Upload = createIconComponent(icon.faUpload);
export const Download = createIconComponent(icon.faDownload);
export const GlobeAmericas = createIconComponent(icon.faGlobeAmericas);
export const Times = createIconComponent(icon.faTimes);
export const Bomb = createIconComponent(icon.faBomb);
export const Barcode = createIconComponent(icon.faBarcode);
export const Ban = createIconComponent(icon.faBan);
export const CloudUploadAlt = createIconComponent(icon.faCloudUploadAlt);
export const Flag = createIconComponent(icon.faFlag);
export const Star = createIconComponent(icon.faStar);
export const Asterisk = createIconComponent(icon.faAsterisk);
export const User = createIconComponent(icon.faUser);
export const Briefcase = createIconComponent(icon.faBriefcase);
export const Home = createIconComponent(icon.faHome);
export const Trash = createIconComponent(icon.faTrashAlt);
export const Truck = createIconComponent(icon.faTruck);
export const MapPin = createIconComponent(icon.faMapPin);
export const Envelope = createIconComponent(icon.faEnvelope);
export const Phone = createIconComponent(icon.faPhone);
export const ExternalLinkAlt = createIconComponent(icon.faExternalLinkAlt);
export const Filter = createIconComponent(icon.faFilter);
export const Box = createIconComponent(icon.faBox);

export const X = (props: any = {}) => <FeatherIcon.X {...props} />;
