import { ApolloClient, MutationOptions, QueryOptions } from '@apollo/client';
import { ExecutionResult } from 'graphql';
import { omit } from 'lodash';
import { AppLogger, LogLocalContext } from '../logger/AppLogger';
import { EAppLoggerCategory } from '../logger/EAppLoggerCategory';
import { StatusError, UnavailableServiceError, UnexpectedError } from '../errors';
import { EnvUtils } from '../utils/EnvUtils';
import { IService } from './IService';
import { getStatusLikeError, StatusLikeErrorOptions } from './ServiceUtils';
import { sleep } from 'src/library/sleep';

declare const LOGGER: AppLogger;
declare const GQL_CLIENT: ApolloClient<any>;

export type ErrorInterceptor<T extends Error = any> = (error: T) => void;
export interface RequestContext extends LogLocalContext {
    errorInterceptor?: ErrorInterceptor;
}

export type StopPollingWhenPrototype = (result: any) => boolean | Error;
export interface ExtendedQueryOptions<D, V> extends QueryOptions<V, D> {
    stopPollingWhen?: StopPollingWhenPrototype;
}
export interface ExtendedMutationOptions<D, V> extends MutationOptions<D, V> {
    /**
     * Number of retries
     */
    retry?: number;
    /**
     * Delay when retry will be executed, default: 1s
     */
    retryDelay?: number;
}

export abstract class Service implements IService {
    /**
     * @inheritdoc
     */
    async clearCache(): Promise<void> {
        await GQL_CLIENT.clearStore();
    }

    protected async post<T>(
        url: string,
        body: Record<string, any>,
        options: RequestInit,
        context?: RequestContext,
    ): Promise<string | T> {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(options.headers ?? {}),
        };

        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers,
            ...omit(options, 'headers'),
            body: JSON.stringify(body),
        });

        if (response.status === 200) {
            return this.handleRestResult<T>(response);
        } else if (response.status === 401) {
            throw new StatusError(401, 'Unauthorized');
        } else {
            await this.handleRestError(response, context);
        }
    }

    protected async query<D, V = {}>(request: QueryOptions<V, D>, context: RequestContext = {}) {
        try {
            const result = await GQL_CLIENT.query(request);
            if (!result) {
                throw new UnexpectedError('no data', context);
            }

            return this.handleGQLResult(result);
        } catch (error) {
            this.handleGQLError(error, context);
        }
    }

    // Temporary for local testing
    // private pool: any[] = [
    //     missingData,
    //     createdEmptyData,
    //     processing37,
    //     processing73,
    //     finished
    // ]
    // private idx = 0;

    protected async watchQuery<D, V = {}>(options: ExtendedQueryOptions<D, V>, context?: RequestContext) {
        const poll = async (resolve, reject) => {
            try {
                const { pollInterval, stopPollingWhen, ...queryOptions } = options;
                const result = await this.query<D, V>(queryOptions, context);
                if (!result) {
                    reject(new UnexpectedError('no data', context));
                }
                // const result = this.pool[this.idx % this.pool.length];
                // this.idx++;
                if (options.stopPollingWhen?.(result)) {
                    resolve(result);
                } else {
                    setTimeout(() => poll(resolve, reject), pollInterval);
                }
            } catch (error) {
                reject(error);
            }
        };

        const result = await new Promise<D>(poll);
        return result;
    }

    protected async mutate<M, V>(options: ExtendedMutationOptions<M, V>, context: RequestContext = {}): Promise<M> {
        const { retry, retryDelay, ...request } = {
            retryDelay: 1000,
            ...options,
        };
        const isRetry = retry > 0;
        if (isRetry) await sleep(retryDelay);
        try {
            const result = await GQL_CLIENT.mutate<M, V>(request);
            if (!result) {
                throw new UnexpectedError('no data', context);
            }
            return this.handleGQLResult(result);
        } catch (error) {
            if (isRetry) {
                LOGGER.warn(`Got error: ${error.message}, next retry (${retry}) in ${retryDelay}ms`);
                return this.mutate(
                    {
                        ...request,
                        retry: retry - 1,
                        retryDelay: Math.round(retryDelay * 1.25), // simple backoff
                    },
                    context,
                );
            }
            this.handleGQLError(error, context);
        }
    }

    /**
     * Handles processing graphql result.
     * @param result holding the data
     * @return result data
     */
    protected handleGQLResult<T>(result: ExecutionResult<T>): T {
        return result.data;
    }

    /**
     * Handles processing graphql error ( Logs & Throws )
     * @param response of either Error or GraphQL Error instance
     * @param context for logger
     * @throws normalized error
     */
    protected handleGQLError(
        response: {
            message: string;
            graphQLErrors?: { message: string; data?: string }[];
            errors?: { message: string; data?: string }[];
        },
        context?: RequestContext,
    ): void {
        context.category = EAppLoggerCategory.GQL;
        // if graphql receives text/html content-type it fails parsing response with following error
        if (response.message === 'Unexpected token < in JSON at position 0') {
            return this.handleError(new StatusError(500, `Unexpected response`), context);
        }
        const error = response?.graphQLErrors?.[0] || response?.errors?.[0];
        if (!error) {
            return this.handleError(new StatusError(500, `Unknown error`), context);
        }
        if (error.data?.includes('ECONNREFUSED')) {
            return this.handleError(new UnavailableServiceError(), context);
        }
        this.handleError(new StatusError(500, error?.data || error?.message || response.message), context);
    }

    /**
     * Handles processing REST result. Parses given response with respect of content type.
     * @param response
     * @returns string or json
     */
    protected async handleRestResult<T>(response: Response): Promise<string | T> {
        const contentType = response.headers.get('content-type');
        if (contentType.startsWith('application/json')) {
            return response.json();
        }
        return response.text();
    }

    /**
     * Handles processing REST error
     * @param result
     * @param context for logger
     * @throws normalized status error
     */
    protected async handleRestError(response: Response, context: RequestContext): Promise<void> {
        const url = response.url.replace(EnvUtils.getApiEndpoint(), '');

        context.category = EAppLoggerCategory.REST;
        context.params.url = url;
        context.params.status = response.status;
        context.params.statusText = response.statusText;
        context.params.body = await response.text();

        const error = new StatusError(response.status, context.params.statusText);

        this.handleError(error, context);
    }

    /**
     * Logs and throws error. Throwing can be intercepted via errorInterceptor from context.
     * @param error
     * @param context for logger
     * @throws error if not intercepted
     */
    protected handleError<T>(error: Error, context?: RequestContext): void {
        const loggerOptions: LogLocalContext = {
            category: context.category ?? EAppLoggerCategory.GENERAL,
            component: context.component ?? this.constructor.name,
            method: context.method,
            params: context.params,
        };

        let isUnExpectedError = true;
        if (context.errorInterceptor) {
            try {
                context.errorInterceptor(error);
                isUnExpectedError = false;
            } catch (error) {
                // nothing by default, given error should be same as provided so loggeed it at the and
                // not caching error whould skip logger if thrown
            }
        }

        if (isUnExpectedError) {
            LOGGER.error(error, loggerOptions);
        }

        throw error;
    }

    /**
     * Checks result status property for 'error' or error related state provided by options.
     * @param result
     * @param options
     * @returns result if status not 'error'
     * @throws if status is 'error' or error related state
     */
    protected handleStatusLikeResult<T>(result: T, options?: StatusLikeErrorOptions): T {
        const error = getStatusLikeError(result, options);
        if (error) throw error;

        return result;
    }
}
