import * as yup from 'yup';
import { Translation } from 'src/common/decorators/Translation';
import { AbstractForm, FieldOption, ValidationSchemas } from 'src/components/form';
import { authBasicFields } from '../Form/basic-fields';

export interface FormData {
    email: string;
    password: string;
}

@Translation({
    i18nPrefix: 'auth.login',
    lookup: true,
})
export class LoginForm extends AbstractForm<FormData> {
    getFields(): FieldOption[][] {
        return authBasicFields;
    }

    getValidationSchema(): { [key in keyof FormData]: yup.BaseSchema<any, any, any> } {
        return {
            email: ValidationSchemas.email(this.t),
            password: ValidationSchemas.password(this.t),
        };
    }
}
