import { BehaviorSubject } from 'rxjs';

export class ProgressBarBloc extends BehaviorSubject<number> {
    static toPercentage = (value: number) => Math.round(value * 100);

    /**
     * Displays calculated percentage value out of total and progress values.
     * @param total
     * @param progress
     */
    update(total: number, progress: number) {
        const percentage = ProgressBarBloc.toPercentage(progress / total);
        this.next(percentage);
    }

    /**
     * Displays directly percentage value.
     * @param percentage
     * @param range represents percentage range 0..1 or 0..100
     */
    display(percentage: number, range: '1' | '100' = '100') {
        const value = range === '1' ? ProgressBarBloc.toPercentage(percentage) : percentage;
        this.next(value);
    }
}
