import ConnectMarketplace from '../ConnectMarketplaceNew';
import ConnectEMagForm, { createValidationSchema } from './ConnectEMagForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectMarketplaceWizard, { ConnectMarketplaceWizardProps } from '../ConnectMarketplaceWizard';
import ConnectMarketplaceHeader from '../ConnectMarketplaceHeader';
import { MarketplaceRegion } from 'src/graphql/generated';
import { ConnectEMagStepBloc } from './ConnectEMagStepBloc';
import { ConnectMarketplaceWizardBloc } from '../ConnectMarketplaceWizardBloc';

interface ConnectEMagOrchestratorProps {
    region: MarketplaceRegion;
    showWizardComponents: boolean;
}

export const ConnectEMagOrchestrator: FunctionComponent<ConnectEMagOrchestratorProps> = ({
    region,
    showWizardComponents,
}) => {
    const { t } = useTranslation();
    const parentBloc = new ConnectMarketplaceWizardBloc(1, region, t);
    const step1Bloc = new ConnectEMagStepBloc(region, parentBloc, null, t);
    return (
        <ConnectEMag
            region={region}
            bloc={parentBloc}
            stepBlocs={[step1Bloc]}
            i18nPrefix="connectEMag"
            showWizardComponents={showWizardComponents}
        />
    );
};

const ConnectEMag: FunctionComponent<ConnectMarketplaceWizardProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ConnectMarketplaceWizard {...props}>
            <ConnectMarketplace
                header={ConnectMarketplaceHeader}
                form={ConnectEMagForm}
                validationSchema={createValidationSchema(t)}
            />
        </ConnectMarketplaceWizard>
    );
};
export default ConnectEMag;
