import { FunctionComponent } from 'react';
import { HasRoutingProps } from 'src/common/props';
import LanguageSelector from 'src/components/LanguageSelector/LanguageSelector';
import ExpandoLogo from 'src/components/logo/ExpandoLogo';
import { FullpageRoutes } from 'src/routing/FullpageRoutes';

import './FullpageLayout.scss';

const FullpageLayout: FunctionComponent<HasRoutingProps> = (routingProps) => {
    return (
        <div className={`simple-layout`}>
            <div className="header">
                <ExpandoLogo color="black" />
            </div>
            <div className="content">
                <FullpageRoutes {...routingProps} />
            </div>
            <div className="footer">
                <LanguageSelector type="text" direction="up" />
            </div>
        </div>
    );
};

export default FullpageLayout;
