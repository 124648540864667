import { LoggerStream } from './LoggerStream';
import { EnvUtils, ENV_KEY } from 'src/utils/EnvUtils';
import { ESegmentEvent, init as initSegment, SegmentEvent, track } from '../library/segment';

export interface SegmentLog {
    [key: string]: any;
    event: ESegmentEvent;
    error?: string;
}

export class SegmentLogger extends LoggerStream<SegmentLog> {
    constructor(segmentId: string) {
        super('SegmentLogger');
        initSegment(segmentId);
    }

    track(log: SegmentLog) {
        if (!log) return;
        track(this.createLog(log));
    }

    createLog(log: Partial<SegmentLog>): SegmentEvent {
        const { event, error, ...params } = log;

        const segmentEvent: SegmentEvent = {
            event,
            params,
            env: EnvUtils.get(ENV_KEY.NODE_ENV),
            timestamp: Date.now(),
            companyId: this.context?.companyId,
            userId: this.context?.user?.id,
            userEmail: this.context?.user?.email,
        };
        if (error) {
            segmentEvent.error = error;
        }

        return segmentEvent;
    }
}
