import { FunctionComponent } from 'react';

export interface CardTitleProps {
    title: string;
    paragraphs: string[];
}

const CardTitle: FunctionComponent<CardTitleProps> = ({ title, paragraphs }) => {
    return (
        <>
            <h3>{title}</h3>
            {paragraphs.map((o, idx) => (
                <p key={idx}>{o}</p>
            ))}
        </>
    );
};

export default CardTitle;
