import { FunctionComponent } from 'react';
import { isNil } from 'lodash';

export interface CardHeaderProps {
    image?: JSX.Element;
    title?: string;
    description?: string;
    badge?: JSX.Element;
    button?: JSX.Element;
    className?: string;
}

const CardHeader: FunctionComponent<CardHeaderProps> = ({ image, title, description, badge, button, className }) => {
    const displayImage = !isNil(image);
    const displayText = !isNil(title) || !isNil(description);

    return (
        <div className={`expando-card-header ${className}`}>
            {displayImage && <div className="image">{image}</div>}
            {displayText && (
                <div className="text">
                    <span className="title">{title}</span>
                    <span className="description">{description}</span>
                </div>
            )}
            {badge}
            {button}
        </div>
    );
};
CardHeader.defaultProps = {
    className: '',
};

export default CardHeader;
