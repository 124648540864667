import { ServiceProvider } from 'src/services/ServiceProvider';
import { AbstractBloc } from 'src/library/bloc';
import { SpecificError } from 'src/errors';
import { StateData } from './StatusPage';
import { TFunction } from 'src/hooks/useTranslation';

declare const SERVICES: ServiceProvider;

export class OAuthRequestBloc extends AbstractBloc<'auth', StateData> {
    public getName(): string {
        return 'OAuthRequestBloc';
    }

    constructor(t: TFunction) {
        super({ state: 'authenticating' }, t);
    }

    public start(): void {
        this.makeRequest('auth', () => SERVICES.auth.startShoptetLogin());
    }

    public produceEvent(action: 'auth', isLoading: boolean, redirectUrl: string, error: Error): void {
        if (isLoading) {
            this.produceStateDataEvent<StateData>({
                state: 'authenticating',
            });
        }
        if (error) {
            this.produceStateDataEvent<StateData>({
                state: 'incorrect',
                error: this.getErrorMessage(error),
            });
        }
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }

    protected getErrorMessage(error: Error) {
        if (error instanceof SpecificError && error.code === 'missingShopId') {
            return this.t('auth.shoptetOAuth.request.error.missingShopId');
        }
        return super.getErrorMessage(error);
    }
}
