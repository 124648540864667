import { FunctionComponent } from 'react';
import FormInput from 'src/components/form/FormInput';
import { Button } from 'src/ui';
import { HasI18nProps } from 'src/common/props';
import FormSelect from './FormSelect';
import FormDate from './FormDate';

export interface FormInputElementProps {
    name: string;
    type: string;
    tabIndex: number;
    optional?: boolean;
    disabled?: boolean;
    [key: string]: any;
}
export interface FormInputLabeledProps extends FormInputElementProps, HasI18nProps {
    hintOnClick?: () => void;
    includeFormPrefix?: boolean;
}

const FormInputLabeled: FunctionComponent<FormInputLabeledProps> = (props) => {
    const { optional, includeFormPrefix, hintOnClick, t, exists, ...inputProps } = props;
    const { name, type } = inputProps;

    const formPrefix = includeFormPrefix ? 'form.' : '';
    const key = `${formPrefix + name}`;

    let label = key;
    let hint = null;
    let placeholder = null;

    const text = t(key, { returnObjects: true });
    if (typeof text === 'string') {
        label = text;
    } else {
        label = t(`${key}.label`);
        hint = exists(`${key}.hint`) ? t(`${key}.hint`) : null;
        placeholder = exists(`${key}.placeholder`) ? t(`${key}.placeholder`) : null;
    }

    const Label: FunctionComponent = () => {
        return (
            <span>
                <label>{label}</label>
                {optional && <small>{t('form.optional')}</small>}
            </span>
        );
    };

    const HelpLink: FunctionComponent = () => {
        if (!hint) return null;
        return <Button color="link" tabIndex={-1} text={hint} onClick={hintOnClick} />;
    };

    const mapping = {
        select: FormSelect,
        date: FormDate,
    };
    const Element = mapping[type] ?? FormInput;

    return (
        <>
            <div className="expando-form-label-panel">
                <Label />
                <HelpLink />
            </div>
            <Element {...inputProps} placeholder={placeholder} />
        </>
    );
};
FormInputLabeled.defaultProps = {
    optional: false,
    disabled: false,
    hintOnClick: () => {},
    includeFormPrefix: true,
};

export default FormInputLabeled;
