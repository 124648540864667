import { EnumCompanyOnboardingState } from '../../graphql/generated';
import { ServiceProvider } from 'src/services/ServiceProvider';
import { DataSourceType } from 'src/components/card/DataSourceCard';
import { AppLogger } from 'src/logger/AppLogger';
import { paths } from 'src/routing/paths';
import { NavigateFunction } from 'react-router-dom';

declare const LOGGER: AppLogger;
declare const SERVICES: ServiceProvider;

function update(state: EnumCompanyOnboardingState) {
    SERVICES?.company
        .updateOnboardingState(state)
        .catch((error) => LOGGER.error('Error updating onboarding state: ' + error?.message));
}

// TODO this should not be called since this is default state
export function redirectToConnectDatasource(navigate: NavigateFunction) {
    update(EnumCompanyOnboardingState.SOURCE_SELECTION);
    navigate(paths.onboarding.connectDataSource);
}

export function redirectToConnectMarketplaces(navigate: NavigateFunction, source: DataSourceType) {
    update(EnumCompanyOnboardingState.MARKETPLACES_CONNECTION);
    navigate(paths.onboarding.connectMarketplaces + '?source=' + source.toLocaleLowerCase());
}

export function endOnboarding() {
    update(EnumCompanyOnboardingState.DONE);
}
