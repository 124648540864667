export const paths = {
    root: '/',
    onboarding: {
        root: '/onboarding',
        feedLoading: '/onboarding/feed-loading',
        feedError: '/onboarding/feed-error',
        connecting: '/onboarding/connecting',
        connectDataSource: '/onboarding/connect-data-source',
        connectDataSourceShoptet: '/onboarding/connect-data-source/shoptet',
        connectDataSourceXML: '/onboarding/connect-data-source/xml',
        connectDataSourceCSV: '/onboarding/connect-data-source/csv',
        connectMarketplaces: '/onboarding/connect-marketplaces',
        connectMarketplacesRegion: '/onboarding/connect-marketplaces/:region',
    },
    oauth: {
        shoptet: {
            login: '/shoptet/login',
            afterauth: '/shoptet/afterauth',
        },
        amazon: {
            afterauth: '/amazon/afterauth',
        },
    },
    auth: {
        login: '/login',
        shoptet: {
            login: '/login/shoptet',
            registration: '/registration/shoptet',
        },
        registration: '/registration',
        resetPassword: '/reset-password',
        resetPasswordFinalize: '/password-reset/finalize',
    },
    eve: {
        root: '/eve',
        dashboard: '/eve/dashboard',
        ordersStatistics: '/eve/orders-statistics',
        marketplaces: '/eve/marketplaces',
        productFeed: '/eve/product-feed',
        orders: '/eve/orders',
        products: '/eve/products',
        updateProductsByFile: '/eve/update-products-by-file',
        pricing: '/eve/pricing',
        autopricer: '/eve/autopricer',
        updateAutopricerByFile: '/eve/update-autopricer-by-file',
        stats: '/eve/stats',
        adminStatistics: '/eve/admin-statistics',
        usersTable: '/eve/users-table',
        syncSettings: '/eve/sync-settings',
        connectMarketplacesRegion: '/eve/connect-marketplaces/:region',
    },
    emailVerification: '/email-verification/:code',
    tos: '/terms-of-service',
    pp: '/pp',
    test: '/test',
    notFound: '/notFound',
    notAuthorized: '/notAuthorized',
    serverError: '/serverError',
};
