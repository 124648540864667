import { FunctionComponent } from 'react';
import { Alert, Button, UncontrolledAlert } from 'reactstrap';
import { toString } from 'lodash';
import cn from 'classnames';
import { Check, ExclamationCircle, ExclamationTriangle, Flag, Info } from 'src/ui/icon';

import './NotificationPanel.scss';

export type NotificationType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
export interface NotificationPanelProps {
    type: NotificationType;
    message: string;
    showIcon?: boolean;
    icon?: JSX.Element;
    className?: string;
    closable?: boolean;
    centered?: boolean;
    action?: {
        /** Action link text, is lowered automatically  */
        text: string;
        /** Action link action callback */
        onClick: () => void;
    };
}

const NotificationPanel: FunctionComponent<NotificationPanelProps> = (props) => {
    const { type, message, showIcon, centered, icon, className, closable } = props;

    if (!message) return null;

    const ThisIcon: FunctionComponent = () => {
        if (!showIcon) return null;
        if (icon) return icon;

        switch (type) {
            case 'info':
                return <Info />;
            case 'success':
                return <Check />;
            case 'danger':
                return <ExclamationCircle />;
            case 'warning':
                return <ExclamationTriangle />;
            default:
                return <Flag />;
        }
    };

    const ActionButton: FunctionComponent = () => {
        const { action } = props;

        if (!action) return null;

        const { text, onClick } = action;

        return (
            <Button color="link" className="action-btn" onClick={onClick}>
                {text}
            </Button>
        );
    };

    const classes = cn({
        'alert-message': true,
        [className]: !!className,
        centered: centered,
    });
    const Component = closable ? UncontrolledAlert : Alert;

    return (
        <Component color={type} className="notification-panel">
            <div className={classes}>
                {toString(message)}
                <ActionButton />
            </div>
            <ThisIcon />
        </Component>
    );
};
NotificationPanel.defaultProps = {
    showIcon: false,
    centered: false,
    icon: null,
    className: '',
};

export default NotificationPanel;
