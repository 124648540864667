import { FunctionComponent } from 'react';
import NotificationPanel from 'src/components/NotificationPanel/NotificationPanel';
import { useBloc } from 'src/hooks/useBloc';
import { useTranslation } from 'src/hooks/useTranslation';
import { Card, CardContent } from 'src/ui';
import CardTitle from 'src/ui/title/CardTitle';
import PageTitle from 'src/ui/title/PageTitle';
import { AuthType } from './Form/AuthType';
import { AbstractSimpleFormProps } from 'src/components/form';
import { IAbstractBloc } from 'src/library/bloc/AbstractBloc';

import './Auth.scss';

export interface AuthContentProps extends Omit<AbstractSimpleFormProps<any>, 'render' | 't'> {}
export interface AuthProps {
    type: AuthType;
    bloc: IAbstractBloc;
    authWithCards?: FunctionComponent<{ type: AuthType }>[];
    content?: any;
    footer?: FunctionComponent;
}

const AuthLayout: FunctionComponent<AuthProps> = (props) => {
    const { type, authWithCards, content: Content, footer: Footer, bloc } = props;
    const i18nPrefix = `auth.${type}`;
    const { t } = useTranslation({ i18nPrefix });

    const { state, data, initialTouched, initialErrors, error, success, onSubmit } = useBloc<any>(bloc);
    const isSubmitting = state === 'requesting';

    return (
        <div className="auth-container login">
            <PageTitle title={t('pageTitle')} description={t('pageDescription')} />
            {authWithCards.length > 0 && (
                <Card className="auth-with-card">
                    {authWithCards.map((AuthWithCard, id) => (
                        <AuthWithCard key={`authWith${id}`} type={type} />
                    ))}
                </Card>
            )}
            <Card>
                <CardContent>
                    <CardTitle title={t('title')} paragraphs={[]} />
                    <NotificationPanel type="danger" message={error} />
                    <NotificationPanel type="success" message={success} />
                    <Content
                        initialValues={data}
                        initialTouched={initialTouched}
                        initialErrors={initialErrors}
                        isSubmitting={isSubmitting}
                        onSubmit={onSubmit}
                    />
                </CardContent>
            </Card>
            <Footer />
        </div>
    );
};
AuthLayout.defaultProps = {
    authWithCards: [],
    content: () => null,
    footer: () => null,
};

export default AuthLayout;
