import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';
import { FormData } from './ConnectCDiscountForm';

export class ConnectCDiscountStepBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectCDiscountStepBloc';
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            sellerId: data?.sellerId ?? '',
        };
    }
}
