import { FunctionComponent } from 'react';

export type IconMap = { [icon: string]: IconProps };
export interface IconProps {
    src: string;
    key?: string;
    className?: string;
    style?: Record<string, any>;
    alt?: string;
}

const Icon: FunctionComponent<IconProps> = (props) => {
    return <img {...props} />;
};
Icon.defaultProps = {
    className: '',
};

export default Icon;
