import { FunctionComponent, Suspense as ReactSuspense } from 'react';
import LoadingPage from '../screens/LoadingPage';
import Page from './Page';

/**
 * Simple wrapper over Suspence to have better controll over fallback and to reduce code needing to initialize it.
 */
export const Suspense: FunctionComponent<{ element }> = ({ element: Element }) => {
    return (
        <ReactSuspense fallback={<LoadingPage />}>
            <Page>
                <Element />
            </Page>
        </ReactSuspense>
    );
};
