import { WizardBloc } from './WizardBloc';
import { AbstractBloc } from './AbstractBloc';
import { StateData } from './StateData';
import { WizardState } from './WizardBloc';
import { TFunction } from 'i18next';

export type WizardStepState = 'locked' | WizardState;

export interface WizardStepStateData<S, T> extends StateData<S | WizardStepState, T> {
    onOpen?: () => void;
    onSave?: (formData: T) => void;
}

export abstract class WizardStepBloc<E, S, SD extends WizardStepStateData<S, any>> extends AbstractBloc<E, SD> {
    protected isDone = false;

    protected abstract onParentCompleted(data: any): void;
    protected abstract onPrevStepCompleted(data: any): void;
    private parentBloc: WizardBloc<any>;

    constructor(parentBloc: WizardBloc<any>, prevStepBloc: WizardStepBloc<any, any, any>, t: TFunction) {
        super({ state: 'locked' } as SD, t);
        this.parentBloc = parentBloc;
        if (parentBloc)
            parentBloc.subscribe((stateData) => {
                if (stateData.state === 'completed') {
                    this.onParentCompleted(stateData.data);
                }
            });
        if (prevStepBloc)
            prevStepBloc.subscribe((stateData) => {
                this.onPrevStepChange(stateData);
                if (stateData.state === 'completed') {
                    this.onPrevStepCompleted(stateData.data);
                }
            });
    }

    protected onPrevStepChange(data: any): void {
        // nothing by default
    }

    public start() {
        this.parentBloc?.start();
    }

    protected getState(action: E, isLoading: boolean, data: any, error: Error): WizardState {
        if (isLoading) return 'loading';
        if (error) return 'incorrect';
        if (this.isDone) return 'completed';
        return 'loaded';
    }
}
