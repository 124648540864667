import { Service } from '../Service';
import {
    GetPlatformStatusesDocument,
    GetPlatformStatusesQuery,
    GetPlatformStatusesQueryVariables,
} from '../../graphql/generated';

export class PlatformService extends Service {
    getPlatformStatuses(): Promise<GetPlatformStatusesQuery> {
        // TODO MSL yeah the name 'GetPlatformStatuses' is missleading
        return this.query<GetPlatformStatusesQuery, GetPlatformStatusesQueryVariables>(
            { query: GetPlatformStatusesDocument },
            { method: 'getPlatformStatuses' },
        );
    }
}
